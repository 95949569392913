<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div class="pw---input-wrapper">
      <div
        class="pw---input pw---dark pw---formula"
        @click="isOpen = true"
        :style="{ 'margin-bottom': isOpen ? '210px' : '0px' }"
      >
        <div
          class="pw---parameter-wrapper"
          style="display: block!important;"
          contenteditable="true"
          @click="getRange()"
          :id="id"
        ></div>
        <div class="pw---formula-drag-n-drop" v-if="isOpen">
          <div class="pw-close-popup-small" @click.stop="isOpen = false">
            <img
              src="https://widget.platformwizards.com/images/white_4.svg"
              alt=""
              class="pw---15px-icon pw---18px-icon"
            />
          </div>
          <div
            v-for="(properties, index) in selectOptions"
            :key="properties.title"
          >
            <h6
              class="pw---50 pw---bottom-5"
              :class="{ ' pw---top-15': index != 0 }"
            >
              {{ properties.title }}
            </h6>
            <div class="pw---parameter-wrapper">
              <span
                v-for="parameter in properties.properties"
                :key="parameter"
                @click="insertOptionToText"
                class="pw---parameter"
                contenteditable="false"
                :type="properties.id"
                style="margin: 3px;"
                :style="{
                  'background-color': spanColor(properties)
                }"
                >{{ parameter }}</span
              >
            </div>
          </div>
          <!--h6 class="pw---50 pw---bottom-5 pw---top-15" contenteditable="false">
            Authentication Data
          </h6>
          <div class="pw---parameter-wrapper">
            <span class="pw---parameter" contenteditable="false">userID</span>
          </div-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputValue",
  props: {
    title: String,
    subtitle: String,
    initialValue: [String, HTMLDivElement],
    selectOptions: [Array],
    id: String
  },
  methods: {
    insertOptionToText(e) {
      let span = e.target
      let copy = span.cloneNode(true)
      if (e.target.className !== "pw---input pw---dark pw---formula") {
        this.pasteHtmlAtCaret(copy)
      }
    },
    pasteHtmlAtCaret(element) {
      //console.log(Object.keys(this.range), this.range.startOffset, this.range)
      if (this.range.startOffset) {
        // delete whatever is on the range
        this.range.deleteContents()
        // place your span
        this.range.insertNode(element)
      } else {
        let range = document.createRange()
        range.setStart(document.getElementById(this.id), 0)
        range.setEnd(document.getElementById(this.id), 0)
        // delete whatever is on the range
        range.deleteContents()
        // place your span
        range.insertNode(element)
      }
    },
    getValue() {
      this.value = this.parsedInput()
    },
    parseInitialValue() {
      let value = this.initialValue
      if (value) {
        // eslint-disable-next-line no-useless-escape
        let regexp = /\{{{(a-|f-|p-)(.*?)\}}}/g
        let matches = [...value.matchAll(regexp)]
        matches.forEach(match => {
          let color = ""
          let type = ""
          switch (match[1]) {
            case "a-":
              color = "#3371f2"
              type = "auth"
              break
            case "f-":
              color = "rgba(255, 255, 255, 0.25"
              type = "field"
              break
            case "p-":
              color = "#e18e41"
              type = "parameter"
              break
          }
          let html = `<span contenteditable="false" type="${type}" class="pw---parameter" style="margin: 3px; background-color: ${color}">${match[2]}</span>`
          value = value.replace(match[0].toString(), html)
        })
      }
      return value
    },
    parsedInput() {
      let value = document.getElementById(this.id).cloneNode(true)
      value.innerHTML = value.innerHTML.replace(/&nbsp;/g, " ")
      Array.from(value.children).forEach(child => {
        if (child.tagName === "SPAN") {
          value.replaceChild(
            document.createTextNode(this.replaceSPAN(child)),
            child
          )
        }
      })
      let valueDecoded = this.htmlDecode(value.innerHTML)
      return valueDecoded
    },
    htmlDecode(input) {
      var e = document.createElement("textarea")
      e.innerHTML = input
      // handle case of empty input
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue
    },
    replaceSPAN(span) {
      let type = span.getAttribute("type")
      let title = span.innerText
      if (type == "auth") {
        return "{{{a-" + title + "}}}"
      } else if (type === "field") {
        return "{{{f-" + title + "}}}"
      } else if (type === "parameter") {
        return "{{{p-" + title + "}}}"
      }
      return title
    },
    getRange() {
      this.range = window.getSelection().getRangeAt(0)
    },
    spanColor(properties) {
      let color
      if (properties.id === "auth") {
        color = "#3371f2"
      } else if (properties.id === "parameter") {
        color = "#e18e41"
      } else {
        color = "rgba(255, 255, 255, 0.25)"
      }
      return color
    }
  },
  data() {
    return {
      value: "",
      isOpen: false,
      range: Object
    }
  },
  watch: {
    value: function(value) {
      this.$emit("change", value)
    }
  },
  mounted() {
    let self = this
    let field = document.getElementById(this.id)

    // create an observer instance
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function() {
        self.getValue()
      })
    })
    // configuration of the observer:
    var config = {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true
    }
    // pass in the target node, as well as the observer options
    observer.observe(field, config)

    // Fill initial value
    let vm = this
    setTimeout(function() {
      document.getElementById(vm.id).innerHTML = ""
      document.getElementById(vm.id).innerHTML = vm.parseInitialValue()
    }, 100)
  }
}
</script>

<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}
</style>
