<template>
  <div
    class="pw---data-attribute pw---blue"
    @click.stop="$emit('click')"
    :style="{ 'background-color': color }"
  >
    <p v-if="title">
      {{ title.length > 15 ? title.substring(0, 15) + "..." : title }}
    </p>
    <p v-if="subtitle" class="pw---25 pw---5-left">
      {{ subtitle.length > 15 ? subtitle.substring(0, 15) + "..." : subtitle }}
    </p>
    <img
      v-if="hasUnselect"
      @click.stop="$emit('unselect')"
      src="https://widget.platformwizards.com/images/white_4.svg"
      alt=""
      class="pw---15px-icon pw---left-5 pw---remove"
    />
  </div>
</template>

<script>
export default {
  name: "Attribute",
  props: {
    title: String,
    subtitle: String,
    color: String,
    hasUnselect: { type: Boolean, default: false }
  }
}
</script>
