<template>
  <div class="pw---flex-row pw---top-15 pw---right">
    <div
      class="pw---button pw---medium pw---ultra-light pw---right-10"
      @click="cancel"
    >
      <p>Cancel</p>
    </div>
    <div
      class="pw---button pw---medium pw---light-blue pw---width-130"
      :style="{ opacity: !saveIsActive ? 0.3 : 1 }"
      @click="save"
    >
      <img src="https://widget.platformwizards.com/images/white_3.svg" alt="" />
      <p class="pw---left-10">Save</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Save",
  props: {
    saveIsActive: Boolean
  },
  methods: {
    save() {
      if (this.saveIsActive) {
        this.$emit("save")
      }
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>
