<template>
  <div class="pw---pw-second">
    <div v-show="!loading">
      <div class="pw---flex-row pw---heading">
        <div class="pw---flex-row">
          <h5>Authentication Settings</h5>
        </div>
      </div>
      <div class="pw---top-15">
        <div class="pw---seperator pw---small"></div>
      </div>
      <InputToggle
        title="Authentication required"
        subtitle="If 'yes', a user needs to be logged in to view this page. Else he will be redirected to the login page."
        :initialValue="authentication.isActive"
        messageOnTrue="Yes"
        messageOnFalse="No"
        @change="
          event => {
            authentication.isActive = event
          }
        "
      />
      <div
        class="pw---settings-wrap"
        v-if="authentication.isActive"
        style="padding-top: 5px"
      >
        <FieldElement
          title="Logout button(s)"
          subtitle="On click, the user will be logged out and redirected to the login page."
          :initialValue="authentication.logoutButtons"
          placeholder="Select logout buttons"
          @open-selector="elementSeletorOpenFor = 'logout-button'"
          @unselect="unselectValueFrom('logout-button', $event)"
          v-on="$listeners"
        />
      </div>
      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight"></div>
          <h5 class="pw---normal">While authenticating</h5>
        </div>
        <FieldElement
          title="Show element(s)"
          subtitle="Select a loading animation (e.g. loading spinner)"
          :initialValue="authentication.states.whileLoading.showElements"
          @open-selector="elementSeletorOpenFor = 'loading-element'"
          @unselect="unselectValueFrom('loading-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Hide element(s)"
          subtitle="e.g. hide elements where user data should be displayed"
          :initialValue="authentication.states.whileLoading.hideUntilLoaded"
          @open-selector="elementSeletorOpenFor = 'loading-hideUntil'"
          @unselect="unselectValueFrom('loading-hideUntil', $event)"
          v-on="$listeners"
        />
      </div>
      <!--InputSelect
          v-if="authentication.isActive"
          title="Redirect unathenticated users to"
          v-bind:selectOptions="pages"
          placeholder="Select page"
          v-bind:hasBoxAroundOptions="true"
          :initialValue="selectedPage"
          @change="authentication.redirectTo = $event.id"
        /-->

      <Save
        @save="saveAuthentication"
        @cancel="$emit('cancel')"
        :saveIsActive="saveIsActive"
      />
    </div>
    <LoadingAnimation v-if="loading" />
  </div>
</template>

<script>
import Save from "./../ui/Save"
import InputToggle from "./../ui/InputToggle.vue"
import FieldElement from "./../ui/FieldElement"
import LoadingAnimation from "./../misc/LoadingAnimation"

export default {
  name: "LoadAuthenticationConfig",
  props: { currentPage: Object, elementSelectorValue: Object },
  components: {
    Save,
    InputToggle,
    LoadingAnimation,
    FieldElement
  },
  data() {
    return {
      authentication: {
        isActive: false,
        logoutButtons: [],
        states: {
          whileLoading: {
            showElements: [],
            hideUntilLoaded: []
          }
        }
      },
      loading: false,
      saveIsActive: true,
      elementSelectorIsOpen: false,
      elementSelectorOpenFor: String
    }
  },
  methods: {
    async saveAuthentication() {
      this.loading = true
      this.$store.commit("saveAuthentication", {
        authentication: this.authentication,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
      } catch (e) {
        throw new Error(e)
      }
      await this.$store.dispatch(
        "getAdminConfig",
        this.$store.state.adminUser.jwt
      )
      this.$emit("saved")
      this.loading = false
    },
    unselectValueFrom(attribute, value) {
      if (attribute == "logout-button") {
        let index = this.authentication.logoutButtons.findIndex(
          element => element.queryString === value.queryString
        )
        this.authentication.logoutButtons.splice(index, 1)
      }
      if (attribute == "loading-element") {
        let index = this.authentication.states.whileLoading.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.authentication.states.whileLoading.showElements.splice(index, 1)
      }
      if (attribute == "loading-hideUntil") {
        let index = this.authentication.states.whileLoading.hideUntilLoaded.findIndex(
          element => element.queryString === value.queryString
        )
        this.authentication.states.whileLoading.hideUntilLoaded.splice(index, 1)
      }
      this.elementSeletorOpenFor = String
    }
  },
  computed: {
    pages() {
      let pages = []
      this.$store.state.pages.forEach(page => {
        let result = {}
        result.id = page.id
        result.title = "/" + page.slug
        result.subtitle = "Page"
        pages.push(result)
      })
      return pages
    },
    selectedPage() {
      let page = this.pages.find(
        page => page.id == this.authentication.redirectTo
      )
      return page
    }
  },
  mounted() {
    if (
      this.$store.state.config[this.currentPage.id] &&
      this.$store.state.config[this.currentPage.id].load &&
      this.$store.state.config[this.currentPage.id].load.authentication
    ) {
      let authentication = this.$store.state.config[this.currentPage.id].load
        .authentication
      this.authentication = { ...this.authentication, ...authentication }
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        if (this.elementSeletorOpenFor == "logout-button")
          this.authentication.logoutButtons.push(this.elementSelectorValue)
        if (this.elementSeletorOpenFor == "loading-element")
          this.authentication.states.whileLoading.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "loading-hideUntil")
          this.authentication.states.whileLoading.hideUntilLoaded.push(
            this.elementSelectorValue
          )
        this.elementSeletorOpenFor = String
      }
    },
    authentication: {
      deep: true,
      handler() {
        if (this.authentication.isActive === false) {
          this.authentication.logoutButtons === []
        }
      }
    }
  }
}
</script>
