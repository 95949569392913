<template>
  <div class="pw---flex-row pw---main-heading">
    <div class="pw---flex-row">
      <img
        src="https://widget.platformwizards.com/images/logo.svg"
        loading="lazy"
        alt=""
        class="pw---logo"
      />
      <div class="pw---page-selector" @click="$router.push({ name: 'Pages' })">
        <p class="pw---50">Page:</p>
        <p
          class="pw---bold pw---left-3"
          v-html="formatPath(currentPathName)"
        ></p>
      </div>
    </div>
    <div
      class="pw---button pw---small pw---light-blue"
      @click="$router.push({ name: 'PreviewSettings' })"
    >
      <p>Preview</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
  computed: {
    currentPathName() {
      if (window.location.pathname === "/") {
        return "/index"
      } else {
        return window.location.pathname
      }
    }
  },
  methods: {
    formatPath(path) {
      path = path.replace(
        /\//g,
        "<p class='pw---50' style='display: inline; padding-right: 3px'>/</p>"
      )
      path = path.replace(
        /index/g,
        "<p class='pw---25' style='display: inline;'>index</hp>"
      )
      return path
    }
  }
}
</script>
