<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div class="pw---input-group">
      <img
        src="https://widget.platformwizards.com/images/white_5.svg"
        alt=""
        class="pw---fixed-icon-input"
        @click.self="open"
      />
    </div>
    <div
      class="pw---multiple-select pw---small"
      @click.self="$emit('open-selector')"
      style="min-height: 51px; padding: 10px;"
    >
      <div>
        <div v-for="value in initialValue" :key="value.value.queryString">
          <Attribute
            v-if="value.type === 'element'"
            :title="
              value.value.id
                ? '#' + value.value.id
                : '.' + value.value.classListString
            "
            :subtitle="value.value.tagName"
            @unselect="$emit('unselect')"
            :hasUnselect="true"
            color="rgba(255, 255, 255, 0.25)"
          />
          <Attribute
            v-if="value.type === 'attribute'"
            :title="value.value.value.name"
            :subtitle="value.value.selectedRequestName"
            @unselect="$emit('unselect')"
            :hasUnselect="true"
            color="#3371f2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Attribute from "./Attribute"

export default {
  name: "FieldElement",
  components: { Attribute },
  props: {
    title: String,
    subtitle: String,
    isMultipleSelect: { type: Boolean, default: false },
    initialValue: {
      type: [Array]
    },
    placeholder: String
  }
}
</script>
