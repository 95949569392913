<template>
  <div class="pw---embed">
    <div class="pw---scroll-wrapper">
      <div class="pw---main">
        <TopBar />
        <div class="pw---main-content">
          <div class="pw---flex-column pw---left">
            <div class="pw---seperator pw---small"></div>
            <BackButton />
            <div class="pw---seperator pw---small"></div>
          </div>
          <div class="pw---flexrow pw---left">
            <div class="pw---label pw---light-orange pw---orange-outline">
              <p class="pw---orange-text">{{ numberOfNavParameters }}</p>
            </div>
            <p class="pw---left-10">Navigation Parameters</p>
          </div>
          <p class="pw---50 pw---top-5">
            Specify parameters that must be passed in a link to this page.
          </p>
          <div class="pw---operations">
            <div
              class="pw---operation"
              v-for="parameter in parameters"
              :key="parameter.id"
              @click="openParameter(parameter)"
              style="margin-bottom: 10px;"
            >
              <div class="pw---flexrow pw---100">
                <div class="pw---select-value pw---orange pw---no-margin">
                  <p>{{ parameter.name }}</p>
                  <p class="pw---25 pw---5-left">{{ parameter.dataType }}</p>
                </div>
              </div>
            </div>
            <div class="pw---flexrow pw---centered pw---top-15">
              <div
                class="pw---button pw---small pw---round pw---light"
                @click="createNewParameter"
              >
                <img
                  src="https://widget.platformwizards.com/images/white.svg"
                  alt=""
                  class="pw---20px-icon pw---right-10"
                />
                <p>Add Parameter</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pw---scroll-wrapper" v-if="config.id">
      <div class="pw---pw-second">
        <div v-if="!loading">
          <div class="pw---flex-row pw---heading">
            <div class="pw---flex-row">
              <h5 v-if="config.name">{{ config.name }}</h5>
              <h5 v-if="!config.name">Configure Parameter</h5>
            </div>
            <div
              class="pw---button pw---small pw---light-white pw---icon"
              @click="deleteParameter"
              v-if="!newParameter"
            >
              <img
                src="https://widget.platformwizards.com/images/white_trash.svg"
                width="20"
                alt=""
                class="_20px-icon"
              />
            </div>
          </div>
          <div class="pw---top-15">
            <div class="pw---seperator pw---small"></div>
          </div>
          <InputText
            title="Parameter Name"
            subtitle="*"
            inputType="text"
            :initialValue="config.name"
            @change="config.name = $event"
          />
          <InputSelect
            title="Data type"
            subtitle="*"
            :selectOptions="[{ id: 'text', title: 'Text' }]"
            placeholder="Select data type"
            @change="config.dataType = $event.id"
            :initialValue="selectedDataType"
            :hideRemove="true"
          />
          <InputSelect
            title="Fallback page"
            subtitle="* Page where your users will be redirected to if the parameter is not
            present"
            :selectOptions="pages"
            placeholder="Select page"
            @change="config.fallbackPageId = $event.id"
            :initialValue="selectedFallbackPage"
            :hasBoxAroundOptions="true"
          />
          <Save
            @save="saveParameter"
            @cancel="closeConfig"
            :saveIsActive="saveIsActive"
          />
        </div>
        <LoadingAnimation v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "./../components/nav/TopBar.vue"
import BackButton from "./../components/nav/BackButton.vue"
import Save from "./../components/ui/Save.vue"
import InputText from "./../components/ui/InputText"
import InputSelect from "./../components/ui/InputSelect"
import LoadingAnimation from "./../components/misc/LoadingAnimation"

export default {
  name: "NavigationParameters",
  components: {
    TopBar,
    BackButton,
    Save,
    InputText,
    InputSelect,
    LoadingAnimation
  },
  data() {
    return {
      config: {
        id: "",
        name: "",
        dataType: "text",
        fallbackPageId: ""
      },
      loading: false,
      newParameter: false
    }
  },
  methods: {
    openParameter(parameter) {
      this.config = { ...this.config, ...parameter }
      this.newParameter = false
    },
    createNewParameter() {
      let id = this.generateId()
      this.newParameter = true
      this.config.id = id
    },
    closeConfig() {
      this.config = {
        id: "",
        name: "",
        dataType: "text",
        fallbackPageId: ""
      }
      this.newParameter = false
    },
    generateId() {
      let id = this.testIfIdAlredyTaken(id)
      return id
    },
    idGenerator(length) {
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    testIfIdAlredyTaken() {
      let id = this.idGenerator(15)
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions
      ) {
        let index = this.$store.state.config[
          this.currentPage.id
        ].actions.findIndex(action => action.id == id)
        if (index === -1) {
          return id
        } else {
          return this.testIfIdAlredyTaken(id)
        }
      } else {
        return id
      }
    },
    async saveParameter() {
      let parameter = this.config
      this.loading = true
      this.$store.commit("saveParameter", {
        parameter: parameter,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
        await this.$store.dispatch("getAdminConfig", this.$store.state.adminUser.jwt)
      } catch (e) {
        throw new Error(e)
      }
      this.closeConfig()
      this.loading = false
    },
    async deleteParameter() {
      if (window.confirm("Are you sure you want to delete this parameter?")) {
        this.loading = true
        this.$store.commit("deleteParameter", {
          parameterId: this.config.id,
          pageId: this.currentPage.id
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        this.closeConfig()
        this.loading = false
      }
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug == pathName)
      return page
    },
    numberOfNavParameters() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].parameters
      ) {
        result = this.$store.state.config[this.currentPage.id].parameters.length
      }
      return result
    },
    parameters() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].parameters
      ) {
        return this.$store.state.config[this.currentPage.id].parameters
      } else return []
    },
    pages() {
      let pages = this.$store.state.pages
      let result = []
      pages.forEach(page => {
        if (page.slug !== this.currentPage.slug) {
          result.push({ id: page.id, title: "/" + page.slug })
        }
      })
      return result
    },
    allPages() {
      let pages = this.$store.state.pages
      let result = []
      pages.forEach(page => {
        result.push({ id: page.id, title: "/" + page.slug })
      })
      return result
    },
    selectedFallbackPage() {
      if (this.config.fallbackPageId) {
        let page = this.allPages.find(
          page => page.id === this.config.fallbackPageId
        )
        return { id: page.id, title: page.title }
      } else return {}
    },
    selectedDataType() {
      if (this.config.dataType === "text") {
        return { id: "text", title: "Text" }
      } else return {}
    },
    saveIsActive() {
      if (
        this.config.name &&
        this.config.dataType &&
        this.config.fallbackPageId
      ) {
        return true
      } else return false
    }
  }
}
</script>
