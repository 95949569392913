import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { Integrations } from "@sentry/tracing"
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "document-register-element/build/document-register-element"
import vueCustomElement from "vue-custom-element"
import VueIntercom from "vue-intercom"
require("dotenv").config()

if (process.env.NODE_ENV !== "development") {
  // eslint-disable-next-line no-undef
  Sentry.init({
    dsn:
      "https://07debf67069549d69d42b9a35ddcc5a7@o480975.ingest.sentry.io/5528843",
    release: "pw-widget@" + "0.4.0",
    integrations: [
      new Integrations.BrowserTracing(),
      new VueIntegration({
        Vue,
        tracing: true
      })
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1
  })
}

Vue.config.productionTip = process.env.NODE_ENV === "development" ? false : true

// eslint-disable-next-line no-undef
Vue.prototype.$jq = $

Vue.use(vueCustomElement)
Vue.use(VueIntercom, { appId: "fexu1rnh" })
App.store = store
App.router = router
Vue.customElement("platform-wizards", App)
