<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div class="pw---input-wrapper">
      <input
        v-if="inputType === 'text'"
        type="text"
        class="pw---input pw---dark w-input"
        v-bind:maxlength="max"
        v-bind:minlength="min"
        v-bind:placeholder="placeholder"
        v-model="value"
        :style="{ paddingLeft: leftPadding + 'px' }"
      />
      <input
        v-if="inputType === 'number'"
        type="number"
        class="pw---input
      pw---dark w-input"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        v-model="value"
        :style="{ paddingLeft: leftPadding + 'px' }"
      />
      <p v-if="fixedInputText" class="pw---input-overlay pw---text pw---50">
        {{ fixedInputText }}
      </p>
    </div>
  </div>
</template>

<script>
/*


*/
export default {
  name: "InputText",
  props: {
    title: String,
    subtitle: String,
    inputType: {
      type: String,
      validator: function(value) {
        return ["text", "number"].indexOf(value) !== -1
      },
      required: true
    },
    initialValue: [String, Number],
    max: Number,
    min: Number,
    placeholder: [String, Number],
    isRequired: Boolean,
    leftPadding: Number,
    fixedInputText: String
  },
  data() {
    return {
      value: this.initialValue ? this.initialValue : ""
    }
  },
  watch: {
    value: function(value) {
      this.$emit("change", value)
    },
    initialValue() {
      this.value = this.initialValue
    }
  }
}
</script>
