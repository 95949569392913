<template>
  <div class="pw---embed">
    <div class="pw---dragndrop-wrapper">
      <div
        class="pw---main pw---preview"
        id="pw---visual-selector"
        style="position:absolute;"
      >
        <div class="pw---flex-row pw---main-heading">
          <div class="pw---flex-row" id="pw---visual-selector-header">
            <img
              src="https://widget.platformwizards.com/images/logo.svg"
              loading="lazy"
              alt=""
              class="pw---logo"
            />
            <h5 class="pw---10-left">Select Element</h5>
          </div>
        </div>
        <div class="pw---flex-column pw---left">
          <div class="pw---seperator pw---small"></div>
        </div>
        <div class="pw---main-content pw---left">
          <div
            class="pw---select-value pw---no-margin"
            v-if="Object.keys(selected).length !== 0"
          >
            <p class="" v-if="selected.classListString && !selected.id">
              {{
                selected.classListString
                  ? "." +
                    selected.classListString.substring(0, 25) +
                    (selected.classListString.length > 25 ? "..." : "")
                  : ""
              }}
            </p>
            <p class="" v-if="selected.id">
              {{ "#" + selected.id }}
            </p>
            <p class="pw---25 pw---5-left">{{ selected.tagName }}</p>
            <img
              src="https://widget.platformwizards.com/images/white_4.svg"
              alt=""
              class="pw---15px-icon pw---left-5 pw---remove"
              @click="selected = {}"
            />
          </div>

          <Save
            v-on:save="save"
            v-on:cancel="cancel"
            :saveIsActive="Object.keys(selected).length !== 0"
          />
        </div>
      </div>
    </div>
    <div
      class="pw---element-selector-wrapper"
      id="pw---element-selector-wrapper-visual"
      style="display: none"
    >
      <div
        class="pw---selector-label"
        style="width: max-content;"
        v-if="hovered.classListString || hovered.id || hovered.tagName"
      >
        <p
          v-if="hovered.classListString.length > 0"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{
            hovered.classListString.length > 0
              ? "." +
                hovered.classListString.substring(0, 25) +
                (hovered.classListString.length > 25 ? "..." : "")
              : ""
          }}
        </p>
        <p
          v-if="hovered.id"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{ "#" + hovered.id }}
        </p>
        <p
          class="pw---5-left pw---50"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{
            !hovered.id && !hovered.classListString
              ? hovered.queryString
              : hovered.tagName
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Save from "./../components/ui/Save"

export default {
  name: "VisualSelector",
  data() {
    return {
      hovered: {
        tagName: "",
        id: "",
        classListString: ""
      },
      selected: {},
      allElements: {}
    }
  },
  components: { Save },
  methods: {
    dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0

      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown

      function dragMouseDown(e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag(e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px"
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px"
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null
        document.onmousemove = null
      }
    },
    enableSelector() {
      let self = this
      function traverse(node, elems) {
        if (node && node.tagName && node.tagName !== "PLATFORM-WIZARDS") {
          if (node.tagName !== "BODY") {
            self.addMouseoverAnimation(node)
          }
          let childs = node.childNodes
          for (let i = 0; i < childs.length; i++) {
            traverse(childs[i], elems)
          }
        }
      }
      let domElements = []
      traverse(document.getElementsByTagName("body")[0], domElements)
    },
    addMouseoverAnimation(element) {
      element.onmouseover = this.mouseOver
      element.onmouseout = this.mouseOut
    },
    mouseOver(e) {
      e.stopPropagation()
      let rect = e.target.getBoundingClientRect()
      // let height = rect.bottom - rect.top
      // let width = rect.right - rect.left
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper-visual"
      )
      let formattedElement = this.formatElement(e.target)
      e.target.onclick = this.selectElement
      this.hovered = {
        tagName: formattedElement.tagName,
        id: formattedElement.id,
        classListString: formattedElement.classListString
      }
      e.target.style["border-width"] = "2px"
      e.target.style["border-color"] = "#3371f2"
      e.target.style["border-style"] = "solid"

      /*
      selectorWrapper.style.width = width + "px"
      selectorWrapper.style.height = height + "px"
*/
      selectorWrapper.style.top = rect.top + "px"
      selectorWrapper.style.left = rect.left + "px"
      selectorWrapper.style.display = "block"
    },
    mouseOut(e) {
      e.stopPropagation()
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper-visual"
      )
      this.hovered = { tagName: "", id: "", classListString: "" }
      selectorWrapper.style.display = "block"
      e.target.style["border-width"] = null
      e.target.style["border-color"] = null
      e.target.style["border-style"] = null
      e.target.onclick = null
    },
    formatElement(element) {
      return {
        tagName: element.tagName.toLowerCase(),
        id: element.id,
        classList: element.className.split(" "),
        classListString: element.className,
        parents: this.getParentsOfElement(element),
        queryString: this.getQueryString(element),
        children: this.getChildNodes(element),
        element: {}
      }
    },
    getParentsOfElement(element) {
      let result = []
      while (
        element &&
        element.nodeName !== "#document" &&
        element.nodeName !== "HTML"
      ) {
        result.unshift(this.getQueryString(element))
        element = element.parentNode
      }
      result.pop()
      return result
    },
    getQueryString(element) {
      let result = ""

      function loop(element) {
        if (element.nodeType === Node.ELEMENT_NODE) {
          // stop here = element has ID
          if (element.getAttribute("id")) {
            result = result.replace(/^/, " #" + element.getAttribute("id"))
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // stop here = element is body
          if (document.body === element) {
            result = result.replace(/^/, " body")
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // concat all classes in "queryselector" style
          if (element.getAttribute("class")) {
            let elemClasses = "."
            elemClasses += element.getAttribute("class")
            elemClasses = elemClasses.replace(/\s/g, ".")
            elemClasses = elemClasses.replace(/^/g, " ")
            let classNth = ""

            // check if element class is the unique child
            let childrens = element.parentNode.children

            let similarElements = []
            for (let i = 0; i < childrens.length; i++) {
              if (
                element.getAttribute("class") ==
                  childrens[i].getAttribute("class") ||
                element.tagName == childrens[i].tagName
              ) {
                similarElements.push(childrens[i])
              }
            }
            if (similarElements.length > 1) {
              for (let j = 0; j < similarElements.length; j++) {
                if (element === similarElements[j]) {
                  j++
                  classNth = ":nth-of-type(" + j + ")"
                  break
                }
              }
            }

            result = result.replace(/^/, elemClasses + classNth)
          } else {
            // get nodeType
            let name = element.nodeName
            name = name.toLowerCase()
            let nodeNth = ""

            let childrens = element.parentNode.children

            if (childrens.length > 1) {
              let similarNodes = []

              for (let i = 0; i < childrens.length; i++) {
                if (element.nodeName == childrens[i].nodeName) {
                  similarNodes.push(childrens[i])
                }
              }

              if (similarNodes.length > 1) {
                for (let j = 0; j < similarNodes.length; j++) {
                  if (element === similarNodes[j]) {
                    j++
                    nodeNth = ":nth-of-type(" + j + ")"
                    break
                  }
                }
              }
            }

            result = result.replace(/^/, " " + name + nodeNth)
          }

          if (element.parentNode) {
            loop(element.parentNode)
          } else {
            result = result.replace(/\s/g, " > ")
            result = result.replace(/\s/, "")
            return result
          }
        } else {
          result = ""
        }
      }

      loop(element)

      return result
    },
    getChildNodes(element) {
      let result = []
      let self = this
      for (let i = 0; i < element.childNodes.length; i++) {
        let childNode = element.childNodes[i]
        let queryString = self.getQueryString(childNode)
        result.push(queryString)
      }
      return result
    },
    selectElement(e) {
      this.selected = this.formatElement(e.target)
    },
    save() {
      this.$emit("selected", this.selected)
    },
    cancel() {
      this.$emit("canceled")
    }
  },
  mounted() {
    let backDropBlurElement = document.getElementsByClassName(
      "pw---background-blur"
    )
    backDropBlurElement[0].style["display"] = "none"

    //Remove all links from a elements or buttons
    var allAElements = document.querySelectorAll("a")
    allAElements.forEach(element => {
      element.removeAttribute("href")
    })

    this.dragElement(document.getElementById("pw---visual-selector"))
    this.enableSelector()
  },
  beforeDestroy() {
    let backDropBlurElement = document.getElementsByClassName(
      "pw---background-blur"
    )
    backDropBlurElement[0].style["display"] = "block"
  }
}
</script>
