<template>
  <div>
    <div class="pw---embed">
      <div class="pw---scroll-wrapper">
        <div
          class="pw---main"
          style="padding-left: 20px; padding-right: 20px; padding-top: 17px"
        >
          <div v-if="!loadingUserToken">
            <div class="pw---flex-row pw---heading">
              <div class="pw---flex-row">
                <img
                  src="https://widget.platformwizards.com/images/logo.svg"
                  loading="lazy"
                  alt=""
                  class="pw---logo"
                />
                <div class="pw---flex-row" style="margin-left: 10px">
                  <h5>Preview Settings</h5>
                </div>
              </div>
            </div>
            <div class="pw---top-15">
              <div class="pw---seperator pw---small"></div>
            </div>
            <div class="pw---settings-wrap">
              <h5 class="pw---normal pw---bottom-10">View as user</h5>
              <InputToggle
                messageOnTrue="Yes"
                messageOnFalse="No"
                :initialValue="preview.viewAsLoggedInUser"
                @change="
                  event => {
                    preview.viewAsLoggedInUser = event
                    if (preview.viewAsLoggedInUser) {
                      loadUsers()
                    } else {
                      preview.viewAs = {}
                    }
                  }
                "
              />
              <LoadingAnimation v-if="loadingUsers" />
              <p
                v-if="loadingUserError"
                style="color: #ff715d; padding-top: 5px"
              >
                {{ loadingUserError }}
              </p>
              <InputSelect
                v-if="preview.viewAsLoggedInUser && !loadingUsers"
                title="Users"
                placeholder="Select user"
                :selectOptions="formatedUsers"
                :initialValue="preview.viewAs"
                @change="selectedUser($event)"
              />
            </div>
            <div
              class="pw---settings-wrap"
              v-if="navigationParameters.length > 0"
            >
              <div class="pw---flexrow pw---left">
                <div class="pw---highlight pw---orange"></div>
                <h5 class="pw---normal">Navigation Parameters</h5>
              </div>
              <div
                v-for="navigationParameter of navigationParameters"
                :key="navigationParameter.id"
              >
                <div
                  class="pw---select-value pw---orange pw---no-margin"
                  style="margin-bottom: 5px; margin-top: 15px; width: fit-content;"
                >
                  <p>{{ navigationParameter.name }}</p>
                  <p class="pw---25 pw---5-left">
                    {{ navigationParameter.dataType }}
                  </p>
                </div>
                <InputText
                  inputType="text"
                  :initialValue="preview.parameters[navigationParameter.name]"
                  :placeholder="'Value of ' + navigationParameter.name"
                  @change="setParameter($event, navigationParameter.name)"
                />
              </div>
            </div>
            <div class="pw---flex-row pw---top-15 pw---right">
              <div
                class="pw---button pw---medium pw---ultra-light pw---right-10"
                @click="$router.push({ name: 'Menu' })"
              >
                <p>Cancel</p>
              </div>
              <div
                class="pw---button pw---medium pw---light-blue pw---width-130"
                :style="{ opacity: previewButtonIsActive ? 1 : 0.2 }"
                @click="
                  () => {
                    if (previewButtonIsActive) showPreview()
                  }
                "
              >
                <p>Preview</p>
              </div>
            </div>
          </div>
          <LoadingAnimation v-if="loadingUserToken" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let check = require("./../auth/check.js")
import InputToggle from "./../components/ui/InputToggle"
import InputSelect from "./../components/ui/InputSelect"
import InputText from "./../components/ui/InputText"
import LoadingAnimation from "./../components/misc/LoadingAnimation"

export default {
  name: "PreviewSettings",
  components: {
    InputToggle,
    InputSelect,
    InputText,
    LoadingAnimation
  },
  data() {
    return {
      preview: {
        viewAsLoggedInUser: false,
        viewAs: {},
        parameters: {}
      },
      loadingUsers: false,
      loadingUserToken: false,
      loadedUsers: [],
      loadingUserError: "",
      allParametersSet: false
    }
  },
  methods: {
    async showPreview() {
      // If logged in, get auth token of user
      if (this.preview.viewAsLoggedInUser) {
        let userEmail = this.preview.viewAs.id
        this.loadingUserToken = true
        let userAuthToken = await this.getUserAuthToken(userEmail)
        check.setCookie("pw---u-token", userAuthToken, 0.1)
      }
      // Set preview cookie
      check.setCookie("pw---preview", "true", 0.1)
      // Determine Link (add parameters)
      let href = new URL(window.location.href)
      for (const [key, value] of Object.entries(this.preview.parameters)) {
        href.searchParams.set(key, value)
      }
      // Go to link / reload
      window.location.href = href
    },
    async getUserAuthToken(email) {
      var myHeaders = new Headers()
      myHeaders.append("access_token", this.$store.state.adminUser.jwt)
      myHeaders.append(
        "projectid",
        document.querySelector("platform-wizards").getAttribute("projectid")
      )
      myHeaders.append("email", email)
      myHeaders.append("Content-Type", "application/json")

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      }

      return await fetch(
        "https://us-central1-platform-wizards-main.cloudfunctions.net/getAuthTokenForEmail",
        requestOptions
      )
        .then(async response => {
          let status = response.status
          response = await response.json()
          if (status === 200) {
            return response.authToken
          } else {
            return ""
          }
        })
        .catch(error => {
          throw new Error(error)
        })
    },
    selectedUser(event) {
      this.preview.viewAs = event
    },
    async loadUsers() {
      this.loadingUsers = true
      this.loadingUserError = ""

      var myHeaders = new Headers()
      myHeaders.append("access_token", this.$store.state.adminUser.jwt)
      myHeaders.append(
        "projectid",
        document.querySelector("platform-wizards").getAttribute("projectid")
      )

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      }

      fetch(
        "https://us-central1-platform-wizards-main.cloudfunctions.net/loadPreviewUsers",
        requestOptions
      )
        .then(async response => {
          this.loadingUsers = false
          if (response.status == 200) {
            response.json().then(res => {
              this.loadedUsers = res.users
            })
          } else {
            response = await response.json()
            this.loadingUserError = response.message
          }
        })
        .catch(error => {
          this.loadingUsers = false
          this.loadingUserError = error
          throw new Error(error)
        })
    },
    setParameter(event, id) {
      this.preview.parameters[id] = event
      this.allParametersAreSet()
    },
    allParametersAreSet() {
      let foundMissingParameter = false
      let numberOfNavParams = this.navigationParameters.length
      let numberOfExistingNavParams = 0
      for (const [, value] of Object.entries(this.preview.parameters)) {
        if (!value) {
          foundMissingParameter = true
        }
        numberOfExistingNavParams++
      }
      if (numberOfExistingNavParams !== numberOfNavParams) {
        foundMissingParameter = true
      }
      this.allParametersSet = !foundMissingParameter
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug == pathName)
      if (!page) {
        return {}
      } else {
        return page
      }
    },
    previewButtonIsActive() {
      let result = true
      if (
        this.preview.viewAsLoggedInUser &&
        !Object.keys(this.preview.viewAs).length > 0
      ) {
        result = false
      }
      if (!this.allParametersSet) {
        result = false
      }
      return result
    },
    navigationParameters() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].parameters
      ) {
        return this.$store.state.config[this.currentPage.id].parameters
      } else return []
    },
    formatedUsers() {
      let result = []
      this.loadedUsers.forEach(user => {
        result.push({ title: user.email, id: user.email })
      })
      return result
    }
  },
  mounted() {
    this.allParametersAreSet()
  }
}
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #ffffff;
}
input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #3371f2;
}
</style>
