<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div class="pw---flexrow pw---left">
      <label class="switch" style="margin-bottom: 0px">
        <input v-model="value" type="checkbox" />
        <span class="slider round"></span>
      </label>
      <p v-if="value" class="pw---left-10 pw---50">
        {{ messageOnTrue ? messageOnTrue : "Yes" }}
      </p>
      <p v-if="!value" class="pw---left-10 pw---50">
        {{ messageOnFalse ? messageOnFalse : "No" }}
      </p>
    </div>
  </div>
</template>

<script>
/*

*/

export default {
  name: "InputToggle",
  props: {
    title: String,
    subtitle: String,
    messageOnTrue: String,
    messageOnFalse: String,
    initialValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.initialValue ? true : false
    }
  },
  watch: {
    value: function(value) {
      this.$emit("change", value)
    },
    initialValue() {
      this.value = this.initialValue
    }
  }
}
</script>
