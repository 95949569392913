import Vue from "vue"
import Vuex from "vuex"
const axios = require("axios")

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: {},
    pages: [],
    adminUser: {},
    user: {},
    database: {},
    authentication: {},
    renderData: {},
    renderLog: []
  },
  mutations: {
    updateConfig(state, config) {
      state.config = config
    },
    updatePages(state, pages) {
      state.pages = pages
    },
    updateUser(state, user) {
      state.user = user
    },
    updateAdminUser(state, user) {
      state.adminUser = user
    },
    updateDatabase(state, database) {
      state.database = database
    },
    updateAuthentication(state, authentication) {
      state.authentication = authentication
    },
    updateRenderData(state, data) {
      state.renderData = data
    },
    addToRenderData(state, data) {
      let index
      state.renderData.forEach((response, i) => {
        if (response.id === data.id) {
          index = i
        }
      })
      if (index === undefined) {
        state.renderData.push(data)
      } else {
        state.renderData[index] = data
      }
    },
    addRenderLog(state, log) {
      state.renderLog.push(log)
    },
    clearRenderLog(state) {
      state.renderLog = []
    },
    saveLoadRequest(state, data) {
      let index
      if (
        state.config[data.pageId] &&
        state.config[data.pageId].load &&
        state.config[data.pageId].load.requests
      ) {
        index = state.config[data.pageId].load.requests.findIndex(
          request => request.id === data.request.id
        )
      } else {
        index = -1
      }
      if (index !== -1) {
        state.config[data.pageId].load.requests[index] = data.request
      } else {
        if (!state.config[data.pageId]) {
          state.config[data.pageId] = { load: { requests: [data.request] } }
        } else if (!state.config[data.pageId].load) {
          state.config[data.pageId].load = { requests: [data.request] }
        } else if (!state.config[data.pageId].load.requests) {
          state.config[data.pageId].load.requests = [data.request]
        } else {
          let requests = state.config[data.pageId].load.requests
          requests.push(data.request)
          state.config[data.pageId].load.requests = requests
        }
      }
    },
    deleteLoadRequest(state, data) {
      let index = state.config[data.pageId].load.requests.findIndex(
        request => request.id == data.requestId
      )
      let requests = state.config[data.pageId].load.requests
      requests.splice(index, 1)
      state.config[data.pageId].load.requests = requests
    },
    saveWriteRequest(state, data) {
      let index
      if (
        state.config[data.pageId] &&
        state.config[data.pageId].write &&
        state.config[data.pageId].write.requests
      ) {
        index = state.config[data.pageId].write.requests.findIndex(
          request => request.id === data.request.id
        )
      } else {
        index = -1
      }
      if (index !== -1) {
        state.config[data.pageId].write.requests[index] = data.request
      } else {
        if (!state.config[data.pageId]) {
          state.config[data.pageId] = { load: { requests: [data.request] } }
        } else if (!state.config[data.pageId].write) {
          state.config[data.pageId].write = { requests: [data.request] }
        } else if (!state.config[data.pageId].write.requests) {
          state.config[data.pageId].write.requests = [data.request]
        } else {
          let requests = state.config[data.pageId].write.requests
          requests.push(data.request)
          state.config[data.pageId].write.requests = requests
        }
      }
    },
    deleteWriteRequest(state, data) {
      let index = state.config[data.pageId].write.requests.findIndex(
        request => request.id == data.requestId
      )
      let requests = state.config[data.pageId].write.requests
      requests.splice(index, 1)
      state.config[data.pageId].write.requests = requests
    },
    saveAction(state, data) {
      let index
      if (state.config[data.pageId] && state.config[data.pageId].actions) {
        index = state.config[data.pageId].actions.findIndex(
          action => action.id === data.action.id
        )
      } else {
        index = -1
      }
      if (index !== -1) {
        state.config[data.pageId].actions[index] = data.action
      } else {
        if (!state.config[data.pageId]) {
          state.config[data.pageId] = { actions: [data.action] }
        } else if (!state.config[data.pageId].actions) {
          state.config[data.pageId].actions = [data.action]
        } else {
          let actions = state.config[data.pageId].actions
          actions.push(data.action)
          state.config[data.pageId].actions = actions
        }
      }
    },
    deleteAction(state, data) {
      let index = state.config[data.pageId].actions.findIndex(
        action => action.id == data.actionId
      )
      let actions = state.config[data.pageId].actions
      actions.splice(index, 1)
      state.config[data.pageId].actions = actions
    },
    saveParameter(state, data) {
      let index
      if (state.config[data.pageId] && state.config[data.pageId].parameters) {
        index = state.config[data.pageId].parameters.findIndex(
          parameter => parameter.id === data.parameter.id
        )
      } else {
        index = -1
      }
      if (index !== -1) {
        state.config[data.pageId].parameters[index] = data.parameter
      } else {
        if (!state.config[data.pageId]) {
          state.config[data.pageId] = { parameters: [data.parameter] }
        } else if (!state.config[data.pageId].parameters) {
          state.config[data.pageId].parameters = [data.parameter]
        } else {
          let parameters = state.config[data.pageId].parameters
          parameters.push(data.parameter)
          state.config[data.pageId].parameters = parameters
        }
      }
    },
    deleteParameter(state, data) {
      let index = state.config[data.pageId].parameters.findIndex(
        parameter => parameter.id == data.parameterId
      )
      let parameters = state.config[data.pageId].parameters
      parameters.splice(index, 1)
      state.config[data.pageId].parameters = parameters
    },
    saveAuthentication(state, data) {
      if (state.config[data.pageId] && !state.config[data.pageId].load) {
        state.config[data.pageId].load = { authentication: data.authentication }
      } else if (state.config[data.pageId].load) {
        state.config[data.pageId].load.authentication = data.authentication
      }
    },
    saveLogin(state, data) {
      if (state.config[data.pageId] && !state.config[data.pageId].load) {
        state.config[data.pageId].load = { login: data.login }
      } else if (state.config[data.pageId].load) {
        state.config[data.pageId].load.login = data.login
      }
    },
    saveConnection(state, data) {
      let index
      if (
        state.config[data.pageId] &&
        state.config[data.pageId].load &&
        state.config[data.pageId].load.connections
      ) {
        // Get current index of connection
        index = state.config[data.pageId].load.connections.findIndex(
          connection => connection.id === data.connection.id
        )
      } else {
        index = -1
      }

      if (index !== -1) {
        // set connection to index
        state.config[data.pageId].load.connections[index] = data.connection
      } else {
        if (!state.config[data.pageId] || !state.config[data.pageId].load) {
          // load does not exists
          state.config[data.pageId] = {
            load: { connections: [data.connection] }
          }
        } else if (
          state.config[data.pageId].load &&
          !state.config[data.pageId].load.connections
        ) {
          // load does exist
          state.config[data.pageId].load.connections = [data.connection]
        } else {
          //connections do already does exist
          let connections = state.config[data.pageId].load.connections
          connections.push(data.connection)
          state.config[data.pageId].load.connections = connections
        }
      }
    },
    deleteConnection(state, data) {
      let index = state.config[data.pageId].load.connections.findIndex(
        connection => connection.id == data.connectionId
      )
      let connections = state.config[data.pageId].load.connections
      connections.splice(index, 1)
      state.config[data.pageId].load.connections = connections
    },
    savePageToConfig(state, page) {
      let config = state.config
      if (config) {
        if (config[page.id] === undefined) {
          config[page.id] = {}
        }
      } else {
        config = {}
        config[page.id] = {}
      }
      state.config = config
    },
    deletePageFromConfig(state, page) {
      let config = state.config
      console.log("trying to delete: " + page.id + " from:", config)
      console.log("before: ", config[page.id])
      delete config[page.id]
      console.log("after: ", config[page.id])
      state.config = config
    }
  },
  actions: {
    async saveConfig({ state, actions }) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/saveProjectConfig",
          headers: {
            access_token: state.adminUser.jwt,
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid")
          },
          data: { config: state.config }
        })
          .then(function (response) {
            if (response.data) {
              resolve(response.data)
              actions.getAdminConfig()
            } else {
              reject()
            }
          })
          .catch(function (err) {
            reject(err)
          })
      })
    },
    async getAdminConfig({ commit }, token) {
      return new Promise(resolve => {
        axios({
          method: "get",
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/getProjectAdminConfig",
          headers: {
            access_token: token,
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid")
          }
        })
          .then(function (response) {
            if (response.data) {
              resolve(response.data)
              commit("updateConfig", response.data.config)
              commit("updatePages", response.data.pages)
              commit("updateDatabase", response.data.database)
              commit("updateAuthentication", response.data.authentication)
            } else {
              resolve(false)
            }
          })
          .catch(function () {
            resolve(false)
          })
      })
    },
    async getConfig({ commit }) {
      return new Promise(resolve => {
        axios({
          method: "get",
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/getProjectConfig",
          headers: {
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid")
          }
        })
          .then(function (response) {
            if (response.data) {
              resolve(response.data)
              commit("updateConfig", response.data.config)
              commit("updatePages", response.data.pages)
              commit("updateAuthentication", response.data.authentication)
            } else {
              resolve(false)
            }
            document.querySelector("body").style.display = "block"
          })
          .catch(function () {
            document.querySelector("body").style.display = "block"
            resolve(false)
          })
      })
    }
  }
})
