<template>
  <div class="pw---pw-second">
    <div v-show="!loading">
      <div class="pw---flex-row pw---heading">
        <div class="pw---flex-row">
          <h5 v-if="config.name !== ''">{{ config.name }}</h5>
          <h5 v-if="config.name === ''">Configure request</h5>
        </div>
        <div
          class="pw---button pw---small pw---light-white pw---icon"
          v-if="!newRequest"
          @click="deleteRequest"
        >
          <img
            src="https://widget.platformwizards.com/images/white_trash.svg"
            width="20"
            alt=""
            class="_20px-icon"
          />
        </div>
      </div>
      <div class="pw---top-15">
        <div class="pw---seperator pw---small"></div>
      </div>
      <InputText
        title="Request name"
        subtitle="*"
        inputType="text"
        placeholder=""
        @change="config.name = $event"
        :initialValue="config.name"
      />
      <div class="pw---settings-wrap">
        <p class="pw---bottom-5">Run request on page load</p>
        <InputToggle
          title=""
          subtitle=""
          messageOnTrue="Yes"
          messageOnFalse="No"
          @change="config.performOnPageLoad = $event"
          :initialValue="config.performOnPageLoad"
        />
      </div>
      <div class="pw---settings-wrap">
        <h5>Request</h5>
        <InputSelect
          title="Table"
          subtitle="*"
          :selectOptions="tables"
          placeholder="Select table"
          @change="selectTable($event)"
          :initialValue="selectedTable"
        />
        <InputSelect
          title="Request Type"
          subtitle="*"
          v-bind:selectOptions="requestTypes"
          placeholder="Select request type"
          @change="config.requestType = $event.id"
          :initialValue="selectedRequestType"
          :hideRemove="true"
        />
        <InputValue
          v-if="config.requestType !== 'list'"
          title="Record Id"
          subtitle="*"
          :initialValue="config.recordId"
          :id="'pw---record-id'"
          :selectOptions="recordIdParameters"
          @change="config.recordId = $event"
        />
        <InputFormula
          v-if="config.requestType === 'list'"
          title="Filter"
          subtitle=""
          :id="'pw---filterByFormula'"
          :initialValue="config.filterByFormula"
          :fields="fields"
          :navigationParameters="navigationParameters[0]"
          :authFields="authFields"
          @change="config.filterByFormula = $event"
        />
        <InputSelect
          v-if="config.requestType === 'list'"
          title="Sort"
          subtitle=""
          :selectOptions="values"
          :initialValue="config.sort.column"
          placeholder="Select Column"
          :hasBoxAroundOptions="true"
          @change="config.sort.column = $event"
        />
        <InputSelect
          v-if="config.requestType === 'list'"
          style="margin-top: 5px;"
          title=""
          subtitle=""
          placeholder="Sort direction"
          :initialValue="selectedSortDirection"
          @change="config.sort.direction = $event.id"
          :selectOptions="sortDirections"
        />
        <InputSelect
          v-if="config.requestType === 'list'"
          title="View"
          subtitle=""
          :selectOptions="views"
          placeholder="Select view"
          @change="config.viewId = $event.id"
          :initialValue="selectedView"
        />
        <InputSelect
          v-if="config.requestType === 'list'"
          title="Select Values"
          :selectOptions="values"
          placeholder="Select Values"
          :isMultipleSelect="true"
          :hasBoxAroundOptions="true"
          @change="config.values = $event"
          :initialValue="config.values"
        />
        <InputText
          v-if="config.requestType === 'list'"
          title="Max Records"
          inputType="number"
          :initialValue="config.maxRecords"
          placeholder="100"
          v-bind:min="1"
          v-bind:max="100"
          @change="config.maxRecords = parseInt($event, 10)"
        />
      </div>
      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight"></div>
          <h5 class="pw---normal">While loading</h5>
        </div>
        <FieldElement
          title="Show element(s)"
          subtitle="Select loading animation (e.g. a loading spinner)"
          :initialValue="config.states.whileLoading.showElements"
          @open-selector="elementSeletorOpenFor = 'loading-element'"
          @unselect="unselectValueFrom('loading-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Hide element(s) until loaded"
          subtitle="e.g. hide content where the loaded data should be displayed"
          :initialValue="config.states.whileLoading.hideUntilLoaded"
          @open-selector="elementSeletorOpenFor = 'loading-hideUntil'"
          @unselect="unselectValueFrom('loading-hideUntil', $event)"
          v-on="$listeners"
        />
      </div>

      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight pw---red"></div>
          <h5 class="pw---normal">On Error</h5>
        </div>
        <FieldElement
          title="Empty response placeholder"
          subtitle="Show element(s) when no data could be loaded (e.g. no data matched the filter)"
          :initialValue="config.states.onError.showWhenResponseEmpty"
          @open-selector="elementSeletorOpenFor = 'error-onEmpty'"
          @unselect="unselectValueFrom('error-onEmpty', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Show element(s)"
          subtitle="Select error message block"
          :initialValue="config.states.onError.showElements"
          @open-selector="elementSeletorOpenFor = 'error-element'"
          @unselect="unselectValueFrom('error-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Insert error message to element(s)"
          subtitle="Select error message text"
          :initialValue="config.states.onError.insertErrorTo"
          @open-selector="elementSeletorOpenFor = 'error-msg'"
          @unselect="unselectValueFrom('error-msg', $event)"
          v-on="$listeners"
        />
      </div>
      <Save
        @save="saveRequest"
        @cancel="$emit('cancel')"
        :saveIsActive="saveIsActive"
      />
    </div>
    <LoadingAnimation v-if="loading" />
  </div>
</template>

<script>
import Save from "./../ui/Save"
import InputText from "./../ui/InputText"
import InputToggle from "./../ui/InputToggle"
import InputSelect from "./../ui/InputSelect"
import FieldElement from "./../ui/FieldElement"
import InputValue from "./../ui/InputValue"
import InputFormula from "./../ui/InputFormula"
import LoadingAnimation from "./../misc/LoadingAnimation"

export default {
  name: "LoadRequestConfig",
  components: {
    Save,
    InputText,
    InputSelect,
    InputToggle,
    FieldElement,
    InputValue,
    InputFormula,
    LoadingAnimation
  },
  props: {
    elementSelectorValue: Object,
    requestConfig: Object,
    currentPage: Object
  },
  data() {
    return {
      config: {
        id: "",
        name: "",
        performOnPageLoad: false,
        tableId: "",
        requestType: "list",
        recordId: "",
        filterByFormula: {},
        sort: {
          column: {},
          direction: ""
        },
        viewId: "",
        values: [],
        maxRecords: "",
        states: {
          whileLoading: {
            showElements: [],
            hideUntilLoaded: []
          },
          onError: {
            showWhenResponseEmpty: [],
            showElements: [],
            insertErrorTo: []
          }
        }
      },
      elementSeletorOpenFor: String,
      loading: false,
      newRequest: false
    }
  },
  methods: {
    selectTable(event) {
      this.config.tableId = event.id
      this.config.values = []
      this.config.viewId = ""
    },
    unselectValueFrom(attribute, value) {
      if (attribute == "loading-element") {
        let index = this.config.states.whileLoading.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.whileLoading.showElements.splice(index, 1)
      }
      if (attribute == "loading-hideUntil") {
        let index = this.config.states.whileLoading.hideUntilLoaded.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.whileLoading.hideUntilLoaded.splice(index, 1)
      }
      if (attribute == "error-onEmpty") {
        let index = this.config.states.onError.showWhenResponseEmpty.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onError.showWhenResponseEmpty.splice(index, 1)
      }
      if (attribute == "error-element") {
        let index = this.config.states.onError.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onError.showElements.splice(index, 1)
      }
      if (attribute == "error-msg") {
        let index = this.config.states.onError.insertErrorTo.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onError.insertErrorTo.splice(index, 1)
      }
      this.elementSeletorOpenFor = String
    },
    async saveRequest() {
      let request = this.config
      this.loading = true
      this.$store.commit("saveLoadRequest", {
        request: request,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
        await this.$store.dispatch(
          "getAdminConfig",
          this.$store.state.adminUser.jwt
        )
      } catch (e) {
        throw new Error(e)
      }
      this.$emit("saved")
      this.loading = false
    },
    generateRequestId() {
      let id = this.testIfIdAlredyTaken(id)
      return id
    },
    idGenerator(length) {
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    testIfIdAlredyTaken() {
      let id = this.idGenerator(15)
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        let index = this.$store.state.config[
          this.currentPage.id
        ].load.requests.findIndex(request => request.id == id)
        if (index === -1) {
          return id
        } else {
          return this.testIfIdAlredyTaken(id)
        }
      } else {
        return id
      }
    },
    async deleteRequest() {
      if (
        window.confirm(
          "Are you sure you want to delete this request? All data connections with this request will be lost."
        )
      ) {
        this.loading = true
        this.$store.commit("deleteLoadRequest", {
          requestId: this.config.id,
          pageId: this.currentPage.id
        })
        let connectionsOfRequest = this.$store.state.config[
          this.currentPage.id
        ].load.connections.filter(
          connection => connection.requestId == this.config.id
        )
        connectionsOfRequest.forEach(connection => {
          this.$store.commit("deleteConnection", {
            connectionId: connection.id,
            pageId: this.currentPage.id
          })
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        this.$emit("saved")
        this.loading = false
      }
    }
  },
  computed: {
    selectedTable() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table) {
        return {
          id: table.id,
          title: table.name
        }
      } else {
        return null
      }
    },
    tables() {
      let result = []
      this.$store.state.database.forEach(table => {
        result.push({
          id: table.id,
          title: table.name
        })
      })
      return result
    },
    selectedRequestType() {
      if (this.config.requestType === "item") {
        return {
          id: "item",
          title: "Single item"
        }
      } else {
        return {
          id: "list",
          title: "Get list of items"
        }
      }
    },
    requestTypes() {
      return [
        {
          id: "item",
          title: "Single item"
        },
        {
          id: "list",
          title: "Get list of items"
        }
      ]
    },
    selectedView() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table) {
        let view = table.views.find(view => {
          return view.id === this.config.viewId
        })
        if (view) {
          return {
            id: view.id,
            title: view.name
          }
        } else {
          return []
        }
      } else {
        return []
      }
    },
    views() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table) {
        let result = []
        table.views.forEach(view => {
          result.push({ id: view.id, title: view.name })
        })
        return result
      } else {
        return []
      }
    },
    values() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table && table.fields) {
        let result = []
        table.fields.forEach(value => {
          result.push({ id: value.id, title: value.name })
        })
        return result
      } else {
        return []
      }
    },
    sortDirections() {
      return [
        { id: "asc", title: "ascending" },
        { id: "desc", title: "descending" },
        { id: "random", title: "random" }
      ]
    },
    selectedSortDirection() {
      let direction = this.sortDirections.find(
        sortDirection => sortDirection.id === this.config.sort.direction
      )
      if (!direction) {
        return {}
      } else {
        return direction
      }
    },
    filterByFormulaParameters() {
      let result = []
      if (this.navigationParameters[0].properties.length > 0)
        result.push(this.navigationParameters[0])
      if (this.authFields.properties.length > 0) result.push(this.authFields)
      if (this.fields.properties.length > 0) result.push(this.fields)
      return result
    },
    recordIdParameters() {
      let result = []
      if (this.navigationParameters[0].properties.length > 0)
        result.push(this.navigationParameters[0])
      if (this.authFields.properties.length > 0) result.push(this.authFields)
      return result
    },
    navigationParameters() {
      let parameters = []
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        Array.isArray(this.$store.state.config[this.currentPage.id].parameters)
      ) {
        this.$store.state.config[this.currentPage.id].parameters.forEach(
          param => {
            parameters.push(param.name)
          }
        )
      }
      return [
        {
          id: "parameter",
          title: "Navigation Parameters",
          properties: parameters
        }
      ]
    },
    authFields() {
      let authFields = []
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.authentication &&
        this.$store.state.config[this.currentPage.id].load.authentication
          .isActive
      ) {
        let authTable = this.$store.state.database.find(
          table => table.id === this.$store.state.authentication.table.id
        )
        if (authTable && authTable.fields) {
          authTable.fields.forEach(value => {
            authFields.push(value.name)
          })
        }
      }
      return {
        id: "auth",
        title: "Authentication Data",
        properties: authFields
      }
    },
    fields() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      let fields = []
      if (table && table.fields) {
        table.fields.forEach(value => {
          fields.push(value.name)
        })
      }
      return {
        id: "field",
        title: "Fields",
        properties: fields
      }
    },
    saveIsActive() {
      if (this.config.name && this.config.tableId) {
        if (
          this.config.requestType === "list" &&
          this.config.filterByFormula.formula !== "AND)"
        ) {
          return true
        } else if (this.config.requestType === "item" && this.config.recordId) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        if (this.elementSeletorOpenFor == "loading-element")
          this.config.states.whileLoading.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "loading-hideUntil")
          this.config.states.whileLoading.hideUntilLoaded.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "error-onEmpty")
          this.config.states.onError.showWhenResponseEmpty.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "error-element")
          this.config.states.onError.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "error-msg")
          this.config.states.onError.insertErrorTo.push(
            this.elementSelectorValue
          )
        this.elementSeletorOpenFor = String
      }
    }
  },
  mounted() {
    this.config = { ...this.config, ...this.requestConfig }
    if (this.config.id === "") {
      this.newRequest = true
      let id = this.generateRequestId()
      this.config.id = id
    }
  }
}
</script>
