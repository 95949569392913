let renderList = (connection, requestData, vm) => {
  let elementQueryString = connection.element.queryString
  let values = requestData.values
  let originalElement = document.querySelector(elementQueryString)
  vm.saveOriginalElementDisplayStyle(originalElement)
  // eslint-disable-next-line no-undef
  let element = $(elementQueryString)

  // duplicate element for every element of values
  if (values.length > 0) {
    // If values are > 0 - Clone orginalElement and render version of each
    values.forEach((value, index) => {
      let elementClone = element.clone(true)
      elementClone.css(
        "display",
        vm.getOriginalElementDisplayStyle(originalElement)
      )
      elementClone.attr("pw---value", value.id)
      let logId
      if (index === 0) {
        logId = addRenderLog(originalElement, connection, vm)
      }
      let prevRevert = elementClone.attr("pw---revert")
      elementClone.attr(
        "pw---revert",
        index === 0 ? (prevRevert ? prevRevert + " " + logId : logId) : "delete"
      )
      // eslint-disable-next-line no-undef
      $(elementClone).appendTo($(element).parent())
    })
    originalElement.remove()
  } else {
    // If Values are empty - Hide original element & log it for re-render
    let logId = addRenderLog(originalElement, connection, vm)
    originalElement.style.display = "none"
    let prevRevert = originalElement.getAttribute("pw---revert")
    originalElement.setAttribute(
      "pw---revert",
      prevRevert ? prevRevert + " " + logId : logId
    )
  }
}

let replaceText = (value, connection, element, vm) => {
  let text = parseParameterString(
    connection.method.fieldsInput.text,
    value.fields,
    vm
  )
  element.innerHTML = text
}

let hideElement = (value, connection, element, vm) => {
  if (
    value.fields[connection.value.name] &&
    !connection.method.fieldsInput.invert
  ) {
    vm.saveOriginalElementDisplayStyle(element)
    element.style.display = "none"
  } else {
    vm.saveOriginalElementDisplayStyle(element)
    element.style.display = vm.getOriginalElementDisplayStyle(element)
  }
}

let showElement = (value, connection, element, vm) => {
  if (
    value.fields[connection.value.name] &&
    !connection.method.fieldsInput.invert
  ) {
    vm.saveOriginalElementDisplayStyle(element)
    element.style.display = vm.getOriginalElementDisplayStyle(element)
  } else {
    vm.saveOriginalElementDisplayStyle(element)
    element.style.display = "none"
  }
}

let addClass = (value, connection, element) => {
  if (
    value.fields[connection.value.name] &&
    !connection.method.fieldsInput.invert
  ) {
    element.classList.add(connection.method.fieldsInput.className)
  } else {
    element.classList.remove(connection.method.fieldsInput.className)
  }
}

let removeClass = (value, connection, element) => {
  if (
    value.fields[connection.value.name] &&
    !connection.method.fieldsInput.invert
  ) {
    element.classList.remove(connection.method.fieldsInput.className)
  } else {
    element.classList.add(connection.method.fieldsInput.className)
  }
}

let replaceImage = (value, connection, element, vm) => {
  let url = parseParameterString(
    connection.method.fieldsInput.src,
    value.fields,
    vm
  )
  element.src = url
}

let replaceBG = (value, connection, element, vm) => {
  let url = parseParameterString(
    connection.method.fieldsInput.backgroundImg,
    value.fields,
    vm
  )
  element.style.backgroundImage = "url('" + url + "')"
}

let addParameter = (value, connection, element, vm) => {
  let parameterName = connection.method.fieldsInput.parameterName
  let parameterValue = parseParameterString(
    connection.method.fieldsInput.parameterValue,
    value.fields,
    vm
  )
  let href = new URL(element.href)
  href.searchParams.set(parameterName, parameterValue)
  element.href = href
}

let addStyle = (value, connection, element, vm) => {
  let cssStyles = parseParameterString(
    connection.method.fieldsInput.inlineCSS,
    value.fields,
    vm
  )
  let existingStyle = element.getAttribute("style")
  let style
  if (existingStyle) {
    style = existingStyle + ";" + cssStyles
  } else {
    style = cssStyles
  }
  if (
    value.fields[connection.value.name] &&
    !connection.method.fieldsInput.invert
  ) {
    element.setAttribute("style", style)
  } else if (
    !value.fields[connection.value.name] &&
    connection.method.fieldsInput.invert
  ) {
    element.setAttribute("style", style)
  }
}

let setAttribute = (value, connection, element, vm) => {
  let name = connection.method.fieldsInput.attributeName
  let attributeValue = parseParameterString(
    connection.method.fieldsInput.attributeValue,
    value.fields,
    vm
  )
  element.setAttribute(name, attributeValue)
}

let setValue = (value, connection, element) => {
  if (element.tagName.toLowerCase() === "input") {
    if (element.type === "checkbox") {
      let inputValue =
        value.fields[connection.value.name] == true ? true : false
      element.checked = inputValue
    } else {
      if (value.fields[connection.value.name])
        element.value = value.fields[connection.value.name]
    }
  }
}

let applyStyle = (value, connection, element, vm) => {
  let cssStyles = parseParameterString(
    connection.method.fieldsInput.inlineCSS,
    value.fields,
    vm
  )
  let existingStyle = element.getAttribute("style")
  let style
  if (existingStyle) {
    style = existingStyle + "; " + cssStyles
  } else {
    style = cssStyles
  }
  element.setAttribute("style", style)
}

//----------------------------------------------------------------

let addRenderLog = (element, connection, vm) => {
  // save original element to method log
  let id = generateId(vm)
  vm.$store.commit("addRenderLog", {
    id: id,
    element: element,
    connectionId: connection.id,
    methodId: connection.method.id
  })
  return id
}

let generateId = vm => {
  let id = Math.random()
    .toString(36)
    .substring(2, 10)
  let idIsExisting = vm.$store.state.renderLog.find(log => log.id === id)
  if (idIsExisting) {
    id = generateId(vm)
  }
  return id
}

let findListElement = (connection, value) => {
  let elements = document.querySelectorAll(
    '[pw---connection~="' + connection.id + '"]'
  )
  let searchedElement
  elements.forEach(element => {
    // find parent with matching pw---value
    let parent = element.closest('[pw---value="' + value.id + '"]')
    if (parent !== null) searchedElement = element
  })

  return searchedElement
}

let parseParameterString = (value, object, vm) => {
  let user = vm.$store.state.user
  if (value && typeof value === "string" && value.length > 0) {
    let regexp = /\{{{(a-|f-|p-)(.*?)\}}}/g
    let matches = [...value.matchAll(regexp)]
    matches.forEach(match => {
      let replacement = ""
      switch (match[1]) {
        case "a-":
          // type = "auth"
          replacement = user[match[2]]
          break
        case "f-":
          // type = "field"
          replacement = object[match[2]]
          break
        case "p-":
          // type = "parameter"
          replacement = vm.navigationParameters[match[2]]
          break
      }
      value = value.replace(match[0].toString(), replacement)
      value.toString()
      // eslint-disable-next-line no-useless-escape
      value = value.replace(/&nbsp;/g, "")
    })
  }
  return value
}

module.exports = {
  parseParameterString,
  renderList,
  replaceText,
  hideElement,
  showElement,
  findListElement,
  addRenderLog,
  addClass,
  removeClass,
  replaceImage,
  replaceBG,
  addParameter,
  addStyle,
  applyStyle,
  setAttribute,
  setValue
}
