<template>
  <div class="pw---embed">
    <div class="pw---dragndrop-wrapper">
      <div
        class="pw---main pw---preview"
        id="pw---preview"
        style="position:absolute;"
      >
        <div class="pw---flex-row pw---main-heading">
          <div class="pw---flex-row">
            <img
              src="https://widget.platformwizards.com/images/logo.svg"
              loading="lazy"
              alt=""
              class="pw---logo"
            />
            <h5 class="pw---10-left">Preview</h5>
          </div>
          <div
            @click="endPreview"
            class="pw---button pw---small pw---light-white"
          >
            <p>End Preview</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let check = require("./../auth/check.js")

export default {
  name: "Preview",
  methods: {
    dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0

      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown

      function dragMouseDown(e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag(e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px"
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px"
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null
        document.onmousemove = null
      }
    },
    endPreview() {
      check.setCookie("pw---preview", "", 0)
      check.setCookie("pw---u-token", "", 0)
      window.location.reload()
    }
  },
  mounted() {
    // let backDropBlurElement = document.getElementsByClassName(
    //   "pw---background-blur"
    // )
    // backDropBlurElement[0].style["display"] = "none"

    this.dragElement(document.getElementById("pw---preview"))
  },
  beforeDestroy() {
    // let backDropBlurElement = document.getElementsByClassName(
    //   "pw---background-blur"
    // )
    // backDropBlurElement[0].style["display"] = "block"
  }
}
</script>
