<template>
  <div class="pw---flex-row">
    <div class="pw---scroll-wrapper">
      <div class="pw---pw-second">
        <div v-if="!loading">
          <div class="pw---flex-row pw---heading">
            <div class="pw---flex-row">
              <h5 v-if="config.name">{{ config.name }}</h5>
              <h5 v-if="!config.name">Configure request</h5>
            </div>
            <div
              class="pw---button pw---small pw---light-white pw---icon"
              @click="deleteRequest"
            >
              <img
                src="https://widget.platformwizards.com/images/white_trash.svg"
                width="20"
                alt=""
                class="_20px-icon"
              />
            </div>
          </div>
          <div class="pw---top-15">
            <div class="pw---seperator pw---small"></div>
          </div>
          <InputText
            title="Request Name"
            subtitle="*"
            inputType="text"
            :initialValue="config.name"
            @change="config.name = $event"
          />
          <FieldElement
            title="Select Trigger"
            subtitle="Request is executed when element is clicked"
            :initialValue="config.trigger"
            @open-selector="elementSeletorOpenFor = 'trigger'"
            @unselect="unselectValueFrom('trigger', $event)"
            v-on="$listeners"
          />
          <div class="pw---settings-wrap">
            <h5>Request</h5>
            <InputSelect
              title="Table"
              subtitle="*"
              :selectOptions="tables"
              placeholder="Select table"
              @change="selectTable($event)"
              :initialValue="selectedTable"
            />
            <InputSelect
              title="Request Type"
              subtitle="*"
              :selectOptions="requestTypes"
              placeholder="Select request type"
              @change="config.requestType = $event.id"
              :initialValue="selectedRequestType"
              :hideRemove="true"
            />
            <div class="pw---settings-wrap">
              <div class="pw---flexrow pw---left">
                <h5 class="pw---normal">Data</h5>
              </div>
              <InputValue
                v-if="
                  config.requestType === 'update' ||
                    config.requestType === 'delete'
                "
                title="Record Id"
                subtitle="*"
                :initialValue="config.recordId"
                :id="'pw---record-id'"
                :selectOptions="loadRequestData"
                @change="config.recordId = $event"
              />
              <div v-if="config.requestType !== 'delete'">
                <div
                  class="pw---settings-wrap"
                  v-for="value of values"
                  :key="value.id"
                  style="padding-top: 5px; background-color: rgba(0, 0, 0, 0.15)"
                >
                  <div>
                    <FieldElementData
                      :title="value.title"
                      :subtitle="value.type"
                      @open-selector="openSelectConnectionElement(value)"
                      :initialValue="initialDataFieldValue(value)"
                      @unselect="deleteFieldValue(value)"
                    />
                    <div v-if="config.connections[value.id]">
                      <InputToggle
                        v-if="value.type !== 'checkbox'"
                        title="Required"
                        messageOnTrue="Yes"
                        messageOnFalse="No"
                        :initialValue="config.connections[value.id].isRequired"
                        @change="toggleRequired(value, $event)"
                      />
                      <InputToggle
                        v-if="value.type === 'checkbox'"
                        title="Reverse input"
                        messageOnTrue="Yes (example: TRUE -> FALSE)"
                        messageOnFalse="No (example: TRUE -> TRUE)"
                        :initialValue="
                          config.connections[value.id].inverted
                            ? config.connections[value.id].inverted
                            : false
                        "
                        @change="toggleInverted(value, $event)"
                      />
                      <InputText
                        v-if="config.connections[value.id].isRequired"
                        title="Error message when not filled out"
                        inputType="text"
                        :initialValue="config.connections[value.id].errorMsg"
                        @change="config.connections[value.id].errorMsg = $event"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pw---settings-wrap">
            <div class="pw---flexrow pw---left">
              <div class="pw---highlight"></div>
              <h5 class="pw---normal">While loading</h5>
            </div>
            <FieldElement
              title="Show element(s)"
              subtitle="Select loading animation (e.g. a loading spinner)"
              :initialValue="config.states.whileLoading.showElements"
              @open-selector="elementSeletorOpenFor = 'loading-element'"
              @unselect="unselectValueFrom('loading-element', $event)"
              v-on="$listeners"
            />
            <FieldElement
              title="Hide element(s) during operation"
              subtitle="e.g. hide input form during the write operation"
              :initialValue="config.states.whileLoading.hideUntilLoaded"
              @open-selector="elementSeletorOpenFor = 'loading-hideUntil'"
              @unselect="unselectValueFrom('loading-hideUntil', $event)"
              v-on="$listeners"
            />
          </div>
          <div class="pw---settings-wrap">
            <div class="pw---flexrow pw---left">
              <div class="pw---highlight pw---green"></div>
              <h5 class="pw---normal">On Success</h5>
            </div>
            <InputSelect
              title="Trigger action"
              subtitle="Selected actions will be executed after the write request"
              :selectOptions="actions"
              :initialValue="config.states.onSuccess.actions"
              @change="config.states.onSuccess.actions = $event"
              :isMultipleSelect="true"
              placeholder="Select actions"
            />
            <FieldElement
              title="Show element(s)"
              subtitle="Select elements that should be shown when operation is successfull."
              :initialValue="config.states.onSuccess.showElements"
              @open-selector="elementSeletorOpenFor = 'success-element'"
              @unselect="unselectValueFrom('success-element', $event)"
              v-on="$listeners"
            />
          </div>
          <div class="pw---settings-wrap">
            <div class="pw---flexrow pw---left">
              <div class="pw---highlight pw---red"></div>
              <h5 class="pw---normal">On Error</h5>
            </div>
            <FieldElement
              title="Show element(s)"
              subtitle="Select error message block (e.g. shown on validation error when input / attribute is required but no data was provided)"
              :initialValue="config.states.onError.showElements"
              @open-selector="elementSeletorOpenFor = 'error-element'"
              @unselect="unselectValueFrom('error-element', $event)"
              v-on="$listeners"
            />
            <FieldElement
              title="Insert error message to element(s)"
              subtitle="Select error message text"
              :initialValue="config.states.onError.insertErrorTo"
              @open-selector="elementSeletorOpenFor = 'error-msg'"
              @unselect="unselectValueFrom('error-msg', $event)"
              v-on="$listeners"
            />
          </div>
          <Save
            @save="saveRequest"
            @cancel="$emit('cancel')"
            :saveIsActive="saveIsActive"
          />
        </div>
        <LoadingAnimation v-if="loading" />
      </div>
    </div>
    <SelectData
      v-if="selectData.open"
      :value="selectData.value"
      @selected="selectedConnectionElement($event)"
      @cancel="closeSelectData()"
    />
  </div>
</template>

<script>
import SelectData from "./SelectData"
import FieldElement from "./../ui/FieldElement"
import FieldElementData from "./../ui/FieldElementData"
import InputSelect from "./../ui/InputSelect"
import InputToggle from "./../ui/InputToggle"
import InputText from "./../ui/InputText"
import InputValue from "./../ui/InputValue"
import Save from "./../ui/Save"
import LoadingAnimation from "./../misc/LoadingAnimation"

export default {
  name: "WriteRequestConfig",
  components: {
    SelectData,
    FieldElement,
    FieldElementData,
    InputSelect,
    InputText,
    Save,
    InputToggle,
    InputValue,
    LoadingAnimation
  },
  props: {
    requestConfig: Object,
    elementSelectorValue: Object,
    currentPage: Object
  },
  data() {
    return {
      config: {
        id: "",
        name: "",
        trigger: [],
        tableId: "",
        requestType: "create",
        recordId: "",
        connections: {},
        states: {
          whileLoading: {
            showElements: [],
            hideUntilLoaded: []
          },
          onSuccess: {
            actions: [],
            showElements: []
          },
          onError: {
            showElements: [],
            insertErrorTo: []
          }
        }
      },
      selectData: {
        open: false,
        value: {}
      },
      loading: false,
      elementSelectorOpenFor: String
    }
  },
  methods: {
    selectTable(event) {
      this.config.tableId = event.id
    },
    openSelectConnectionElement(value) {
      if (!this.selectData.open) {
        this.selectData.open = true
        this.selectData.value = value
      }
    },
    selectedConnectionElement(event) {
      this.config.connections[this.selectData.value.id] = event
      this.selectData.open = false
      this.selectData.value = {}
    },
    closeSelectData() {
      this.selectData.open = false
      this.selectData.value = {}
    },
    selectConnection(element) {
      if (!this.config.connections[this.selectData.value.id]) {
        this.config.connections[this.selectData.value.id] = {}
      }
      this.config.connections[this.selectData.value.id].element = element
    },
    unselectValueFrom(attribute, value) {
      if (attribute == "loading-element") {
        let index = this.config.states.whileLoading.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.whileLoading.showElements.splice(index, 1)
      }
      if (attribute == "loading-hideUntil") {
        let index = this.config.states.whileLoading.hideUntilLoaded.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.whileLoading.hideUntilLoaded.splice(index, 1)
      }
      if (attribute == "error-element") {
        let index = this.config.states.onError.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onError.showElements.splice(index, 1)
      }
      if (attribute == "trigger") {
        let index = this.config.trigger.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.trigger.splice(index, 1)
      }
      if (attribute == "success-element") {
        let index = this.config.states.onSuccess.showElements.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onSuccess.showElements.splice(index, 1)
      }
      if (attribute == "error-msg") {
        let index = this.config.states.onError.insertErrorTo.findIndex(
          element => element.queryString === value.queryString
        )
        this.config.states.onError.insertErrorTo.splice(index, 1)
      }
      if (attribute == "actions") {
        let index = this.config.states.onSuccess.actions.findIndex(
          action => action.id === value.id
        )
        this.config.states.onSuccess.actions.splice(index, 1)
      }
      this.elementSeletorOpenFor = String
    },
    toggleRequired(value, event) {
      this.config.connections[value.id].isRequired = event
    },
    toggleInverted(value, event) {
      this.config.connections[value.id].inverted = event
    },
    async saveRequest() {
      let request = this.config
      this.loading = true
      this.$store.commit("saveWriteRequest", {
        request: request,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
        await this.$store.dispatch(
          "getAdminConfig",
          this.$store.state.adminUser.jwt
        )
      } catch (e) {
        throw new Error(e)
      }
      this.$emit("saved")
      this.loading = false
    },
    async deleteRequest() {
      if (window.confirm("Are you sure you want to delete this request?")) {
        this.loading = true
        this.$store.commit("deleteWriteRequest", {
          requestId: this.config.id,
          pageId: this.currentPage.id
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        this.$emit("saved")
        this.loading = false
      }
    },
    generateRequestId() {
      let id = this.testIfIdAlredyTaken(id)
      return id
    },
    idGenerator(length) {
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    testIfIdAlredyTaken() {
      let id = this.idGenerator(15)
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        let index = this.$store.state.config[
          this.currentPage.id
        ].write.requests.findIndex(request => request.id == id)
        if (index === -1) {
          return id
        } else {
          return this.testIfIdAlredyTaken(id)
        }
      } else {
        return id
      }
    },
    initialDataFieldValue(value) {
      if (this.config.connections[value.id]) {
        return [this.config.connections[value.id]]
      } else return []
    },
    deleteFieldValue(value) {
      let connections = this.config.connections
      delete connections[value.id]
      this.config.connections = connections
      this.$forceUpdate()
    },
    addAction(action) {
      console.log(this.config.states.onSuccess)
      this.config.states.onSuccess.actions.push(action)
    },
    isDescendant(parent, child) {
      var node = child.parentNode
      while (node != null) {
        if (node == parent) {
          return true
        }
        node = node.parentNode
      }
      return false
    }
  },
  computed: {
    selectedTable() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table) {
        return {
          id: table.id,
          title: table.name
        }
      } else {
        return null
      }
    },
    tables() {
      let result = []
      this.$store.state.database.forEach(table => {
        result.push({
          id: table.id,
          title: table.name
        })
      })
      return result
    },
    selectedRequestType() {
      if (this.config.requestType === "update") {
        return {
          id: "update",
          title: "Update item"
        }
      }
      if (this.config.requestType === "create") {
        return {
          id: "create",
          title: "Create item"
        }
      } else {
        return {
          id: "delete",
          title: "Delete item"
        }
      }
    },
    requestTypes() {
      return [
        {
          id: "create",
          title: "Create new item"
        },
        {
          id: "update",
          title: "Update item"
        },
        {
          id: "delete",
          title: "Delete item"
        }
      ]
    },
    values() {
      let table = this.$store.state.database.find(
        table => table.id === this.config.tableId
      )
      if (table && table.fields) {
        let result = []
        table.fields.forEach(value => {
          if (
            value.type !== "multipleLookupValues" &&
            value.type !== "formula" &&
            value.type !== "createdBy" &&
            value.type !== "createdTime" &&
            value.type !== "lastModifiedBy" &&
            value.type !== "lastModifiedTime" &&
            value.type !== "multipleLookupValues" &&
            value.type !== "rollup" &&
            value.type !== "multipleAttachments"
          ) {
            result.push({ id: value.id, title: value.name, type: value.type })
          }
        })
        return result
      } else {
        return []
      }
    },
    getRequestsWhereDataIsAvailable() {
      // Get all requests
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        Array.isArray(
          this.$store.state.config[this.currentPage.id].load.requests
        ) &&
        Array.isArray(
          this.$store.state.config[this.currentPage.id].load.connections
        )
      ) {
        let allRequests = this.$store.state.config[this.currentPage.id].load
          .requests
        // Check if there is list request with same tableId
        let listRequests = allRequests.filter(
          request =>
            request.tableId === this.config.tableId &&
            request.requestType === "list"
        )
        // Filter connections to only match thoses that belong to found request
        let matchingListConnections = []
        this.$store.state.config[this.currentPage.id].load.connections.forEach(
          connection => {
            listRequests.forEach(request => {
              if (
                connection.method.id === "renderList" &&
                connection.requestId === request.id
              ) {
                matchingListConnections.push(connection)
              }
            })
          }
        )

        //Check if all triggers are descendends of a connection, if yes return it
        let matchingListTriggerConnections = []
        matchingListConnections.forEach(connection => {
          let connectionEl = document.querySelector(
            connection.element.queryString
          )
          this.config.trigger.forEach(trigger => {
            let triggerEl = document.querySelector(trigger.queryString)
            if (
              this.isDescendant(connectionEl, triggerEl) ||
              trigger.queryString === connection.element.queryString
            ) {
              matchingListTriggerConnections.push(connection)
            }
          })
        })

        let matchingRequests = []
        matchingListTriggerConnections.forEach(connection => {
          matchingRequests.push(connection.requestId)
        })
        // Delete all duplicates
        matchingRequests = [...new Set(matchingRequests)]

        // Return requests that match all triggers
        let result = []
        matchingRequests.forEach(requestId => {
          let request = this.$store.state.config[
            this.currentPage.id
          ].load.requests.find(request => request.id === requestId)
          let tableId = request.tableId
          let table = this.$store.state.database.find(
            table => table.id === tableId
          )
          let tableFields = []
          table.fields.forEach(field => {
            tableFields.push(field.name)
          })
          result.push({
            id: "field",
            title: request.name,
            properties: tableFields
          })
        })
        return result
      } else {
        return []
      }
    },
    getAuthData() {
      if (this.$store.state.authentication.enabled) {
        let authTable = this.$store.state.database.find(
          table => table.id === this.$store.state.authentication.table.id
        )
        let authFields = []
        if (authTable && authTable.fields) {
          authTable.fields.forEach(value => {
            authFields.push(value.name)
          })
        }
        return [
          {
            id: "auth",
            title: "Authentication Data",
            properties: authFields
          }
        ]
      } else {
        return []
      }
    },
    loadRequestData() {
      return this.getRequestsWhereDataIsAvailable.concat(this.getAuthData)
    },
    saveIsActive() {
      if (
        this.config.name &&
        this.config.tableId &&
        Object.keys(this.config.trigger).length > 0
      ) {
        if (this.config.requestType === "create") {
          return true
        } else if (
          (this.config.requestType === "update" ||
            this.config.requestType === "delete") &&
          this.config.recordId
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    actions() {
      let actions,
        result = []
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions &&
        this.$store.state.config[this.currentPage.id].actions.length > 0
      ) {
        actions = this.$store.state.config[this.currentPage.id].actions
        actions.forEach(action => {
          result.push({ id: action.id, title: action.name })
        })
      }
      return result
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        if (this.elementSeletorOpenFor == "trigger")
          this.config.trigger.push(this.elementSelectorValue)
        if (this.elementSeletorOpenFor == "loading-element")
          this.config.states.whileLoading.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "loading-hideUntil")
          this.config.states.whileLoading.hideUntilLoaded.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "success-element")
          this.config.states.onSuccess.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "error-element")
          this.config.states.onError.showElements.push(
            this.elementSelectorValue
          )
        if (this.elementSeletorOpenFor == "error-msg")
          this.config.states.onError.insertErrorTo.push(
            this.elementSelectorValue
          )
        this.elementSeletorOpenFor = String
      }
    }
  },
  mounted() {
    this.config = { ...this.config, ...this.requestConfig }
    if (this.config.id === "") {
      this.newRequest = true
      let id = this.generateRequestId()
      this.config.id = id
    }
  }
}
</script>
