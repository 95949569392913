import Vue from "vue"
import VueRouter from "vue-router"
import Pages from "../views/Pages.vue"
import Menu from "../views/Menu.vue"
import Actions from "../views/Actions.vue"
import LoadDataRequests from "../views/LoadDataRequests.vue"
import LoadDataConnect from "../views/LoadDataConnect.vue"
import WriteDataRequests from "../views/WriteDataRequests.vue"
import NavigationParameters from "../views/NavigationParameters.vue"
import PreviewSettings from "../views/PreviewSettings.vue"
import Authentication from "../views/Authentication.vue"
import Render from "../views/Render.vue"

Vue.use(VueRouter)

const routes = [
  { path: "/", name: "Menu", component: Menu },
  {
    path: "/pages",
    name: "Pages",
    component: Pages
  },
  { path: "/actions", name: "Actions", component: Actions },
  {
    path: "/loadDataRequests",
    name: "LoadDataRequests",
    component: LoadDataRequests
  },
  {
    path: "/loadDataConnect",
    name: "LoadDataConnect",
    component: LoadDataConnect
  },
  {
    path: "/writeDataRequests",
    name: "WriteDataRequests",
    component: WriteDataRequests
  },
  {
    path: "/navigationParameters",
    name: "NavigationParameters",
    component: NavigationParameters
  },
  {
    path: "/previewSettings",
    name: "PreviewSettings",
    component: PreviewSettings
  },
  {
    path: "/authentication",
    name: "Authentication",
    component: Authentication
  },
  {
    path: "/render",
    name: "Render",
    component: Render
  },
]

const router = new VueRouter({
  routes,
  mode: "abstract"
})

export default router
