<template>
  <Preview v-if="isPreview" />
</template>

<script>
let check = require("./../auth/check.js")
let methods = require("./../render/methods.js")
import Preview from "./Preview.vue"

let baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/platform-wizards-main/us-central1/"
    : "https://us-central1-platform-wizards-main.cloudfunctions.net/"

export default {
  name: "Render",
  components: { Preview },
  data() {
    return {
      originalElementDisplayStyles: []
    }
  },
  methods: {
    async validateEmailTokenIfPresent() {
      let loginToken = check.getURLParameter("login-token")
      if (loginToken) {
        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")

        var raw = JSON.stringify({
          emailToken: loginToken,
          projectId: document
            .querySelector("platform-wizards")
            .getAttribute("projectid")
        })

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        }

        return await fetch(baseURL + "validateEmailToken", requestOptions)
          .then(async response => {
            if (response.status === 200) {
              response = await response.json()
              check.setCookie("pw---u-token", response.authToken, 7)
              check.deleteParam("login-token")
              location.reload()
            } else {
              window.alert(
                "Login link expired or invalid. Please try logging in again."
              )
              let loginUrl =
                "https://" +
                window.location.hostname +
                "/" +
                this.$store.state.authentication.login.slug
              if (window.location.href !== loginUrl)
                window.location.href = loginUrl
            }
          })
          .catch(error => {
            throw new Error(error)
          })
      }
    },
    async loginIfCookieExists() {
      let userToken = check.token("pw---u-token")
      if (userToken) {
        // Login user if token is present

        //Redirect to Dashboard Page if on Login Page
        if (
          window.location.href ===
          "https://" +
            window.location.hostname +
            "/" +
            this.$store.state.authentication.login.slug
        )
          window.location.href =
            "https://" +
            window.location.hostname +
            "/" +
            this.$store.state.authentication.dashboard.slug

        if (
          this.$store.state.config &&
          this.$store.state.config[this.currentPage.id] &&
          this.$store.state.config[this.currentPage.id].load &&
          this.$store.state.config[this.currentPage.id].load.authentication &&
          this.$store.state.config[this.currentPage.id].load.authentication
            .isActive
        ) {
          let vm = this

          // Connect logout action to logout buttons
          if (
            Array.isArray(
              this.$store.state.config[this.currentPage.id].load.authentication
                .logoutButtons
            )
          ) {
            this.$store.state.config[
              this.currentPage.id
            ].load.authentication.logoutButtons.forEach(buttonConfig => {
              this.$jq(buttonConfig.queryString).on("click", () => {
                check.setCookie("pw---u-token", "", 0)
                setTimeout(() => {
                  window.location.href =
                    "https://" +
                    window.location.hostname +
                    "/" +
                    this.$store.state.authentication.login.slug
                }, 300)
              })
            })
          }

          //Handle Whileloading
          if (
            this.$store.state.config[this.currentPage.id].load.authentication
              .states &&
            this.$store.state.config[this.currentPage.id].load.authentication
              .states.whileLoading
          )
            this.whileLoading(
              this.$store.state.config[this.currentPage.id].load.authentication
                .states.whileLoading,
              true
            )
          var myHeaders = new Headers()
          myHeaders.append("Content-Type", "application/json")

          var raw = JSON.stringify({
            authToken: userToken,
            projectId: document
              .querySelector("platform-wizards")
              .getAttribute("projectid")
          })

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          }

          return await fetch(baseURL + "validateAuthToken", requestOptions)
            .then(async response => {
              //Handle Whileloading
              if (
                this.$store.state.config[this.currentPage.id].load
                  .authentication.states &&
                this.$store.state.config[this.currentPage.id].load
                  .authentication.states.whileLoading
              )
                this.whileLoading(
                  this.$store.state.config[this.currentPage.id].load
                    .authentication.states.whileLoading,
                  false
                )
              if (response.status === 200) {
                response = await response.json()
                vm.$store.commit("updateUser", response)
              } else {
                check.setCookie("pw---u-token", "", 0)
                window.location.href =
                  "https://" +
                  window.location.hostname +
                  "/" +
                  this.$store.state.authentication.login.slug
              }
              return
            })
            .catch(error => {
              check.setCookie("pw---u-token", "", 0)
              window.location.href =
                "https://" +
                window.location.hostname +
                "/" +
                this.$store.state.authentication.login.slug
              throw new Error(error)
            })
        }
      } else {
        if (
          this.$store.state.config &&
          this.$store.state.config[this.currentPage.id] &&
          this.$store.state.config[this.currentPage.id].load &&
          this.$store.state.config[this.currentPage.id].load.authentication &&
          this.$store.state.config[this.currentPage.id].load.authentication
            .isActive
        ) {
          // Redirect to login if token not present but site needs authentication
          let loginUrl =
            "https://" +
            window.location.hostname +
            "/" +
            this.$store.state.authentication.login.slug
          if (window.location.href !== loginUrl) window.location.href = loginUrl
        }
      }
    },
    async loadRequests() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        let requests = this.$store.state.config[this.currentPage.id].load
          .requests
        requests = requests.filter(
          request => request.performOnPageLoad === true
        )
        requests.forEach(request => {
          //Handle Whileloading
          this.whileLoading(request.states.whileLoading, true)
        })

        let user = this.$store.state.user
        let navigationParameters = this.navigationParameters

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")

        var raw = JSON.stringify({
          user: user,
          navigationParameters: navigationParameters,
          requests: requests,
          projectId: document
            .querySelector("platform-wizards")
            .getAttribute("projectid"),
          pageId: this.currentPage.id
        })

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        }

        let url = baseURL + "loadOperation"
        return await fetch(url, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (!result.error && result && Array.isArray(result)) {
              result.forEach(result => {
                if (Array.isArray(result.values)) {
                  result.values.forEach(
                    item => (item.fields = this.flattenFields(item.fields))
                  )
                } else if (typeof result.values.fields === "object") {
                  result.values.fields = this.flattenFields(
                    result.values.fields
                  )
                }
              })
              this.$store.commit("updateRenderData", result)

              requests.forEach(request => {
                this.whileLoading(request.states.whileLoading, false)
                let response = result.find(
                  response => response.id === request.id
                )
                this.onError(request.states.onError, response, request.name)
              })
              return
            } else {
              throw new Error(result.error.message)
            }
          })
          .catch(error => {
            requests.forEach(request => {
              this.whileLoading(request.states.whileLoading, false)
              this.onError(
                request.states.onError,
                { status: "rejected", reason: error },
                request.name
              )
            })
            throw new Error(error)
          })
      }
    },
    async loadRequest(request) {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        //Handle Whileloading
        this.whileLoading(request.states.whileLoading, true)

        let user = this.$store.state.user
        let navigationParameters = this.navigationParameters

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/json")

        var raw = JSON.stringify({
          user: user,
          navigationParameters: navigationParameters,
          requests: [request],
          projectId: document
            .querySelector("platform-wizards")
            .getAttribute("projectid"),
          pageId: this.currentPage.id
        })

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        }

        let url = baseURL + "loadOperation"
        return await fetch(url, requestOptions)
          .then(response => response.json())
          .then(result => {
            result[0].values.fields = this.flattenFields[
              result[0].values.fields
            ]
            this.$store.commit("addToRenderData", result[0])
            this.whileLoading(request.states.whileLoading, false)
            let response = result[0]
            this.onError(request.states.onError, response, request.name)
            this.renderConnections()
            return
          })
          .catch(error => {
            this.whileLoading(request.states.whileLoading, false)
            this.onError(
              request.states.onError,
              { status: "rejected", reason: error },
              request.name
            )
            throw new Error(error)
          })
      }
    },
    flattenFields(fields) {
      try {
        for (const [arrayKey, value] of Object.entries(fields)) {
          if (Array.isArray(value) && value[0]) {
            value.forEach((item, index) => {
              if (typeof item === "object") {
                for (const [objectKey, value] of Object.entries(item)) {
                  fields[arrayKey + "[" + index + "]." + objectKey] = value
                }
              } else {
                fields[arrayKey + "[" + index + "]"] = item
              }
            })
          }
        }
        return fields
      } catch (e) {
        return fields
      }
    },
    async writeRequest(event, request) {
      event.stopPropagation()

      let user = this.$store.state.user
      let navigationParameters = this.navigationParameters

      let fields = []
      let fieldValueNotFound = false
      // Get value of fields (connections)
      for (let [fieldId, connection] of Object.entries(request.connections)) {
        let value
        switch (connection.type) {
          case "element": {
            // Get value of input element
            let element
            let listParent = this.$jq(event.target).closest("[pw---value]")
            if (listParent.length > 0) {
              element = listParent.find(connection.value.queryString).get(0)
            } else {
              element = document.querySelector(connection.value.queryString)
            }

            //Prevent default webflow form action
            let parentForm = this.$jq(element).closest("form")
            let vm = this
            // eslint-disable-next-line no-undef
            if (parentForm.length > 0 && Webflow) {
              // eslint-disable-next-line no-undef
              Webflow.push(function() {
                vm.$jq(parentForm).submit(function() {
                  return false
                })
              })
            }

            if (element.getAttribute("type") === "checkbox") {
              value = element.checked
              if (connection.inverted) {
                value = !value
              }
            } else {
              value = element.value
            }
            break
          }
          case "attribute": {
            if (connection.value.requestId === "auth") {
              // Get value from User Object
              value = user[connection.value.value.name]
            } else {
              // Get value from renderData
              let renderDataRequest = this.$store.state.renderData.find(
                request => request.id === connection.value.requestId
              )
              //console.log(renderDataRequest, connection.value)
              if (
                renderDataRequest === undefined &&
                connection.value.requestId === "params"
              ) {
                renderDataRequest = {
                  fields: this.navigationParameters
                }
              }
              if (Array.isArray(renderDataRequest.values)) {
                // Get the right value (bc element has been duplicated in 'List')
                let parent = event.target.closest("[pw---value]")
                let recordId = parent.getAttribute("pw---value")
                let record = renderDataRequest.values.find(
                  value => value.id === recordId
                )
                let valueName = connection.value.value.name
                value = record.fields[valueName]
                if (value === undefined) fieldValueNotFound = true
                if (connection.inverted) {
                  if (value === undefined) {
                    value = true
                  } else {
                    value = !value
                  }
                }
              } else {
                // Get the right value (single Element)
                if (renderDataRequest.fields) {
                  value = renderDataRequest.fields[connection.value.value.name]
                } else {
                  value =
                    renderDataRequest.values.fields[connection.value.value.name]
                }
              }
            }
            break
          }
        }
        if (
          connection.isRequired &&
          !value &&
          typeof value !== "boolean" &&
          !fieldValueNotFound
        ) {
          window.alert(
            connection.errorMsg
              ? connection.errorMsg
              : "Value '" +
                  connection.value.value.name +
                  "' required but not defined. Please fill out the corresponding input field."
          )
          return
        }
        fields.push({ fieldId: fieldId, value: value })
      }

      // Parse recordId
      let parsedRecordId = ""
      if (request.recordId) {
        let record
        let parent = event.target.closest("[pw---connection]")
        if (parent) {
          let connectionId = parent.getAttribute("pw---connection")
          //Attention!!! pw-connection can be multiple values if mutiple connections are on one element
          let connection = this.$store.state.config[
            this.currentPage.id
          ].load.connections.find(connection =>
            connectionId.includes(connection.id)
          )
          let renderDataRequest = this.$store.state.renderData.find(
            request => request.id === connection.requestId
          )
          if (Array.isArray(renderDataRequest.values)) {
            let listEl = event.target.closest("[pw---value]")
            let recordId = listEl.getAttribute("pw---value")
            record = renderDataRequest.values.find(
              value => value.id === recordId
            )
          } else {
            record = renderDataRequest.values
          }
          if (!record && !record.fields) {
            record = { fields: {} }
          }
          parsedRecordId = methods.parseParameterString(
            request.recordId,
            record.fields,
            this
          )
          if (!parsedRecordId) {
            window.alert(
              "Failed to get id of record. Please check your request configuration and make sure that the data you selected in the recordId field is available on this page."
            )
          }
        }
      }

      //------

      this.whileLoading(request.states.whileLoading, true)
      this.onSuccess(request.states.onSuccess, "", false)

      let myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      let raw = JSON.stringify({
        user: user,
        navigationParameters: navigationParameters,
        request: request,
        parsedRecordId: parsedRecordId,
        projectId: document
          .querySelector("platform-wizards")
          .getAttribute("projectid"),
        pageId: this.currentPage.id,
        fields: fields
      })

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      }
      return await fetch(baseURL + "writeOperation", requestOptions)
        .then(async response => {
          this.whileLoading(request.states.whileLoading, false)
          if (response.status === 200) {
            this.handleOnWriteSuccess(request)
          }
          response = await response.json()
          this.onError(request.states.onError, response, request.name)
        })
        .catch(error => {
          this.whileLoading(request.states.whileLoading, false)
          this.onError(
            request.states.onError,
            { status: "rejected", reason: error },
            request.name
          )
          throw new Error(error)
        })
    },
    whileLoading(whileLoading, isLoading) {
      if (Array.isArray(whileLoading.showElements)) {
        whileLoading.showElements.forEach(element => {
          let loadingElement = document.querySelector(element.queryString)
          if (isLoading) {
            loadingElement.style.display = this.getOriginalElementDisplayStyle(
              loadingElement
            )
          } else {
            this.saveOriginalElementDisplayStyle(loadingElement)
            loadingElement.style.display = "none"
          }
        })
      }
      if (Array.isArray(whileLoading.hideUntilLoaded)) {
        whileLoading.hideUntilLoaded.forEach(element => {
          let loadingElement = document.querySelector(element.queryString)
          if (loadingElement) {
            if (isLoading) {
              this.saveOriginalElementDisplayStyle(loadingElement)
              loadingElement.style.display = "none"
            } else {
              loadingElement.style.display = this.getOriginalElementDisplayStyle(
                loadingElement
              )
            }
          }
        })
      }
    },
    onError(onError, response, requestName) {
      if (Array.isArray(onError.showElements)) {
        switch (response.status) {
          default: {
            onError.showElements.forEach(element => {
              let loadingElement = document.querySelector(element.queryString)
              this.saveOriginalElementDisplayStyle(loadingElement)
              loadingElement.style.display = "none"
            })
            break
          }
          case "rejected": {
            onError.showElements.forEach(element => {
              let loadingElement = document.querySelector(element.queryString)

              loadingElement.style.display = this.getOriginalElementDisplayStyle(
                loadingElement
              )
            })
            break
          }
        }
      }
      if (Array.isArray(onError.insertErrorTo)) {
        switch (response.status) {
          default: {
            onError.insertErrorTo.forEach(element => {
              let errorMsg = document.querySelector(element.queryString)
              errorMsg.innerHTML = ""
              this.saveOriginalElementDisplayStyle(errorMsg)
              errorMsg.style.display = "none"
            })
            break
          }
          case "rejected": {
            onError.insertErrorTo.forEach(element => {
              let errorMsg = document.querySelector(element.queryString)
              if (response.reason) {
                errorMsg.innerHTML = response.reason
                errorMsg.style.display = this.getOriginalElementDisplayStyle(
                  errorMsg
                )
              } else {
                errorMsg.innerHTML =
                  "Unfortunately, an error occured while loading: " +
                  requestName

                errorMsg.style.display = this.getOriginalElementDisplayStyle(
                  errorMsg
                )
              }
            })
            break
          }
        }
      }
      if (
        Array.isArray(onError.showWhenResponseEmpty) &&
        response.values &&
        ((Array.isArray(response.values) && response.values.length === 0) ||
          (typeof response.values.fields === "object" &&
            Object.keys(response.values.fields).length === 0))
      ) {
        onError.showWhenResponseEmpty.forEach(element => {
          let emptyElement = document.querySelector(element.queryString)

          emptyElement.style.display = this.getOriginalElementDisplayStyle(
            emptyElement
          )
        })
      } else if (onError.showWhenResponseEmpty) {
        onError.showWhenResponseEmpty.forEach(element => {
          let emptyElement = document.querySelector(element.queryString)
          this.saveOriginalElementDisplayStyle(emptyElement)
          emptyElement.style.display = "none"
        })
      }
    },
    onSuccess(onSuccess, successMessage, isSuccessfull) {
      if (Array.isArray(onSuccess.showElements)) {
        onSuccess.showElements.forEach(element => {
          let successElement = document.querySelector(element.queryString)
          if (isSuccessfull) {
            successElement.style.display = this.getOriginalElementDisplayStyle(
              successElement
            )
          } else {
            this.saveOriginalElementDisplayStyle(successElement)
            successElement.style.display = "none"
          }
        })
      }
      if (Array.isArray(onSuccess.insertMessageTo)) {
        switch (isSuccessfull) {
          default: {
            onSuccess.insertMessageTo.forEach(element => {
              let msg = document.querySelector(element.queryString)
              msg.innerHTML = ""
              this.saveOriginalElementDisplayStyle(msg)
              msg.style.display = "none"
            })
            break
          }
          case true: {
            onSuccess.insertMessageTo.forEach(element => {
              let msg = document.querySelector(element.queryString)
              msg.innerHTML = successMessage
              msg.style.display = this.getOriginalElementDisplayStyle(msg)
            })
            break
          }
        }
      }
    },
    handleOnWriteSuccess(request) {
      this.onSuccess(request.states.onSuccess, "", false)
      request.states.onSuccess.actions.forEach(actionConfig => {
        let action = this.$store.state.config[this.currentPage.id].actions.find(
          action => action.id === actionConfig.id
        )
        if (!action) {
          window.alert(
            "Action: '" +
              actionConfig.title +
              "' not found. It might have beed deleted."
          )
          return
        }
        switch (action.requestType) {
          case "load":
            action.requests.forEach(requestConfig => {
              let request = this.$store.state.config[
                this.currentPage.id
              ].load.requests.find(request => request.id === requestConfig.id)
              if (!request) {
                window.alert(
                  "Error: Could not connect the trigger of action: '" +
                    action.title +
                    "' to load request: " +
                    requestConfig.name +
                    "'. The request has probably been deleted."
                )
                return
              }
              this.loadRequest(request)
            })
            break
          case "write":
            action.requests.forEach(requestConfig => {
              let request = this.$store.state.config[
                this.currentPage.id
              ].write.requests.find(request => request.id === requestConfig.id)
              if (!request) {
                window.alert(
                  "Error: Could not connect the trigger of action: '" +
                    action.name +
                    "' to write request: " +
                    requestConfig.name +
                    "'. The request has probably been deleted."
                )
                return
              }
              this.writeRequest({ target: undefined }, request)
            })
            break
        }
      })
    },
    getPageSlug(pageId) {
      let page = this.$store.state.pages.find(page => page.id == pageId)
      return page.slug
    },
    renderConnections() {
      this.restorePageToOriginalVersion()
      this.addConnectionIdToElements()
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let connections = this.$store.state.config[this.currentPage.id].load
          .connections
        if (connections.length > 0) {
          connections.forEach(connection => {
            this.applyRenderMethod(connection)
          })
        }
      }
    },
    addConnectionIdToElements() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let connections = this.$store.state.config[this.currentPage.id].load
          .connections
        if (connections.length > 0) {
          connections.forEach(connection => {
            let element = document.querySelector(connection.element.queryString)
            if (element) {
              let existingConnection = element.getAttribute("pw---connection")

              element.setAttribute(
                "pw---connection",
                existingConnection
                  ? connection.id + " " + existingConnection
                  : connection.id
              )
            } else {
              //console.log(connection)
              console.log(
                "Render Error. Element not found: " +
                  connection.element.queryString
              )
            }
          })
        }
      }
    },
    applyRenderMethod(connection) {
      let vm = this
      let requestData = this.$store.state.renderData.find(
        request => request.id === connection.requestId
      )
      if (connection.requestId === "auth") {
        requestData = {
          status:
            Object.keys(this.$store.state.user).length > 0 ? "fulfilled" : "",
          values: { fields: this.$store.state.user }
        }
      }
      if (requestData && requestData.status === "fulfilled") {
        if (connection.method.id === "renderList") {
          methods.renderList(connection, requestData, vm)
        } else {
          let elementQueryString = connection.element.queryString
          let values = requestData.values
          let element, logId
          if (Array.isArray(values)) {
            values.forEach(value => {
              element = methods.findListElement(connection, value)
              this.selectAndApplyRightMethod(value, connection, element, vm)
            })
          } else {
            element = document.querySelector(elementQueryString)
            logId = methods.addRenderLog(element, connection, vm)
            let revertIdExisting = element.getAttribute("pw---revert")
            element.setAttribute(
              "pw---revert",
              revertIdExisting ? revertIdExisting + " " + logId : logId
            )
            this.selectAndApplyRightMethod(values, connection, element, vm)
          }
        }
        //console.log(connection.method.id)
      } else {
        throw new Error(
          "Error during loading of connection data. Request id:" +
            connection.requestId
        )
      }
    },
    selectAndApplyRightMethod(value, connection, element, vm) {
      switch (connection.method.id) {
        case "replaceText":
          methods.replaceText(value, connection, element, vm)
          break
        case "showElement":
          methods.showElement(value, connection, element, vm)
          break
        case "hideElement":
          methods.hideElement(value, connection, element, vm)
          break
        case "addClass":
          methods.addClass(value, connection, element)
          break
        case "removeClass":
          methods.removeClass(value, connection, element)
          break
        case "replaceImage":
          methods.replaceImage(value, connection, element, vm)
          break
        case "replaceBG":
          methods.replaceBG(value, connection, element, vm)
          break
        case "addParameter":
          methods.addParameter(value, connection, element, vm)
          break
        case "addStyle":
          methods.addStyle(value, connection, element, vm)
          break
        case "applyStyle":
          methods.applyStyle(value, connection, element, vm)
          break
        case "setAttribute":
          methods.setAttribute(value, connection, element, vm)
          break
        case "setValue":
          methods.setValue(value, connection, element)
          break
      }
    },
    addWriteRequestTrigger() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        Array.isArray(
          this.$store.state.config[this.currentPage.id].write.requests
        ) &&
        this.$store.state.config[this.currentPage.id].write.requests.length > 0
      ) {
        let requests = this.$store.state.config[this.currentPage.id].write
          .requests
        requests.forEach(request => {
          request.trigger.forEach(trigger => {
            this.$jq(trigger.queryString).on("click", event => {
              this.writeRequest(event, request)
            })
          })
        })
      }
    },
    addActionTrigger() {
      let actions = this.$store.state.config[this.currentPage.id].actions
      actions.forEach(action => {
        action.trigger.forEach(trigger => {
          switch (action.requestType) {
            case "load":
              action.requests.forEach(requestConfig => {
                let request = this.$store.state.config[
                  this.currentPage.id
                ].load.requests.find(request => request.id === requestConfig.id)
                if (!request) {
                  window.alert(
                    "Error: Could not connect the trigger of action: '" +
                      action.name +
                      "' to load request: " +
                      requestConfig.name +
                      "'. The request has probably been deleted."
                  )
                  return
                }
                this.$jq(trigger.queryString).on("click", () => {
                  this.loadRequest(request)
                })
              })
              break
            case "write":
              action.requests.forEach(requestConfig => {
                let request = this.$store.state.config[
                  this.currentPage.id
                ].write.requests.find(
                  request => request.id === requestConfig.id
                )
                if (!request) {
                  window.alert(
                    "Error: Could not connect the trigger of action: '" +
                      action.name +
                      "' to write request: " +
                      requestConfig.name +
                      "'. The request has probably been deleted."
                  )
                  return
                }
                this.$jq(trigger.queryString).on("click", event => {
                  this.writeRequest(event, request)
                })
              })
              break
          }
        })
      })
    },
    restorePageToOriginalVersion() {
      this.$store.state.renderLog.forEach(log => {
        let modifiedElement = document.querySelector(
          '[pw---revert~="' + log.id + '"]'
        )
        if (modifiedElement) {
          modifiedElement.parentNode.replaceChild(log.element, modifiedElement)
        } else {
          console.log("Modified element not found for render log: " + log)
        }
      })
      document.querySelectorAll('[pw---revert="delete"]').forEach(element => {
        element.remove()
      })
      this.$store.commit("clearRenderLog")
    },
    renderLogin() {
      let vm = this
      if (
        this.$store.state.authentication &&
        this.$store.state.authentication.login &&
        this.currentPage.id === this.$store.state.authentication.login.id &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.login
      ) {
        let loginConfig = this.$store.state.config[this.currentPage.id].load
          .login

        //Prevent default webflow form action
        let parentForm = vm
          .$jq(loginConfig.emailField[0].queryString)
          .closest("form")
        // eslint-disable-next-line no-undef
        if (parentForm.length > 0 && Webflow) {
          // eslint-disable-next-line no-undef
          Webflow.push(function() {
            // Disable submitting form fields during development
            vm.$jq(parentForm).submit(function() {
              return false
            })
          })
        }

        loginConfig.loginLinkButton.forEach(buttonConfig => {
          vm.$jq(buttonConfig.queryString).on("click", () => {
            vm.loginOperation()
          })
        })
      }
    },
    loginOperation() {
      let loginConfig = this.$store.state.config[this.currentPage.id].load.login
      let email = document.querySelector(loginConfig.emailField[0].queryString)
        .value
      let emailValid = this.validateEmail(email)
      if (!emailValid) {
        window.alert("Please enter a valid email.")
        return
      }

      this.whileLoading(loginConfig.whileLoading, true)
      this.onSuccess(loginConfig.onSuccess, "", false)

      var myHeaders = new Headers()
      myHeaders.append("Content-Type", "application/json")

      var raw = JSON.stringify({
        email: email,
        projectId: document
          .querySelector("platform-wizards")
          .getAttribute("projectid")
      })

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      }

      fetch(baseURL + "sendUserLoginEmail", requestOptions)
        .then(async response => {
          let status = response.status
          response = await response.json()
          this.whileLoading(loginConfig.whileLoading, false)
          if (status === 200) {
            // On Success
            if (
              loginConfig.onSuccess.showElements.length === 0 &&
              loginConfig.onSuccess.insertMessageTo.length === 0
            ) {
              window.alert(response.message)
            } else {
              this.onSuccess(loginConfig.onSuccess, response.message, true)
            }
            this.onError(
              loginConfig.onError,
              { status: "fulfilled", reason: "" },
              "Login"
            )
          } else {
            // On Error
            if (
              loginConfig.onError.showElements.length === 0 &&
              loginConfig.onError.insertErrorTo.length === 0
            ) {
              window.alert(response.error.message)
            } else {
              this.onError(
                loginConfig.onError,
                { status: "rejected", reason: response.error.message },
                "Login"
              )
            }
          }
        })
        .catch(error => {
          this.whileLoading(loginConfig.whileLoading, false)
          this.onError(
            loginConfig.onError,
            { status: "rejected", reason: error },
            "Login"
          )
          throw new Error(error)
        })
    },
    validateEmail(email) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        return true
      }
      return false
    },
    hideAllStateElements() {
      // Get all requests
      let loadRequests = []
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        loadRequests = this.$store.state.config[this.currentPage.id].load
          .requests
      }
      let writeRequests = []
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        writeRequests = this.$store.state.config[this.currentPage.id].write
          .requests
      }
      let loginConfigStates = {}
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.login
      ) {
        let loginConfig = this.$store.state.config[this.currentPage.id].load
          .login
        loginConfigStates.onSuccess = loginConfig.onSuccess
        loginConfigStates.onError = loginConfig.onError
        loginConfigStates.whileLoading = loginConfig.whileLoading
      }

      // Loop threw all states and hide all Elements
      writeRequests.forEach(request => {
        this.hideAllExistingStates(request.states)
      })
      loadRequests.forEach(request => {
        this.hideAllExistingStates(request.states)
      })
      this.hideAllExistingStates(loginConfigStates)
    },
    hideAllExistingStates(states) {
      if (states.whileLoading) {
        if (states.whileLoading.showElements) {
          states.whileLoading.showElements.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            if (element) {
              this.saveOriginalElementDisplayStyle(element)
              element.style.display = "none"
            }
          })
        }
        // if (states.whileLoading.hideUntilLoaded) {
        //   states.whileLoading.hideUntilLoaded.forEach(elementConfig => {
        //     let element = document.querySelector(elementConfig.queryString)
        //     element.style.display = "none"
        //   })
        // }
      }
      if (states.onSuccess) {
        if (states.onSuccess.showElements) {
          states.onSuccess.showElements.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            if (element) {
              this.saveOriginalElementDisplayStyle(element)
              element.style.display = "none"
            }
          })
        }
        if (states.onSuccess.insertMessageTo) {
          states.onSuccess.insertMessageTo.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            if (element) {
              this.saveOriginalElementDisplayStyle(element)
              element.style.display = "none"
            }
          })
        }
      }
      if (states.onError) {
        if (states.onError.showElements) {
          states.onError.showElements.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            this.saveOriginalElementDisplayStyle(element)
            element.style.display = "none"
          })
        }
        if (states.onError.insertErrorTo) {
          states.onError.insertErrorTo.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            this.saveOriginalElementDisplayStyle(element)
            element.style.display = "none"
          })
        }
        if (states.onError.showWhenResponseEmpty) {
          states.onError.showWhenResponseEmpty.forEach(elementConfig => {
            let element = document.querySelector(elementConfig.queryString)
            this.saveOriginalElementDisplayStyle(element)
            element.style.display = "none"
          })
        }
      }
    },
    saveOriginalElementDisplayStyle(element) {
      if (element) {
        let displayStyle = window.getComputedStyle(element, null).display
        let className = element.className
        let existing = this.originalElementDisplayStyles.find(
          style => style.className === className
        )
        if (!existing && displayStyle && className) {
          this.originalElementDisplayStyles.push({
            className: className,
            displayStyle: displayStyle
          })
        }
      }
    },
    getOriginalElementDisplayStyle(element) {
      let className = element.className
      if (className) {
        let style = this.originalElementDisplayStyles.find(
          style => style.className === className
        )
        if (style && style.displayStyle) {
          return style.displayStyle === "none" ? "block" : style.displayStyle
        } else return "block"
      } else return "block"
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      if (page && Object.keys(page).length > 0) {
        return page
      } else return {}
    },
    navigationParameters() {
      let navigationParameters = {}
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].parameters &&
        this.$store.state.config[this.currentPage.id].parameters.length > 0
      )
        this.$store.state.config[this.currentPage.id].parameters.forEach(
          param => {
            let value = check.getURLParameter(param.name)
            if (value) {
              navigationParameters[param.name] = value
            } else {
              if (param.fallbackPageId) {
                alert(
                  "Required navigation parameter '" +
                    param.name +
                    "' is not present in url. You will be redirected to: " +
                    "https://" +
                    window.location.hostname +
                    "/" +
                    this.getPageSlug(param.fallbackPageId)
                )
                window.location.href =
                  "https://" +
                  window.location.hostname +
                  "/" +
                  this.getPageSlug(param.fallbackPageId)
              }
            }
          }
        )
      return navigationParameters
    },
    isPreview() {
      let preview = check.getCookie("pw---preview")
      if (preview) {
        return true
      } else {
        return false
      }
    }
  },
  async mounted() {
    document.querySelector("body").style.display = "block"
    await this.$store.dispatch("getConfig")
    document.querySelector("body").style.display = "block"
    if (this.currentPage.id) {
      this.hideAllStateElements()
      await this.validateEmailTokenIfPresent()
      await this.loginIfCookieExists()
      this.renderLogin()
      await this.loadRequests()
      await this.addWriteRequestTrigger()
      await this.renderConnections()
    } else {
      document.querySelector("body").style.display = "block"
    }
    document.querySelector("body").style.display = "block"
  }
}
</script>
