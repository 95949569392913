<template>
  <div>
    <div class="pw---embed" v-show="!visualSelectorOpen">
      <div class="pw---scroll-wrapper">
        <div class="pw---main">
          <TopBar />
          <div class="pw---main-content">
            <div class="pw---flex-column pw---left">
              <div class="pw---seperator pw---small"></div>
              <BackButton />
              <div class="pw---seperator pw---small"></div>
            </div>
            <div class="pw---flexrow pw---left-right">
              <div>
                <h5>Connections</h5>
                <p class="pw---50 pw---top-5">
                  {{
                    `${connectionsCounter} connection${
                      connectionsCounter > 1 ? "s" : ""
                    }`
                  }}
                  on this page
                </p>
              </div>
              <div
                class="pw---dropdown-icon-box"
                @click="allConnectionsOpen = !allConnectionsOpen"
              >
                <img
                  :style="{
                    transform: allConnectionsOpen
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)'
                  }"
                  src="https://widget.platformwizards.com/images/25-white.svg"
                  alt=""
                  class="pw---20px-icon pw---pointer"
                />
              </div>
            </div>
            <div v-if="allConnectionsOpen">
              <div
                class="pw---connections-block pw---top-15"
                v-for="element of getConnectionElements"
                :key="element.queryString"
              >
                <div
                  class="pw---element-row-all"
                  v-for="(parent, index) of element.parents"
                  :key="parent.queryString"
                  @mouseover="mouseOver(parent.queryString)"
                  @mouseleave="mouseOut(parent.queryString)"
                >
                  <div class="pw---element-wrapper no-background">
                    <div class="pw---depth" v-for="time of index" :key="time">
                      <div class="pw---hor-depth"></div>
                    </div>
                    <img
                      src="https://widget.platformwizards.com/images/25-white.svg"
                      alt=""
                      class="pw---15px-icon pw---right-5"
                    />
                    <p class="pw---25">{{ parent.tagName }}</p>
                    <p class="pw-classname">
                      {{
                        parent.id
                          ? "#" + parent.id
                          : parent.classListString
                          ? "." +
                            parent.classListString.substring(0, 25) +
                            (parent.classListString.length > 25 ? "..." : "")
                          : ""
                      }}
                    </p>
                    <div
                      style="margin-left: 5px; flex"
                      v-for="connection of parent.connections"
                      :key="connection.id"
                    >
                      <Attribute
                        style="transform: scale(0.8)"
                        :title="connection.value.name"
                        @click="$emit('open-connection', connection)"
                        :color="attributeColor(connection.value)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="pw---connection-top-wrapper"
                  @mouseover="mouseOver(element.queryString)"
                  @mouseleave="mouseOut(element.queryString)"
                >
                  <div
                    class="pw---element-wrapper pw---inside-block"
                    @click="selectedElement(element)"
                  >
                    <p class="pw---25">{{ element.tagName }}</p>
                    <p class="pw-classname">
                      {{
                        element.id
                          ? "#" + element.id
                          : element.classListString
                          ? "." +
                            element.classListString.substring(0, 25) +
                            (element.classListString.length > 25 ? "..." : "")
                          : ""
                      }}
                    </p>
                  </div>
                  <div
                    class="pw---connection-wrapper"
                    v-for="connection of element.connections"
                    :key="connection.id"
                    @click="selectConnection(connection)"
                  >
                    <p class="pw---bottom-5">
                      <span
                        class="pw---parameter"
                        :style="{
                          'background-color': attributeColor(connection.value)
                        }"
                        >{{ connection.value.name }}</span
                      >
                      <span class="pw---50"> of</span>
                      {{ getRequestName(connection) }}
                    </p>
                    <p>
                      <span class="pw---50">Method</span>
                      {{ connection.method.title }}
                    </p>
                    <div
                      style="background-color: #ff715d; margin-top: 5px; padding: 5px; border-radius: 5px"
                      v-if="connectionHasNoValidRequest(connection)"
                    >
                      <p>
                        The selected connection value is invalid. Its request
                        might have been deleted. Please edit connection and
                        re-select value. Or delete connection and try again.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pw---seperator pw---small"></div>
            <div class="pw---flexrow pw---left pw---bottom-15">
              <h5>Add Connection</h5>
            </div>
            <div
              class="pw---button pw---medium pw---outline-white"
              @click="visualSelectorOpen = true"
            >
              <p>Visual Element Selector</p>
            </div>
            <div class="pw---seperator pw---small"></div>
            <PageTree
              @selected="selectedElement($event)"
              :showConnectionErrors="true"
              @open-connection="selectConnection($event)"
              :fullHeight="true"
              @delete-connection="deleteConnection($event)"
            />
          </div>
        </div>
      </div>
      <div
        class="pw---scroll-wrapper"
        v-if="Object.keys(connection.element).length !== 0"
      >
        <div class="pw---pw-second">
          <div v-show="!loading">
            <div class="pw---flex-row">
              <div
                class="pw---flex-row pw---left"
                style="flex-wrap: wrap; margin-top: -4px; margin-bottom: -4px"
              >
                <div
                  class="pw---flex-row"
                  style="margin-right: 5px; margin-bottom: 7px; margin-top: 7px;"
                >
                  <h5>Connect</h5>
                </div>
                <Attribute
                  :title="
                    connection.element.id
                      ? '#' + connection.element.id
                      : connection.element.classListString.length > 15
                      ? '.' +
                        connection.element.classListString.substring(0, 15) +
                        '...'
                      : '.' + connection.element.classListString
                  "
                  :subtitle="connection.element.tagName"
                  color="rgba(255, 255, 255,
              0.25)"
                />
                <h5 class="pw---left-5" style="margin-right: 5px">to:</h5>
                <Attribute
                  v-if="Object.keys(connection.value).length !== 0"
                  :title="connection.value.name"
                  :subtitle="connection.value.type"
                  color="#3371f2"
                  :hasUnselect="true"
                  @unselect="unselectValue()"
                />
              </div>
              <div
                class="pw---button pw---small pw---light-white pw---icon"
                @click="deleteConnection"
                style="flex-shrink: 0"
                v-if="isExistingConnection"
              >
                <img
                  src="https://widget.platformwizards.com/images/white_trash.svg"
                  width="20"
                  alt=""
                  class="_20px-icon"
                />
              </div>
            </div>
            <div class="pw---top-15">
              <div class="pw---seperator pw---small"></div>
            </div>
            <div v-if="Object.keys(connection.value).length === 0">
              <LoadRequestAttributeSelector
                v-for="request of allRequests"
                :key="request.id"
                @value-selected="selectedValue($event)"
                :request="request"
                :connection="connection"
                :childHasListConnectionCheck="true"
              />
            </div>
            <div v-if="Object.keys(connection.value).length !== 0">
              <InputSelect
                title="Method"
                placeholder="Select method"
                :selectOptions="filteredMethodList"
                :initialValue="connection.method"
                @change="
                  event => {
                    connection.method = event
                    connection.method.fieldsInput = {}
                  }
                "
                messageOnEmpty="Unfortunately, there are no methods available yet for this element / value combination. Please contact us under support@platformwizards.om if have ideas for additional methods."
              />
              <div v-if="Object.keys(connection.method).length !== 0">
                <div v-for="field of connection.method.fields" :key="field.id">
                  <InputText
                    v-if="field.type === 'InputText'"
                    :title="field.title"
                    :subtitle="'* ' + (field.subtitle ? field.subtitle : '')"
                    :initialValue="
                      connection.method.fieldsInput[field.id]
                        ? connection.method.fieldsInput[field.id]
                        : ''
                    "
                    @change="connection.method.fieldsInput[field.id] = $event"
                    inputType="text"
                  />
                  <InputToggle
                    v-if="field.type === 'InputToggle'"
                    :title="field.title"
                    :subtitle="'* ' + (field.subtitle ? field.subtitle : '')"
                    :initialValue="connection.method.fieldsInput[field.id]"
                    @change="connection.method.fieldsInput[field.id] = $event"
                    :messageOnTrue="field.messageOnTrue"
                    :messageOnFalse="field.messageOnFalse"
                  />
                  <InputValue
                    v-if="field.type === 'InputValue'"
                    :title="field.title"
                    :subtitle="'* ' + (field.subtitle ? field.subtitle : '')"
                    :initialValue="
                      connection.method.fieldsInput[field.id]
                        ? connection.method.fieldsInput[field.id]
                        : ''
                    "
                    @change="connection.method.fieldsInput[field.id] = $event"
                    :selectOptions="[
                      {
                        title: 'Selected Value',
                        id: 'field',
                        properties: [connection.value.name]
                      }
                    ]"
                    :id="field.id"
                  />
                </div>
              </div>
              <div v-if="connection.method.description">
                <div class="pw---seperator pw---small"></div>
                <p class="pw---50">Summary:<a href="#"></a></p>
                <p class="pw---top-10">
                  Attribute
                  <span class="pw---attribute-inline pw---grey">{{
                    connection.value.name
                  }}</span>
                  from request &quot;{{ selectedRequestName }}&quot;
                  {{ descriptionText }}
                  <span class="pw---attribute-inline pw---grey">{{
                    connection.element.id || connection.element.classListString
                      ? connection.element.id
                        ? "#" + connection.element.id
                        : connection.element.classListString.length > 15
                        ? "." +
                          connection.element.classListString.substring(0, 15) +
                          "..."
                        : "." + connection.element.classListString
                      : connection.element.tagName
                  }}</span
                  >.
                </p>
              </div>
            </div>
            <Save
              @cancel="closeConnector"
              @save="saveConnection"
              :saveIsActive="saveIsActive"
            />
          </div>
          <LoadingAnimation v-if="loading" />
        </div>
      </div>
      <div
        class="pw---element-selector-wrapper"
        id="pw---element-selector-wrapper-co"
        style="display: none; z-index: -1"
      >
        <div
          class="pw---selector-label"
          style="width: max-content;"
          v-if="Object.keys(hovered).length !== 0"
        >
          <p
            v-if="hovered.classListString.length > 0"
            style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
          >
            {{
              hovered.classListString.length > 0
                ? "." +
                  hovered.classListString.substring(0, 25) +
                  (hovered.classListString.length > 25 ? "..." : "")
                : ""
            }}
          </p>
          <p
            v-if="hovered.id"
            style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
          >
            {{ "#" + hovered.id }}
          </p>
          <p
            class="pw---5-left pw---50"
            style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
          >
            {{
              !hovered.id && !hovered.classListString
                ? hovered.queryString
                : hovered.tagName
            }}
          </p>
        </div>
      </div>
    </div>
    <VisualSelector
      v-if="visualSelectorOpen"
      @selected="selectedElement($event)"
      @canceled="visualSelectorOpen = false"
    />
  </div>
</template>

<script>
import TopBar from "./../components/nav/TopBar"
import BackButton from "./../components/nav/BackButton"
import PageTree from "./../components/configViews/PageTree"
import VisualSelector from "./VisualSelector"
import InputSelect from "./../components/ui/InputSelect"
import InputToggle from "./../components/ui/InputToggle"
import LoadingAnimation from "./../components/misc/LoadingAnimation"
import Attribute from "./../components/ui/Attribute"
import Save from "./../components/ui/Save"
import InputText from "./../components/ui/InputText"
import InputValue from "./../components/ui/InputValue"
import LoadRequestAttributeSelector from "./../components/configViews/LoadRequestAttributeSelector"

export default {
  name: "LoadDataConnect",
  components: {
    TopBar,
    PageTree,
    VisualSelector,
    BackButton,
    InputSelect,
    InputText,
    InputValue,
    InputToggle,
    LoadingAnimation,
    Attribute,
    Save,
    LoadRequestAttributeSelector
  },
  props: {
    elementSelection: Object,
    initialValue: Object
  },
  data() {
    return {
      connection: {
        id: "",
        element: Object,
        requestId: String,
        value: {},
        method: {}
      },
      visualSelectorOpen: false,
      selectedRequestName: "",
      loading: false,
      isExistingConnection: false,
      saveIsActive: false,
      descriptionText: "",
      methodList: [
        {
          title: "Replace text",
          id: "replaceText",
          fields: [{ title: "Text", type: "InputValue", id: "text" }],
          fieldsInput: {},
          requiredType: "",
          description: "will replace the text of element"
        },
        {
          title: "Hide element",
          id: "hideElement",
          fields: [
            {
              title: "Invert value",
              type: "InputToggle",
              id: "invert",
              messageOnTrue: "Yes (example: TRUE -> FALSE)",
              messageOnFalse: "No (example: TRUE -> TRUE)"
            }
          ],
          fieldsInput: {},
          requiredType: "boolean",
          description: "(if: TRUE), will hide the element"
        },
        {
          title: "Show element",
          id: "showElement",
          fields: [
            {
              title: "Invert value",
              type: "InputToggle",
              id: "invert",
              messageOnTrue: "Yes (example: TRUE -> FALSE)",
              messageOnFalse: "No (example: TRUE -> TRUE)"
            }
          ],
          fieldsInput: {},
          requiredType: "boolean",
          description: "(if: TRUE), will show the element"
        },
        {
          title: "Add class",
          id: "addClass",
          fields: [
            { title: "Class Name", type: "InputText", id: "className" },
            {
              title: "Invert value",
              type: "InputToggle",
              id: "invert",
              messageOnTrue: "Yes (example: TRUE -> FALSE)",
              messageOnFalse: "No (example: TRUE -> TRUE)"
            }
          ],
          fieldsInput: {},
          requiredType: "boolean",
          description:
            "(if: TRUE), will add class with 'Class Name' to the element"
        },
        {
          title: "Remove class",
          id: "removeClass",
          fields: [
            { title: "Class Name", type: "InputText", id: "className" },
            {
              title: "Invert value",
              type: "InputToggle",
              id: "invert",
              messageOnTrue: "Yes (example: TRUE -> FALSE)",
              messageOnFalse: "No (example: TRUE -> TRUE)"
            }
          ],
          fieldsInput: {},
          requiredType: "boolean",
          description:
            "(if: TRUE), will remove class with 'Class Name' of the element"
        },
        {
          title: "Replace image src",
          id: "replaceImage",
          fields: [{ title: "'src:' Value", type: "InputValue", id: "src" }],
          fieldsInput: {},
          requiredType: "img",
          description: "will replace the 'src' tag of the img element"
        },
        {
          title: "Replace background image style",
          id: "replaceBG",
          fields: [
            {
              title: "'background-image:' Value",
              type: "InputValue",
              id: "backgroundImg"
            }
          ],
          fieldsInput: {},
          requiredType: "imageURL",
          description:
            "will add inline 'background-image' CSS style to the element"
        },
        {
          title: "Add parameter to link",
          id: "addParameter",
          fields: [
            {
              title: "Parameter name",
              type: "InputText",
              id: "parameterName"
            },
            {
              title: "Parameter value",
              type: "InputValue",
              id: "parameterValue"
            }
          ],
          fieldsInput: {},
          requiredType: "button",
          description: "will append parameter to link ('href') of element"
        },
        {
          title: "Add style",
          id: "addStyle",
          fields: [
            {
              title: "CSS Styles",
              subtitle: "use ';' to seperate multiple",
              type: "InputValue",
              id: "inlineCSS"
            },
            {
              title: "Invert value",
              type: "InputToggle",
              id: "invert",
              messageOnTrue: "Yes (example: TRUE -> FALSE)",
              messageOnFalse: "No (example: TRUE -> TRUE)"
            }
          ],
          fieldsInput: {},
          requiredType: "boolean",
          description: "(if: TRUE), will add 'CSS Inline Styles' to the element"
        },
        {
          title: "Apply style",
          id: "applyStyle",
          fields: [
            {
              title: "CSS Styles",
              subtitle: "use ';' to seperate multiple",
              type: "InputValue",
              id: "inlineCSS"
            }
          ],
          fieldsInput: {},
          requiredType: "",
          description: "will add 'CSS Inline Styles' to the element"
        },
        {
          title: "Render list",
          id: "renderList",
          fields: [],
          fieldsInput: {},
          requiredType: "list",
          description: "For every 'List' item it will duplicate element"
        },
        {
          title: "Set HTML attribute",
          id: "setAttribute",
          fields: [
            {
              title: "Attribute name",
              subtitle: "",
              type: "InputText",
              id: "attributeName"
            },
            {
              title: "Attribute value",
              subtitle: "",
              type: "InputValue",
              id: "attributeValue"
            }
          ],
          fieldsInput: {},
          requiredType: "",
          description: "will add 'HTML Attribute' to the element"
        },
        {
          title: "Set input value",
          id: "setValue",
          fields: [],
          fieldsInput: {},
          requiredType: "input",
          description: "will be used as value for the input element"
        }
      ],
      foundConnections: [],
      connectionsWithInvalidRequests: [],
      connectionsCounter: 0,
      allConnectionsOpen: true,
      hovered: {}
    }
  },
  methods: {
    getAttributesOfTable(tableId, values) {
      let fields = this.$store.state.database.find(
        table => table.id === tableId
      ).fields
      if (values.length > 0) {
        fields = fields.filter(field => values.includes(field.name))
      }
      return fields
    },
    selectedElement(e) {
      if (Object.keys(this.connection.element).length === 0) {
        this.connection.element = e
        this.visualSelectorOpen = false
        let id = this.generateConnectionId()
        this.connection.id = id
        this.isExistingConnection = false
      }
    },
    selectedValue(event) {
      this.connection.value = event.value
      this.connection.requestId = event.requestId
      this.selectedRequestName = event.selectedRequestName
    },
    selectConnection(connection) {
      let resultConnection = {}
      this.connection = Object.assign(resultConnection, connection)
      this.isExistingConnection = true
    },
    closeConnector() {
      this.connection.element = Object
      this.connection.value = {}
      this.connection.method = {}
      this.connection.id = ""
      this.$store.dispatch("getAdminConfig", this.$store.state.adminUser.jwt)
    },
    async saveConnection() {
      this.loading = true
      this.$store.commit("saveConnection", {
        connection: this.connection,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
      } catch (e) {
        throw new Error(e)
      }
      this.$store.dispatch("getAdminConfig", this.$store.state.adminUser.jwt)
      this.loading = false
      this.closeConnector()
    },
    async deleteConnection(id) {
      if (window.confirm("Are you sure you want to delete this connection?")) {
        this.loading = true
        this.$store.commit("deleteConnection", {
          connectionId: typeof id === "string" ? id : this.connection.id,
          pageId: this.currentPage.id
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        this.loading = false
        this.closeConnector()
      }
    },
    generateConnectionId() {
      let id = this.testIfIdAlredyTaken(id)
      return id
    },
    idGenerator(length) {
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    testIfIdAlredyTaken() {
      let id = this.idGenerator(15)
      if (
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let index = this.$store.state.config[
          this.currentPage.id
        ].load.connections.findIndex(connection => connection.id == id)
        if (index === -1) {
          return id
        } else {
          return this.testIfIdAlredyTaken(id)
        }
      } else {
        return id
      }
    },
    unselectValue() {
      this.connection.value = {}
      this.connection.requestId = String
    },
    formatElement(element) {
      return {
        tagName: element.tagName.toLowerCase(),
        id: element.id,
        classList: element.className.toString().split(" "),
        classListString: element.className.toString(),
        parents: this.getParentsOfElement(element),
        queryString: this.getQueryString(element),
        children: this.getChildNodes(element),
        element: {},
        connections: this.getConnetionsOfElement(this.getQueryString(element)),
        matchesQuery: this.matchesQuery(element)
      }
    },
    getParentsOfElement(element) {
      let result = []
      let counter = 0
      while (
        element &&
        element.nodeName !== "#document" &&
        element.nodeName !== "HTML" &&
        counter < 4
      ) {
        result.unshift({
          queryString: this.getQueryString(element),
          tagName: element.tagName.toLowerCase(),
          id: element.id,
          classList: element.className.toString().split(" "),
          classListString: element.className.toString(),
          connections: this.getConnetionsOfElement(this.getQueryString(element))
        })
        element = element.parentNode
        counter++
      }
      result.pop()
      return result
    },
    getQueryString(element) {
      let result = ""

      function loop(element) {
        if (element.nodeType === Node.ELEMENT_NODE) {
          // stop here = element has ID
          if (element.getAttribute("id")) {
            result = result.replace(/^/, " #" + element.getAttribute("id"))
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // stop here = element is body
          if (document.body === element) {
            result = result.replace(/^/, " body")
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // concat all classes in "queryselector" style
          if (element.getAttribute("class")) {
            let elemClasses = "."
            elemClasses += element.getAttribute("class")
            elemClasses = elemClasses.replace(/\s/g, ".")
            elemClasses = elemClasses.replace(/^/g, " ")
            let classNth = ""

            // check if element class is the unique child
            let childrens = element.parentNode.children

            let similarElements = []
            for (let i = 0; i < childrens.length; i++) {
              if (
                element.getAttribute("class") ==
                  childrens[i].getAttribute("class") ||
                element.tagName == childrens[i].tagName
              ) {
                similarElements.push(childrens[i])
              }
            }
            if (similarElements.length > 1) {
              for (let j = 0; j < similarElements.length; j++) {
                if (element === similarElements[j]) {
                  j++
                  classNth = ":nth-of-type(" + j + ")"
                  break
                }
              }
            }

            result = result.replace(/^/, elemClasses + classNth)
          } else {
            // get nodeType
            let name = element.nodeName
            name = name.toLowerCase()
            let nodeNth = ""

            let childrens = element.parentNode.children

            if (childrens.length > 1) {
              let similarNodes = []

              for (let i = 0; i < childrens.length; i++) {
                if (element.nodeName == childrens[i].nodeName) {
                  similarNodes.push(childrens[i])
                }
              }

              if (similarNodes.length > 1) {
                for (let j = 0; j < similarNodes.length; j++) {
                  if (element === similarNodes[j]) {
                    j++
                    nodeNth = ":nth-of-type(" + j + ")"
                    break
                  }
                }
              }
            }

            result = result.replace(/^/, " " + name + nodeNth)
          }

          if (element.parentNode) {
            loop(element.parentNode)
          } else {
            result = result.replace(/\s/g, " > ")
            result = result.replace(/\s/, "")
            return result
          }
        } else {
          result = ""
        }
      }

      loop(element)

      return result
    },
    getChildNodes(element) {
      let result = []
      let self = this
      for (let i = 0; i < element.childNodes.length; i++) {
        let childNode = element.childNodes[i]
        let queryString = self.getQueryString(childNode)
        result.push(queryString)
      }
      return result
    },
    getConnetionsOfElement(elementQueryString) {
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let connections = this.$store.state.config[
          this.currentPage.id
        ].load.connections.filter(
          connection => connection.element.queryString === elementQueryString
        )
        connections.forEach(connection => {
          this.foundConnections.push(connection)
        })
        return connections
      } else {
        //console.log("no connections found for " + elementQueryString)
        return []
      }
    },
    matchesQuery(element) {
      let result
      if (this.query) {
        let elementQueryString = this.getQueryString(element)
        let elementsMachingQuery = document.querySelectorAll(this.query)
        let queryStringsForElementsMatchingQuery = []
        elementsMachingQuery.forEach(element => {
          let querySelector = this.getQueryString(element)
          queryStringsForElementsMatchingQuery.push(querySelector)
        })
        queryStringsForElementsMatchingQuery.forEach(queryString => {
          if (queryString === elementQueryString) {
            result = true
          }
        })
      }
      return result
    },
    attributeColor(value) {
      if (value.type === "list") {
        return "rgb(51, 173, 242)"
      } else return ""
    },
    mouseOver(queryString) {
      let e = document.querySelector(queryString)
      let rect = e.getBoundingClientRect()
      // let height = rect.bottom - rect.top
      // let width = rect.right - rect.left
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper-co"
      )
      let formattedElement = this.formatElement(e)
      this.hovered = {
        tagName: formattedElement.tagName,
        id: formattedElement.id,
        classListString: formattedElement.classListString
      }
      e.style["border-width"] = "2px"
      e.style["border-color"] = "#3371f2"
      e.style["border-style"] = "solid"
      /*
        selectorWrapper.style.width = width + "px"
        selectorWrapper.style.height = height + "px"
        */
      selectorWrapper.style.top = rect.top + "px"
      selectorWrapper.style.left = rect.left + "px"
      selectorWrapper.style.display = "block"
    },
    mouseOut(queryString) {
      let e = document.querySelector(queryString)
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper-co"
      )
      this.hovered = {}
      selectorWrapper.style.display = "block"
      e.style["border-width"] = null
      e.style["border-color"] = null
      e.style["border-style"] = null
      e.onclick = null
    },
    getRequestName(connection) {
      let request = {}
      if (
        connection.requestId !== "auth" &&
        connection.requestId !== "params" &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        request = this.$store.state.config[
          this.currentPage.id
        ].load.requests.find(request => request.id === connection.requestId)
        if ((!request || !request.name) && connection && connection.requestId) {
          //this.connectionsWithInvalidRequests.push(connection.id)
          return "❌ Request does not exist any more. Please select another value!"
        }
      } else if (connection.requestId !== "auth") {
        request = {}
        request.name = "Authentication data"
      } else if (connection.requestId !== "params") {
        request = {}
        request.name = "Navigation Parameters"
      }
      if (request && request.name) {
        return request.name
      } else return ""
    },
    openConnection(connection) {
      this.connection = connection
      this.isExistingConnection = true
    },
    connectionHasNoValidRequest(connection) {
      if (this.connectionsWithInvalidRequests.length > 0) {
        let foundConnectionId = this.connectionsWithInvalidRequests.find(
          connectionId => connectionId === connection.id
        )
        if (foundConnectionId) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  computed: {
    getConnectionElements() {
      let self = this
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      self.connectionsCounter = 0
      self.connectionsWithInvalidRequests = []
      function traverse(node, elems) {
        if (
          node &&
          node.tagName &&
          node.tagName !== "PLATFORM-WIZARDS" &&
          node.id !== "intercom-frame" &&
          node.classList[0] !== "intercom-lightweight-app" &&
          node.classList[0] !== "w-webflow-badge"
        ) {
          let element = self.formatElement(node)
          if (element.connections.length > 0) {
            elems.push(element)
            self.connectionsCounter =
              self.connectionsCounter + element.connections.length
          }
          let childs = node.childNodes
          for (let i = 0; i < childs.length; i++) {
            traverse(childs[i], elems)
          }
        }
      }
      let domElements = []
      traverse(document.getElementsByTagName("body")[0], domElements)
      return domElements
    },
    filteredMethodList() {
      let result = []
      if (
        this.connection.element.tagName === "button" ||
        this.connection.element.tagName === "a"
      ) {
        result = result.concat(
          this.methodList.filter(method => method.requiredType === "button")
        )
      }
      if (this.connection.value.type === "checkbox") {
        result = result.concat(
          this.methodList.filter(method => method.requiredType === "boolean")
        )
      }
      if (
        this.connection.value.type === "multipleAttachments" &&
        this.connection.element.tagName === "img" &&
        this.connection.value.name.includes(".url")
      ) {
        result = result.concat(
          this.methodList.filter(method => method.requiredType === "img")
        )
      }
      if (
        this.connection.value.type === "multipleAttachments" &&
        this.connection.value.name.includes(".url")
      ) {
        result = result.concat(
          this.methodList.filter(method => method.requiredType === "imageURL")
        )
      }
      if (this.connection.value.type === "list") {
        return this.methodList.filter(method => method.requiredType === "list")
      }
      if (this.connection.element.tagName === "input") {
        result = result.concat(
          this.methodList.filter(method => method.requiredType === "input")
        )
      }
      result = result.concat(
        this.methodList.filter(method => method.requiredType === "")
      )
      return result
    },
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    allRequests() {
      let requests = []
      if (this.$store.state.config[this.currentPage.id].load.requests) {
        this.$store.state.config[this.currentPage.id].load.requests.forEach(
          request => {
            let values = this.getAttributesOfTable(
              request.tableId,
              request.values
            )
            requests.push({
              title: request.name,
              id: request.id,
              tableId: request.tableId,
              values: values,
              type: request.requestType
            })
          }
        )
      }
      if (this.$store.state.authentication.enabled) {
        let authValues = this.$store.state.database.find(
          table => table.id === this.$store.state.authentication.table.id
        ).fields
        requests.push({
          title: "User Authentication",
          tableId: this.$store.state.authentication.table.id,
          values: authValues,
          type: "item",
          id: "auth"
        })
      }
      if (this.$store.state.config[this.currentPage.id].parameters) {
        let parameterValues = this.$store.state.config[this.currentPage.id]
          .parameters
        requests.push({
          title: "Navigation parameters",
          tableId: this.$store.state.authentication.table.id,
          values: parameterValues,
          type: "item",
          id: "params"
        })
      }
      return requests
    },
    methodText() {
      return ""
    }
  },
  watch: {
    connection: {
      deep: true,
      handler() {
        if (
          Object.keys(this.connection.element).length !== 0 &&
          this.connection.requestId &&
          Object.keys(this.connection.method).length > 0
        ) {
          this.saveIsActive = true
        } else {
          this.saveIsActive = false
        }

        if (
          this.connection.method &&
          this.connection.method.fieldsInput &&
          this.connection.method.fieldsInput.invert
        ) {
          this.descriptionText = this.connection.method.description.replace(
            "TRUE",
            "FALSE"
          )
        } else {
          this.descriptionText = this.connection.method.description
        }
      }
    }
  }
}
</script>
