<template>
  <div>
    <div class="pw---embed" v-show="!visualSelectorOpen">
      <div class="pw---scroll-wrapper">
        <div class="pw---main">
          <TopBar />
          <div class="pw---main-content">
            <div class="pw---flex-column pw---left">
              <div class="pw---seperator pw---small"></div>
              <BackButton />
              <div class="pw---seperator pw---small"></div>
            </div>
            <div class="pw---flexrow pw---left">
              <div class="pw---label pw---light-blue-light">
                <p class="pw---light-blue-text">{{ numberOfActions }}</p>
              </div>
              <p class="pw---left-10">Actions</p>
            </div>
            <p class="pw---50 pw---top-5">
              Trigger load / write operations based on user interaction.
            </p>
            <div class="pw---operations">
              <div
                class="pw---operation"
                v-for="action in actions"
                :key="action.id"
                @click="selectAction(action)"
              >
                <div class="pw---flexrow pw---100" style="margin-bottom: 10px">
                  <p>{{ action.name }}</p>
                </div>
              </div>
              <div class="pw---flexrow pw---centered pw---top-15">
                <div
                  class="pw---button pw---small pw---round pw---light"
                  @click="createNewAction"
                >
                  <img
                    src="https://widget.platformwizards.com/images/white.svg"
                    alt=""
                    class="pw---20px-icon pw---right-10"
                  />
                  <p>Add Action</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pw---scroll-wrapper" v-if="config.id">
        <div class="pw---pw-second">
          <div v-if="!loading">
            <div class="pw---flex-row pw---heading">
              <div class="pw---flex-row">
                <h5 v-if="config.name">{{ config.name }}</h5>
                <h5 v-if="!config.name">Configure action</h5>
              </div>
              <div
                class="pw---button pw---small pw---light-white pw---icon"
                @click="deleteAction"
                v-if="!newAction"
              >
                <img
                  src="https://widget.platformwizards.com/images/white_trash.svg"
                  width="20"
                  alt=""
                  class="_20px-icon"
                />
              </div>
            </div>
            <div class="pw---top-15">
              <div class="pw---seperator pw---small"></div>
            </div>
            <InputText
              title="Action Name"
              subtitle="*"
              inputType="text"
              :initialValue="config.name"
              @change="config.name = $event"
            />
            <FieldElement
              title="Select Trigger"
              subtitle="Request is executed when element is clicked"
              :initialValue="config.trigger"
              @open-selector="elementSelectorOpen = true"
              @unselect="unselectTrigger($event)"
            />
            <InputSelect
              title="Action Type"
              subtitle="*"
              :selectOptions="actionTypes"
              placeholder="Select action type"
              @change="changeRequestType($event)"
              :initialValue="selectedActionType"
              :hideRemove="true"
            />
            <InputSelect
              title="Action"
              subtitle="*"
              :selectOptions="availableActions"
              placeholder="Select action"
              @change="config.requests = $event"
              :initialValue="config.requests"
              :hasBoxAroundOptions="true"
              :isMultipleSelect="true"
            />
            <Save
              @save="saveAction"
              @cancel="closeConfig"
              :saveIsActive="saveIsActive"
            />
          </div>
          <LoadingAnimation v-if="loading" />
        </div>
      </div>
      <SelectElement
        v-if="elementSelectorOpen"
        @selected="selectedTrigger($event)"
        @close-element-selector="closeElementSelector"
        @open-visual-selector="visualSelectorOpen = true"
        v-on="$listeners"
      />
    </div>
    <VisualSelector
      v-if="visualSelectorOpen"
      @selected="selectedTrigger($event)"
      @canceled="visualSelectorOpen = false"
    />
  </div>
</template>

<script>
import TopBar from "./../components/nav/TopBar"
import BackButton from "./../components/nav/BackButton"
import Save from "./../components/ui/Save"
import FieldElement from "./../components/ui/FieldElement"
import InputText from "./../components/ui/InputText"
import InputSelect from "./../components/ui/InputSelect"
import SelectElement from "./../components/configViews/SelectElement"
import LoadingAnimation from "./../components/misc/LoadingAnimation"
import VisualSelector from "./VisualSelector"

export default {
  name: "Actions",
  components: {
    TopBar,
    BackButton,
    Save,
    FieldElement,
    InputText,
    InputSelect,
    SelectElement,
    LoadingAnimation,
    VisualSelector
  },
  data() {
    return {
      config: {
        id: "",
        name: "",
        trigger: [],
        requestType: "load",
        requests: []
      },
      loading: false,
      elementSelectorOpen: false,
      newAction: false,
      visualSelectorOpen: false
    }
  },
  methods: {
    changeRequestType(event) {
      this.config.requestType = event.id
      this.config.requests = []
      this.$forceUpdate()
    },
    selectAction(action) {
      this.config = { ...this.config, ...action }
    },
    createNewAction() {
      let id = this.generateActionId()
      this.newAction = true
      this.config.id = id
    },
    generateActionId() {
      let id = this.testIfIdAlredyTaken(id)
      return id
    },
    idGenerator(length) {
      var result = ""
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    testIfIdAlredyTaken() {
      let id = this.idGenerator(15)
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions
      ) {
        let index = this.$store.state.config[
          this.currentPage.id
        ].actions.findIndex(action => action.id == id)
        if (index === -1) {
          return id
        } else {
          return this.testIfIdAlredyTaken(id)
        }
      } else {
        return id
      }
    },
    selectedTrigger(event) {
      this.config.trigger.push(event)
      this.visualSelectorOpen = false
      this.elementSelectorOpen = false
    },
    unselectTrigger(value) {
      let index = this.config.trigger.findIndex(
        element => element.queryString === value.queryString
      )
      this.config.trigger.splice(index, 1)
    },
    closeElementSelector() {
      this.elementSelectorOpen = false
    },
    selectRequest(event) {
      let found = this.config.requests.find(requestId => requestId === event)
      if (!found) {
        this.config.requests.push(event[0].id)
      }
    },
    async saveAction() {
      let action = this.config
      this.loading = true
      this.$store.commit("saveAction", {
        action: action,
        pageId: this.currentPage.id
      })
      try {
        await this.$store.dispatch("saveConfig")
        await this.$store.dispatch(
          "getAdminConfig",
          this.$store.state.adminUser.jwt
        )
      } catch (e) {
        throw new Error(e)
      }
      this.closeConfig()
      this.loading = false
    },
    async deleteAction() {
      if (window.confirm("Are you sure you want to delete this action?")) {
        this.loading = true
        this.$store.commit("deleteAction", {
          actionId: this.config.id,
          pageId: this.currentPage.id
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        this.closeConfig()
        this.loading = false
      }
    },
    closeConfig() {
      this.config = {
        id: "",
        name: "",
        trigger: [],
        requestType: "load",
        requests: []
      }
      this.newAction = false
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    numberOfActions() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions
      ) {
        result = this.$store.state.config[this.currentPage.id].actions.length
      }
      return result
    },
    actions() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions
      ) {
        return this.$store.state.config[this.currentPage.id].actions
      } else return []
    },
    loadRequests() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        return this.$store.state.config[this.currentPage.id].load.requests
      } else return []
    },
    writeRequests() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        return this.$store.state.config[this.currentPage.id].write.requests
      } else return []
    },
    actionTypes() {
      return [
        {
          id: "load",
          title: "Load Data"
        }
        // {
        //   id: "write",
        //   title: "Write Data"
        // }
      ]
    },
    selectedActionType() {
      if (this.config.requestType === "load") {
        return {
          id: "load",
          title: "Load Data"
        }
      } else {
        return {
          id: "write",
          title: "Write Data"
        }
      }
    },
    availableActions() {
      let result = []
      if (this.config.requestType === "load") {
        this.loadRequests.forEach(request => {
          result.push({
            id: request.id,
            title: request.name,
            subtitle: this.selectedActionType.title
          })
        })
      }
      if (this.config.requestType === "write") {
        this.writeRequests.forEach(request => {
          result.push({
            id: request.id,
            title: request.name,
            subtitle: this.selectedActionType.title
          })
        })
      }
      return result
    },
    saveIsActive() {
      if (
        this.config.name &&
        this.config.requestType &&
        this.config.requests.length > 0
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
