<template>
  <div id="platform-wizards-widget">
    <div
      class="pw---background-blur"
      v-if="Object.keys($store.state.adminUser).length > 0"
    ></div>
    <router-view />
  </div>
</template>

<script>
let check = require("./auth/check.js")
var WebFont = require("webfontloader")

export default {
  name: "App",
  data() {
    return {
      isLoggedIn: false
    }
  },
  methods: {
    loadWebfont() {
      WebFont.load({
        google: {
          families: [
            "Poppins:100,200,300,regular,500,600,700,800,900",
            "Roboto:100,300,regular,500,700,900"
          ]
        }
      })
    }
  },
  async mounted() {
    let adminToken = check.token("token")
    let preview = check.getCookie("pw---preview")
    if (adminToken && !preview) {
      // Token available either as cookie or param
      this.$router.push({ name: "Authentication" })
    } else {
      // No token available
      this.$router.push({ name: "Render" })
    }
    this.loadWebfont()
  }
}
</script>

<style lang="scss">
body {
  display: block;
}

#platform-wizards-widget {
  @import "./css/pw-embed.webflow.scss";

  .pw---background-blur {
    backdrop-filter: blur(5px);
    z-index: 1000000000;
  }

  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  *::-webkit-scrollbar {
    display: none;
  }

  h2 {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    font-style: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 35px;
    line-height: 52px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    text-align: left;
  }

  h4 {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    text-align: left;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
  }

  h5 {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    text-align: left;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    font-family: Poppins, sans-serif;
    font-style: normal;
  }

  h6 {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    font-style: normal;
    text-align: left;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
  }

  p {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    font-style: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 0px;
    line-height: 21px;
    font-size: 14px;
    font-family: Poppins, sans-serif;
  }

  a {
    text-shadow: none;
    word-break: normal;
    direction: ltr;
    text-transform: none;
    columns: auto auto;
    text-indent: 0px;
    letter-spacing: normal;
    text-decoration: none;
    font-style: normal;
    text-align: left;
    color: #fff;
    text-decoration: underline;
    font-family: Poppins, sans-serif;
  }
}
</style>
