<template>
  <div class="pw---pw-second">
    <div v-show="!loading">
      <div class="pw---flex-row pw---heading">
        <div class="pw---flex-row">
          <h5>User Login</h5>
        </div>
      </div>
      <div class="pw---top-15">
        <div class="pw---seperator pw---small"></div>
      </div>
      <div class="pw---settings-wrap">
        <h5>Login Form</h5>
        <FieldElement
          title="Email Field *"
          subtitle="Please select the field where your user will type in his email"
          :initialValue="login.emailField"
          placeholder="Select email field"
          @open-selector="elementSeletorOpenFor = 'email-field'"
          @unselect="unselectValueFrom('email-field', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Login button *"
          subtitle="Please select the trigger button that will send the login-link email"
          :initialValue="login.loginLinkButton"
          placeholder="Select login button"
          @open-selector="elementSeletorOpenFor = 'login-button'"
          @unselect="unselectValueFrom('login-button', $event)"
          v-on="$listeners"
        />
      </div>
      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight"></div>
          <h5 class="pw---normal">While Loading</h5>
        </div>
        <FieldElement
          title="Show element"
          subtitle="Select loading animation (e.g. a loading spinner)"
          :initialValue="login.whileLoading.showElements"
          @open-selector="elementSeletorOpenFor = 'load-element'"
          @unselect="unselectValueFrom('load-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Hide element(s) during login"
          subtitle="e.g. hide email input form"
          :initialValue="login.whileLoading.hideUntilLoaded"
          @open-selector="elementSeletorOpenFor = 'load-hideUntil'"
          @unselect="unselectValueFrom('load-hideUntil', $event)"
          v-on="$listeners"
        />
      </div>
      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight pw---green"></div>
          <h5 class="pw---normal">On Success</h5>
        </div>
        <FieldElement
          title="Show element"
          subtitle="Select success message block"
          :initialValue="login.onSuccess.showElements"
          @open-selector="elementSeletorOpenFor = 'next-element'"
          @unselect="unselectValueFrom('next-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Insert next-steps message"
          subtitle="Select next-steps message element"
          :initialValue="login.onSuccess.insertMessageTo"
          @open-selector="elementSeletorOpenFor = 'next-msg'"
          @unselect="unselectValueFrom('next-msg', $event)"
          v-on="$listeners"
        />
      </div>
      <div class="pw---settings-wrap">
        <div class="pw---flexrow pw---left">
          <div class="pw---highlight pw---red"></div>
          <h5 class="pw---normal">On Error</h5>
        </div>
        <FieldElement
          title="Show element"
          subtitle="Select error message block (e.g. shown on validation error when email is invalid)"
          :initialValue="login.onError.showElements"
          @open-selector="elementSeletorOpenFor = 'error-element'"
          @unselect="unselectValueFrom('error-element', $event)"
          v-on="$listeners"
        />
        <FieldElement
          title="Insert error message"
          subtitle="Select error message text"
          :initialValue="login.onError.insertErrorTo"
          @open-selector="elementSeletorOpenFor = 'error-msg'"
          @unselect="unselectValueFrom('error-msg', $event)"
          v-on="$listeners"
        />
      </div>

      <Save
        @save="saveLogin"
        @cancel="$emit('cancel')"
        :saveIsActive="saveIsActive"
      />
    </div>
    <LoadingAnimation v-if="loading" />
  </div>
</template>

<script>
import Save from "./../ui/Save"
import FieldElement from "./../ui/FieldElement"
import LoadingAnimation from "./../misc/LoadingAnimation"

export default {
  name: "LoginConfig",
  props: { currentPage: Object, elementSelectorValue: Object },
  components: {
    Save,
    LoadingAnimation,
    FieldElement
  },
  data() {
    return {
      login: {
        emailField: [],
        loginLinkButton: [],
        whileLoading: {
          showElements: [],
          hideUntilLoaded: []
        },
        onSuccess: {
          showElements: [],
          insertMessageTo: []
        },
        onError: {
          showElements: [],
          insertErrorTo: []
        }
      },
      loading: false,
      elementSelectorIsOpen: false,
      elementSelectorOpenFor: String
    }
  },
  methods: {
    async saveLogin() {
      if (this.saveIsActive) {
        this.loading = true
        this.$store.commit("saveLogin", {
          login: this.login,
          pageId: this.currentPage.id
        })
        try {
          await this.$store.dispatch("saveConfig")
        } catch (e) {
          throw new Error(e)
        }
        await this.$store.dispatch(
          "getAdminConfig",
          this.$store.state.adminUser.jwt
        )
        this.$emit("saved")
        this.loading = false
      }
    },
    unselectValueFrom(attribute) {
      if (attribute == "email-field") this.login.emailField = []
      if (attribute == "login-button") this.login.loginLinkButton = []
      if (attribute == "next-element") this.login.onSuccess.showElements = []
      if (attribute == "next-msg") this.login.onSuccess.insertMessageTo = []
      if (attribute == "error-element") this.login.onError.showElements = []
      if (attribute == "error-msg") this.login.onError.insertErrorTo = []
      if (attribute == "load-element") this.login.whileLoading.showElements = []
      if (attribute == "load-hideUntil")
        this.login.whileLoading.hideUntilLoaded = []
    }
  },
  computed: {
    saveIsActive() {
      if (
        Object.keys(this.login.emailField).length > 0 &&
        Object.keys(this.login.loginLinkButton).length > 0
      ) {
        return true
      } else if (
        Object.keys(this.login.emailField).length === 0 &&
        Object.keys(this.login.loginLinkButton).length === 0
      ) {
        return true
      }
      return false
    }
  },
  mounted() {
    if (
      this.$store.state.config[this.currentPage.id] &&
      this.$store.state.config[this.currentPage.id].load &&
      this.$store.state.config[this.currentPage.id].load.login
    ) {
      let login = this.$store.state.config[this.currentPage.id].load.login
      this.login = { ...this.login, ...login }
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        if (this.elementSeletorOpenFor == "email-field")
          this.login.emailField = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "login-button")
          this.login.loginLinkButton = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "next-element")
          this.login.onSuccess.showElements = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "next-msg")
          this.login.onSuccess.insertMessageTo = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "load-element")
          this.login.whileLoading.showElements = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "load-hideUntil")
          this.login.whileLoading.hideUntilLoaded = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "error-element")
          this.login.onError.showElements = [this.elementSelectorValue]
        if (this.elementSeletorOpenFor == "error-msg")
          this.login.onError.insertErrorTo = [this.elementSelectorValue]
        this.elementSeletorOpenFor = String
      }
    }
  }
}
</script>
