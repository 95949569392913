var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.visualSelectorOpen),expression:"!visualSelectorOpen"}],staticClass:"pw---embed"},[_c('div',{staticClass:"pw---scroll-wrapper"},[_c('div',{staticClass:"pw---main"},[_c('TopBar'),_c('div',{staticClass:"pw---main-content"},[_c('div',{staticClass:"pw---flex-column pw---left"},[_c('div',{staticClass:"pw---seperator pw---small"}),_c('BackButton'),_c('div',{staticClass:"pw---seperator pw---small"})],1),_c('div',{staticClass:"pw---flex-row pw---left"},[_c('div',{staticClass:"pw---label pw---blue"},[_c('p',{staticClass:"pw---blue-text"},[_vm._v(_vm._s(_vm.numberOfLoadRequests))])]),_c('p',{staticClass:"pw---left-10"},[_vm._v("Load Data")])]),_c('div',{staticClass:"pw---operations"},[(
                _vm.$store.state.authentication &&
                  _vm.$store.state.authentication.login &&
                  _vm.$store.state.authentication.enabled &&
                  _vm.currentPage &&
                  _vm.currentPage.id !== _vm.$store.state.authentication.login.id
              )?_c('div',{staticClass:"pw---operation",class:{ 'pw---active': _vm.authConfigOpen },staticStyle:{"margin-bottom":"10px"},on:{"click":_vm.openAuthConfig}},[_c('label',{staticClass:"switch",staticStyle:{"margin":"0px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.authIsActive),expression:"authIsActive"}],attrs:{"type":"checkbox","disabled":""},domProps:{"checked":Array.isArray(_vm.authIsActive)?_vm._i(_vm.authIsActive,null)>-1:(_vm.authIsActive)},on:{"change":function($event){var $$a=_vm.authIsActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.authIsActive=$$a.concat([$$v]))}else{$$i>-1&&(_vm.authIsActive=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.authIsActive=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('p',{staticClass:"pw---left-10"},[_vm._v("Authenticate & Load User")])]):_vm._e(),(
                _vm.$store.state.authentication &&
                  _vm.$store.state.authentication.enabled &&
                  _vm.$store.state.authentication.login &&
                  _vm.currentPage.id === _vm.$store.state.authentication.login.id
              )?_c('div',{staticClass:"pw---operation",class:{ 'pw---active': _vm.loginConfigOpen },staticStyle:{"margin-bottom":"10px"},on:{"click":_vm.openLoginConfig}},[_c('label',{staticClass:"switch",staticStyle:{"margin":"0px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginIsConfigured),expression:"loginIsConfigured"}],attrs:{"type":"checkbox","disabled":""},domProps:{"checked":Array.isArray(_vm.loginIsConfigured)?_vm._i(_vm.loginIsConfigured,null)>-1:(_vm.loginIsConfigured)},on:{"change":function($event){var $$a=_vm.loginIsConfigured,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.loginIsConfigured=$$a.concat([$$v]))}else{$$i>-1&&(_vm.loginIsConfigured=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.loginIsConfigured=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('p',{staticClass:"pw---left-10"},[_vm._v("User Login")])]):_vm._e(),(
                _vm.$store.state.config && _vm.$store.state.config[_vm.currentPage.id]
              )?_c('div',_vm._l((_vm.requests),function(request){return _c('div',{key:request.id,staticClass:"pw---operation",class:{ 'pw---active': _vm.openRequestConfigId === request.id },staticStyle:{"margin-bottom":"10px"},on:{"click":function($event){return _vm.openRequestConfig(request.id)}}},[_c('div',{staticClass:"pw---flexrow pw---100"},[_c('p',[_vm._v(_vm._s(request.name))])])])}),0):_vm._e(),_c('div',{staticClass:"pw---flexrow pw---centered pw---top-15"},[_c('div',{staticClass:"pw---button pw---small pw---round pw---light",on:{"click":function () {
                    _vm.requestConfigOpen = true
                    _vm.loginConfigOpen = false
                    _vm.authConfigOpen = false
                  }}},[_c('img',{staticClass:"pw---20px-icon pw---right-10",attrs:{"src":"https://widget.platformwizards.com/images/white.svg","alt":""}}),_c('p',[_vm._v("Add Request")])])]),_c('div',{staticClass:"pw---button pw---big pw---top-20",style:({ opacity: _vm.hasAuthenticationOrRequests ? 1 : 0.2 }),on:{"click":function () {
                  if (this$1.hasAuthenticationOrRequests)
                    { _vm.$router.push({ name: 'LoadDataConnect' }) }
                }}},[_c('p',{staticClass:"pw---50 pw---right-5"},[_vm._v("Next:")]),_c('p',[_vm._v("Link loaded data with elements")])])])])],1)]),_c('div',{staticClass:"pw---scroll-wrapper"},[(_vm.authConfigOpen)?_c('LoadAuthenticationConfig',{attrs:{"currentPage":_vm.currentPage,"elementSelectorValue":_vm.elementSelectorValue},on:{"saved":_vm.closeAuthConfig,"cancel":_vm.closeAuthConfig,"open-selector":function($event){_vm.elementSelectorOpen = true}}}):_vm._e(),(_vm.loginConfigOpen)?_c('LoginConfig',{attrs:{"currentPage":_vm.currentPage,"elementSelectorValue":_vm.elementSelectorValue},on:{"saved":_vm.closeLoginConfig,"cancel":_vm.closeLoginConfig,"open-selector":function($event){_vm.elementSelectorOpen = true}}}):_vm._e(),(_vm.requestConfigOpen)?_c('LoadRequestConfig',{attrs:{"elementSelectorValue":_vm.elementSelectorValue,"requestConfig":_vm.currentlyOpenRequest,"currentPage":_vm.currentPage},on:{"open-selector":function($event){_vm.elementSelectorOpen = true},"cancel":_vm.closeRequestConfig,"saved":_vm.closeRequestConfig}}):_vm._e()],1),_c('div',{staticClass:"pw---scroll-wrapper"},[(_vm.elementSelectorOpen)?_c('SelectElement',_vm._g({on:{"selected":function($event){_vm.elementSelectorValue = $event},"open-visual-selector":function($event){_vm.visualSelectorOpen = true},"close-element-selector":function($event){_vm.elementSelectorOpen = false}}},_vm.$listeners)):_vm._e()],1)]),(_vm.visualSelectorOpen)?_c('VisualSelector',{on:{"selected":function($event){return _vm.selectedValue($event)},"canceled":function($event){_vm.visualSelectorOpen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }