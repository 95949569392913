<template>
  <div class="pw---third">
    <div
      class="pw---flex-row pw---main-heading"
      style="cursor: move; padding: 0px"
    >
      <h5>Select Element</h5>
      <div
        class="pw-close-popup-small"
        style="position: static;"
        @click="$emit('close-element-selector')"
      >
        <img
          src="https://widget.platformwizards.com/images/white_4.svg"
          alt=""
          class="pw---15px-icon pw---18px-icon"
        />
      </div>
    </div>
    <div class="pw---seperator pw---small"></div>
    <div
      class="pw---button pw---medium pw---outline-white"
      @click="$emit('open-visual-selector')"
    >
      <p>Visual Element Selector</p>
    </div>
    <div class="pw---seperator pw---small"></div>
    <PageTree @selected="$emit('close-element-selector')" v-on="$listeners" />
  </div>
</template>

<script>
import PageTree from "./PageTree"

export default {
  name: "SelectElement",
  components: { PageTree }
}
</script>
