<template>
  <div
    class="pw---button pw---small pw---light-white pw---right-20"
    @click="$router.go(-1)"
  >
    <img
      src="https://widget.platformwizards.com/images/white_2.svg"
      alt=""
      class="pw---20px-icon pw---right-10"
    />
    <p>Back</p>
  </div>
</template>

<script>
export default {
  name: "BackButton"
}
</script>
