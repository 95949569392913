var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pw---embed"},[_c('div',{staticClass:"pw---scroll-wrapper"},[_c('div',{staticClass:"pw---main",staticStyle:{"padding-left":"20px","padding-right":"20px","padding-top":"17px"}},[(!_vm.loadingUserToken)?_c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"pw---settings-wrap"},[_c('h5',{staticClass:"pw---normal pw---bottom-10"},[_vm._v("View as user")]),_c('InputToggle',{attrs:{"messageOnTrue":"Yes","messageOnFalse":"No","initialValue":_vm.preview.viewAsLoggedInUser},on:{"change":function (event) {
                  _vm.preview.viewAsLoggedInUser = event
                  if (_vm.preview.viewAsLoggedInUser) {
                    _vm.loadUsers()
                  } else {
                    _vm.preview.viewAs = {}
                  }
                }}}),(_vm.loadingUsers)?_c('LoadingAnimation'):_vm._e(),(_vm.loadingUserError)?_c('p',{staticStyle:{"color":"#ff715d","padding-top":"5px"}},[_vm._v(" "+_vm._s(_vm.loadingUserError)+" ")]):_vm._e(),(_vm.preview.viewAsLoggedInUser && !_vm.loadingUsers)?_c('InputSelect',{attrs:{"title":"Users","placeholder":"Select user","selectOptions":_vm.formatedUsers,"initialValue":_vm.preview.viewAs},on:{"change":function($event){return _vm.selectedUser($event)}}}):_vm._e()],1),(_vm.navigationParameters.length > 0)?_c('div',{staticClass:"pw---settings-wrap"},[_vm._m(2),_vm._l((_vm.navigationParameters),function(navigationParameter){return _c('div',{key:navigationParameter.id},[_c('div',{staticClass:"pw---select-value pw---orange pw---no-margin",staticStyle:{"margin-bottom":"5px","margin-top":"15px","width":"fit-content"}},[_c('p',[_vm._v(_vm._s(navigationParameter.name))]),_c('p',{staticClass:"pw---25 pw---5-left"},[_vm._v(" "+_vm._s(navigationParameter.dataType)+" ")])]),_c('InputText',{attrs:{"inputType":"text","initialValue":_vm.preview.parameters[navigationParameter.name],"placeholder":'Value of ' + navigationParameter.name},on:{"change":function($event){return _vm.setParameter($event, navigationParameter.name)}}})],1)})],2):_vm._e(),_c('div',{staticClass:"pw---flex-row pw---top-15 pw---right"},[_c('div',{staticClass:"pw---button pw---medium pw---ultra-light pw---right-10",on:{"click":function($event){return _vm.$router.push({ name: 'Menu' })}}},[_c('p',[_vm._v("Cancel")])]),_c('div',{staticClass:"pw---button pw---medium pw---light-blue pw---width-130",style:({ opacity: _vm.previewButtonIsActive ? 1 : 0.2 }),on:{"click":function () {
                  if (_vm.previewButtonIsActive) { _vm.showPreview() }
                }}},[_c('p',[_vm._v("Preview")])])])]):_vm._e(),(_vm.loadingUserToken)?_c('LoadingAnimation'):_vm._e()],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---flex-row pw---heading"},[_c('div',{staticClass:"pw---flex-row"},[_c('img',{staticClass:"pw---logo",attrs:{"src":"https://widget.platformwizards.com/images/logo.svg","loading":"lazy","alt":""}}),_c('div',{staticClass:"pw---flex-row",staticStyle:{"margin-left":"10px"}},[_c('h5',[_vm._v("Preview Settings")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---top-15"},[_c('div',{staticClass:"pw---seperator pw---small"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---flexrow pw---left"},[_c('div',{staticClass:"pw---highlight pw---orange"}),_c('h5',{staticClass:"pw---normal"},[_vm._v("Navigation Parameters")])])}]

export { render, staticRenderFns }