<template>
  <div class="pw---embed">
    <div class="pw---scroll-wrapper">
      <div class="pw---main">
        <div class="pw---flex-row pw---main-heading">
          <div class="pw---flex-row">
            <img
              src="https://widget.platformwizards.com/images/logo.svg"
              loading="lazy"
              alt=""
              class="pw---logo"
            />
            <h5 class="pw---left-10">Pages</h5>
          </div>
          <div
            class="pw---button pw---small pw---light-blue"
            @click="$router.push({ name: 'PreviewSettings' })"
          >
            <p>Preview</p>
          </div>
        </div>
        <div class="pw---main-content">
          <div class="pw---flex-column pw---left">
            <div class="pw---seperator pw---small"></div>
            <BackButton />
            <div class="pw---seperator pw---small"></div>
          </div>
          <div class="pw---pages">
            <div
              class="pw---page pw---flex-row pw---left"
              v-for="page in sortedPages"
              :key="page.slug"
              @click="navigateToPage(page.slug)"
              v-bind:class="{ 'pw---active': isCurrentPath(page.slug) }"
            >
              <div class="pw---flex-row" style="width: 100%">
                <div class="pw---flex-row pw---left">
                  <p class="pw---bold pw---50">/</p>
                  <p v-if="page.slug !== ''" class="pw---bold pw---5-left">
                    {{ page.slug }}
                  </p>
                  <p
                    v-if="page.slug === ''"
                    class="pw---25 pw---bold pw---5-left"
                  >
                    index
                  </p>
                  <div style="width: 5px" />
                  <Attribute
                    title="Login"
                    color="#ffffff10"
                    v-if="
                      $store.state.authentication.login &&
                        page.id === $store.state.authentication.login.id
                    "
                  />
                  <Attribute
                    title="Dashboard"
                    color="#ffffff10"
                    v-if="
                      $store.state.authentication.dashboard &&
                        page.id === $store.state.authentication.dashboard.id
                    "
                  />
                  <div v-if="false" class="pw---label pw---orange pw---left-10">
                    <p>todoId</p>
                  </div>
                </div>
                <div
                  class="pw---button pw---small pw---light-white pw---icon"
                  @click.stop="openEditPage(page)"
                >
                  <img
                    src="https://widget.platformwizards.com/images/white_settings.svg"
                    width="20"
                    alt=""
                    class="_20px-icon"
                  />
                </div>
              </div>
            </div>
            <div class="pw---flexrow pw---centered pw---top-15">
              <div
                class="pw---button pw---small pw---round pw---light"
                @click.stop="openEditPage()"
              >
                <img
                  src="https://widget.platformwizards.com/images/white.svg"
                  alt=""
                  class="pw---20px-icon pw---right-10"
                />
                <p>Add Page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pw---scroll-wrapper" v-if="editPage">
      <div class="pw---pw-second">
        <div v-if="!loading">
          <div class="pw---flex-row pw---heading">
            <div class="pw---flex-row" style="width: 100%">
              <h5 style="width: 100%">Add Page</h5>
              <div
                class="pw---button pw---small pw---light-white pw---icon"
                style="flex-shrink: 0"
                @click="deletePage"
                v-if="page.id"
              >
                <img
                  src="https://widget.platformwizards.com/images/white_trash.svg"
                  width="20"
                  alt=""
                  class="_20px-icon"
                />
              </div>
            </div>
          </div>
          <div class="pw---top-15">
            <div class="pw---seperator pw---small"></div>
          </div>
          <InputText
            v-if="!isIndexPage"
            title="Page slug"
            required="true"
            inputType="text"
            :leftPadding="17"
            fixedInputText="/"
            :initialValue="page.slug"
            @change="cleanPageSlug($event)"
          />
          <div
            class="pw---settings-wrap"
            v-if="$store.state.authentication.enabled && !page.isDashboard"
          >
            <h5>Login page</h5>
            <InputToggle
              title="Use this page as main login page"
              subtitle="If 'yes', unauthenticated users will be redirected to this page to log in."
              :initialValue="page.isLogin"
              @change="page.isLogin = $event"
            />
          </div>
          <div
            class="pw---settings-wrap"
            v-if="$store.state.authentication.enabled && !page.isLogin"
          >
            <h5>Dashboard</h5>
            <InputToggle
              title="Redirect users to this page after login"
              subtitle="If 'yes' users will be redirected to this page after login. It will be 'dashboard' page"
              :initialValue="page.isDashboard"
              @change="page.isDashboard = $event"
            />
          </div>
          <Save
            @cancel="closeEditPage"
            @save="updatePage"
            :saveIsActive="page.slug !== '' || isIndexPage"
          />
        </div>
        <LoadingAnimation v-if="loading" />
      </div>
    </div>
  </div>
</template>

<script>
// TODO SAVE PAGE SETTINGS CORRECTLY - CURRENTLY NOT SAVING AUTH SETTINGS

import BackButton from "./../components/nav/BackButton"
import Save from "./../components/ui/Save"
import InputText from "./../components/ui/InputText"
import InputToggle from "./../components/ui/InputToggle"
import LoadingAnimation from "./../components/misc/LoadingAnimation"
import Attribute from "./../components/ui/Attribute"
const axios = require("axios")

export default {
  name: "Pages",
  components: {
    BackButton,
    Save,
    InputText,
    LoadingAnimation,
    InputToggle,
    Attribute
  },
  data() {
    return {
      editPage: false,
      page: {
        id: "",
        slug: "",
        isLogin: false,
        isDashboard: false
      },
      loading: false,
      isIndexPage: false
    }
  },
  methods: {
    navigateToPage(slug) {
      window.location.href = "/" + slug
    },
    isCurrentPath(slug) {
      if ("/" + slug === this.currentPathName) {
        return true
      } else {
        return false
      }
    },
    openEditPage(page) {
      this.editPage = true
      if (page && (page.id || page.slug)) {
        this.page.id = page.id
        this.page.slug = page.slug
        if (page.slug === "") this.isIndexPage = true
        if (
          this.$store.state.authentication &&
          this.$store.state.authentication.dashboard &&
          this.$store.state.authentication.dashboard.id === this.page.id
        ) {
          this.page.isDashboard = true
        }
        if (
          this.$store.state.authentication &&
          this.$store.state.authentication.login &&
          this.$store.state.authentication.login.id === this.page.id
        ) {
          this.page.isLogin = true
        }
      }
    },
    closeEditPage() {
      this.editPage = false
      this.page.id = ""
      this.page.slug = ""
      this.page.isLogin = false
      this.page.isDashboard = false
      this.isIndexPage = false
    },
    async updatePage() {
      let method = this.page.id ? "put" : "post"
      this.loading = true
      await axios({
        method: method,
        url:
          "https://us-central1-platform-wizards-main.cloudfunctions.net/pages",
        headers: {
          projectid: document
            .querySelector("platform-wizards")
            .getAttribute("projectid"),
          access_token: this.$store.state.adminUser.jwt
        },
        data: {
          id: this.page.id,
          page: {
            slug: this.page.slug
          }
        }
      })
        .then(async () => {
          await this.updateAuthPages()
          await this.$store.dispatch(
            "getAdminConfig",
            this.$store.state.adminUser.jwt
          )
          let page = this.$store.state.pages.find(
            page => page.slug == this.page.slug
          )
          this.$store.commit("savePageToConfig", page)
          this.$store.dispatch("saveConfig")
          this.loading = false
          this.closeEditPage()
        })
        .catch(err => {
          throw new Error(err)
        })
    },
    async updateAuthPages() {
      if (
        this.page.isDashboard ||
        this.page.isLogin ||
        (this.$store.state.authentication &&
          this.$store.state.authentication.dashboard &&
          this.$store.state.authentication.dashboard.id === this.page.id) ||
        (this.$store.state.authentication &&
          this.$store.state.authentication.login &&
          this.$store.state.authentication.login.id === this.page.id)
      ) {
        // Selected page
        let authentication = this.$store.state.authentication
          ? this.$store.state.authentication
          : {}
        if (this.page.isLogin)
          authentication.login = { id: this.page.id, slug: this.page.slug }
        if (
          // Unselected page
          !this.page.isLogin &&
          this.$store.state.authentication &&
          this.$store.state.authentication.login &&
          this.$store.state.authentication.login.id === this.page.id
        )
          authentication.login = { id: "", slug: "" }
        // Selected page
        if (this.page.isDashboard)
          authentication.dashboard = { id: this.page.id, slug: this.page.slug }
        if (
          // Unselected page
          !this.page.isDashboard &&
          this.$store.state.authentication &&
          this.$store.state.authentication.dashboard &&
          this.$store.state.authentication.dashboard.id === this.page.id
        )
          authentication.dashboard = { id: "", slug: "" }

        await axios({
          method: "post",
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/saveProjectAuthPages",
          headers: {
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid"),
            access_token: this.$store.state.adminUser.jwt
          },
          data: { authentication }
        })
          .then(async () => {
            return
          })
          .catch(err => {
            throw new Error(err)
          })
      } else {
        return
      }
    },
    async deletePage() {
      if (
        window.confirm(
          "Are you sure you want to delete this page? All page settings will be lost."
        )
      ) {
        let method = "delete"
        let page = this.$store.state.pages.find(
          page => page.slug == this.page.slug
        )
        this.loading = true
        axios({
          method: method,
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/pages",
          headers: {
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid"),
            access_token: this.$store.state.adminUser.jwt
          },
          data: {
            id: this.page.id
          }
        })
          .then(async () => {
            this.$store.commit("deletePageFromConfig", page)
            this.$store.dispatch("saveConfig")
            await this.$store.dispatch(
              "getAdminConfig",
              this.$store.state.adminUser.jwt
            )
            this.closeEditPage()
            this.loading = false
          })
          .catch(err => {
            throw new Error(err)
          })
      }
    },
    cleanPageSlug(slug) {
      slug = slug.replace(/\s+/g, "")
      // eslint-disable-next-line no-useless-escape
      slug = slug.replace(/[^a-zA-Z0-9(\/)-]/g, "")
      this.page.slug = slug
    }
  },
  computed: {
    currentPathName() {
      return window.location.pathname
    },
    sortedPages() {
      let vm = this
      let sorted = vm.$store.state.pages.sort((a, b) => {
        if (a.slug < b.slug) {
          return -1
        }
        if (a.slug > b.slug) {
          return 1
        }
        return 0
      })
      return sorted
    }
  }
}
</script>
