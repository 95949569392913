<template>
  <div class="pw---scroll-wrapper">
    <div class="pw---third">
      <div class="pw---flex-row pw---main-heading">
        <div class="pw---flex-row pw---left">
          <div class="pw---flex-row">
            <h5 style="margin-right: 5px">Connect</h5>
            <Attribute :title="value.title" color="#6220f7" />
          </div>
          <h5 class="pw---left-5" style="margin-right: 5px">to:</h5>
        </div>
        <div
          class="pw-close-popup-small"
          style="position: static;"
          @click="$emit('cancel')"
        >
          <img
            src="https://widget.platformwizards.com/images/white_4.svg"
            alt=""
            class="pw---15px-icon pw---18px-icon"
          />
        </div>
      </div>
      <div class="pw---seperator pw---small"></div>
      <div class="pw---flexrow pw---left">
        <h5>Select value</h5>
      </div>
      <div class="pw---seperator pw---small"></div>
      <PageTree @selected="selectedElement($event)" :query="'input'" />
      <div class="pw---top-15">
        <div class="pw---seperator pw---small"></div>
      </div>
      <LoadRequestAttributeSelector
        v-for="request of allRequests"
        :key="request.id"
        @value-selected="selectedAttribute($event)"
        :request="request"
        :onlyListParameters="true"
      />
    </div>
  </div>
</template>

<script>
import LoadRequestAttributeSelector from "./LoadRequestAttributeSelector"
import PageTree from "./PageTree"
import Attribute from "./../ui/Attribute"

export default {
  name: "SelectData",
  components: { LoadRequestAttributeSelector, PageTree, Attribute },
  props: { value: Object },
  data() {
    return {
      connection: {
        type: "",
        value: {}
      }
    }
  },
  methods: {
    selectedAttribute(event) {
      this.connection.type = "attribute"
      this.connection.value = event
      this.connection.isRequired = true
      this.$emit("selected", this.connection)
    },
    selectedElement(event) {
      this.connection.type = "element"
      this.connection.value = event
      this.connection.isRequired = true
      this.$emit("selected", this.connection)
    },
    getAttributesOfTable(tableId, values) {
      let fields = this.$store.state.database.find(
        table => table.id === tableId
      ).fields
      if (values.length > 0) {
        fields = fields.filter(field => values.includes(field.name))
      }
      return fields
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    allRequests() {
      let requests = []
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        this.$store.state.config[this.currentPage.id].load.requests.forEach(
          request => {
            let values = this.getAttributesOfTable(
              request.tableId,
              request.values
            )
            requests.push({
              title: request.name,
              id: request.id,
              tableId: request.tableId,
              values: values,
              type: request.requestType
            })
          }
        )
      }
      if (this.$store.state.authentication.enabled) {
        let authValues = this.$store.state.database.find(
          table => table.id === this.$store.state.authentication.table.id
        ).fields
        requests.push({
          title: "User Authentication",
          tableId: this.$store.state.authentication.table.id,
          values: authValues,
          type: "item",
          id: "auth"
        })
      }
      if (this.$store.state.config[this.currentPage.id].parameters) {
        let parameterValues = this.$store.state.config[this.currentPage.id]
          .parameters
        requests.push({
          title: "Navigation parameters",
          tableId: this.$store.state.authentication.table.id,
          values: parameterValues,
          type: "item",
          id: "params"
        })
      }
      return requests
    }
  }
}
</script>
