<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div class="pw---input-group">
      <img
        src="https://widget.platformwizards.com/images/white_5.svg"
        alt=""
        class="pw---fixed-icon-input"
        @click.self="open"
      />
    </div>
    <div
      class="pw---multiple-select pw---small"
      @click.self="open"
      style="min-height: 51px"
    >
      <div
        class="pw---select-value"
        v-for="element in initialValue"
        :key="element.queryString"
      >
        <p>
          {{ element.id ? element.id : "." + element.classListString }}
        </p>
        <p class="pw---5-left pw---25 ">{{ element.tagName }}</p>
        <img
          @click="remove(element.queryString)"
          src="https://widget.platformwizards.com/images/white_4.svg"
          alt=""
          class="pw---15px-icon pw---left-5 pw---remove"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*
Props: 
- title
- subtitle
- isMultipleSelect
- placeholder
- isRequired


- selectData
- selectElements
- selectDataFilter
- selectElementsFilter
- selectFilterMessage

- selectedOption: Array
-- id
-- title
-- subtitle
*/

export default {
  name: "FieldElement",
  props: {
    title: String,
    subtitle: String,
    isMultipleSelect: { type: Boolean, default: false },
    initialValue: {
      type: [Array],
      validator: function(value) {
        if (Array.isArray(value)) {
          value.forEach(option => {
            if (
              !option.queryString ||
              !option.tagName ||
              (!option.id && !option.classNameString)
            ) {
              return false
            }
          })
          return true
        }
      }
    },
    placeholder: String
  },
  methods: {
    open() {
      this.$emit("open-selector")
    },
    remove(queryString) {
      let value
      if (Array.isArray(this.initialValue)) {
        value = this.initialValue.find(
          element => element.queryString === queryString
        )
      } else {
        value = this.initialValue
      }
      this.$emit("unselect", value)
    }
  }
}
</script>
