<template>
  <div class="pw---select-attribute">
    <p class="pw---bottom-5">{{ request.title }}</p>
    <div
      v-if="!childHasListConnection(request) || !childHasListConnectionCheck"
      class="pw---attribute-wrapper"
    >
      <div v-if="request.type === 'list'" class="pw---element-row">
        <img
          src="https://widget.platformwizards.com/images/25-white.svg"
          alt=""
          class="pw---15px-icon"
        />
        <div
          :style="{
            opacity: parentHasListConnection(request) ? 0.2 : 1
          }"
        >
          <Attribute
            title="List"
            subtitle="Item"
            color="#33adf2"
            @click="
              () => {
                if (!parentHasListConnection(request))
                  selectedValue(request, {
                    id: 'list-' + request.id,
                    name: 'List',
                    type: 'list'
                  })
              }
            "
          />
        </div>
      </div>
      <div
        v-for="value in request.values"
        :key="value.id"
        :style="{
          opacity:
            request.type === 'list' && !parentHasListConnection(request)
              ? 0.2
              : 1
        }"
      >
        <div
          v-if="value.type !== 'multipleAttachments'"
          class="pw---element-row"
        >
          <div
            v-if="request.type === 'list'"
            class="pw---depth"
            style="height: 35px"
          >
            <div class="pw---hor-depth"></div>
          </div>
          <img
            src="https://widget.platformwizards.com/images/25-white.svg"
            alt=""
            class="pw---15px-icon pw---0"
          />
          <Attribute
            :title="value.name"
            :subtitle="value.type"
            :color="
              request.title !== 'Navigation parameters' ? '#3371f2' : '#e18e41'
            "
            @click="
              () => {
                if (
                  (request.type === 'list' &&
                    parentHasListConnection(request)) ||
                  request.type !== 'list'
                )
                  selectedValue(request, value)
              }
            "
          />
        </div>
        <div v-if="value.type === 'multipleAttachments'">
          <div class="pw---element-row">
            <div
              v-if="request.type === 'list'"
              class="pw---depth"
              style="height: 35px"
            >
              <div class="pw---hor-depth"></div>
            </div>
            <img
              src="https://widget.platformwizards.com/images/25-white.svg"
              alt=""
              class="pw---15px-icon"
            />
            <Attribute
              :title="value.name"
              :subtitle="value.type"
              :color="'rgba(255, 255, 255, 0.1)'"
            />
          </div>
          <div class="pw---element-row">
            <div class="pw---depth" style="height: 35px">
              <div class="pw---hor-depth"></div>
            </div>
            <div
              v-if="request.type === 'list'"
              class="pw---depth"
              style="height: 35px"
            >
              <div class="pw---hor-depth"></div>
            </div>
            <img
              src="https://widget.platformwizards.com/images/25-white.svg"
              alt=""
              class="pw---15px-icon pw---0"
            />
            <Attribute
              :title="'url'"
              :subtitle="value.name"
              :color="
                request.title !== 'Navigation parameters'
                  ? '#3371f2'
                  : '#e18e41'
              "
              @click="
                () => {
                  if (
                    (request.type === 'list' &&
                      parentHasListConnection(request)) ||
                    request.type !== 'list'
                  )
                    selectedValue(request, {
                      name: value.name + '[0].url',
                      type: value.type,
                      id: value.id,
                      option: value.options
                    })
                }
              "
            />
          </div>
          <div class="pw---element-row">
            <div class="pw---depth" style="height: 35px">
              <div class="pw---hor-depth"></div>
            </div>
            <div
              v-if="request.type === 'list'"
              class="pw---depth"
              style="height: 35px"
            >
              <div class="pw---hor-depth"></div>
            </div>
            <img
              src="https://widget.platformwizards.com/images/25-white.svg"
              alt=""
              class="pw---15px-icon pw---0"
            />
            <Attribute
              :title="'filename'"
              :subtitle="value.name"
              :color="
                request.title !== 'Navigation parameters'
                  ? '#3371f2'
                  : '#e18e41'
              "
              @click="
                () => {
                  if (
                    (request.type === 'list' &&
                      parentHasListConnection(request)) ||
                    request.type !== 'list'
                  )
                    selectedValue(request, {
                      name: value.name + '[0].filename',
                      type: value.type,
                      id: value.id,
                      option: value.options
                    })
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="childHasListConnection(request) && childHasListConnectionCheck"
      class="pw---attribute-wrapper"
    >
      <p style="opacity: 0.3; margin-left: 10px">
        Seems like one of this elements children already has a 'List connection'
        with this request.
      </p>
    </div>
  </div>
</template>

<script>
import Attribute from "./../ui/Attribute"

export default {
  name: "loadRequestAttributeSelector",
  components: { Attribute },
  props: {
    request: Object,
    connection: Object,
    onlyListParameters: Boolean,
    childHasListConnectionCheck: { type: Boolean, default: false }
  },
  methods: {
    parentHasListConnection(request) {
      let listConnection = {}
      if (this.onlyListParameters) {
        return true
      } else if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        this.$store.state.config[this.currentPage.id].load.connections.forEach(
          connection => {
            if (
              connection.requestId === request.id &&
              connection.value.id === "list-" + connection.requestId
            ) {
              listConnection = connection
            }
          }
        )
        if (Object.keys(listConnection).length !== 0) {
          let parentQueryString = listConnection.element.queryString
          let parent = document.querySelector(parentQueryString)
          let selectedElement = document.querySelector(
            this.connection.element.queryString
          )
          if (
            this.isDescendant(parent, selectedElement) ||
            parentQueryString === this.connection.element.queryString
          ) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    childHasListConnection(request) {
      let listConnection = {}
      if (this.onlyListParameters) {
        return true
      } else if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        this.$store.state.config[this.currentPage.id].load.connections.forEach(
          connection => {
            if (
              connection.requestId === request.id &&
              connection.value.id === "list-" + connection.requestId
            ) {
              listConnection = connection
            }
          }
        )
        if (Object.keys(listConnection).length !== 0) {
          let listElementQueryString = listConnection.element.queryString
          let listElement = document.querySelector(listElementQueryString)
          let selectedElement = document.querySelector(
            this.connection.element.queryString
          )
          if (this.isDescendant(selectedElement, listElement)) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isDescendant(parent, child) {
      var node = child.parentNode
      while (node != null) {
        if (node == parent) {
          return true
        }
        node = node.parentNode
      }
      return false
    },
    selectedValue(request, value) {
      this.$emit("value-selected", {
        value: value,
        requestId: request.id,
        selectedRequestName: request.title
      })
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    }
  }
}
</script>
