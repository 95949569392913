<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div
      class="pw---input-div pw---top-5"
      @click="isOpen = true"
      :style="{ 'margin-bottom': isOpen ? '258px' : '0px' }"
    >
      <div
        v-if="isOpen"
        class="pw---multiple-select-selector"
        style="z-index: 2; display: flex; flex-direction: column;"
      >
        <input
          v-if="enableSearch"
          type="text"
          class="pw---dropdown-search w-input"
          placeholder="Search..."
          v-model="search"
          style="flex-shrink: 0;"
        />
        <div class="pw---5px-seperator" style="flex-shrink: 0;"></div>
        <div
          style="position: sticky; margin-top: -40px; margin-bottom: 20px;align-self: flex-end; flex-shrink: 0;"
          class="pw-close-popup-small"
          @click.stop="isOpen = false"
        >
          <img
            src="https://widget.platformwizards.com/images/white_4.svg"
            alt=""
            class="pw---15px-icon pw---18px-icon"
          />
        </div>
        <div
          class="pw---dropdown-select-element"
          v-for="selectOption in filteredSelectOptions"
          :key="selectOption.id"
          @click="selectValue(selectOption.id)"
          style="cursor: pointer; flex-shrink: 0;"
        >
          <div
            v-bind:class="{
              'pw---select-value pw---no-margin': hasBoxAroundOptions
            }"
          >
            <p>{{ selectOption.title }}</p>
            <p class="pw---25 pw---5-left">{{ selectOption.subtitle }}</p>
          </div>
        </div>
        <p
          class="pw---50"
          style="text-align: center; margin: 10px"
          v-if="messageOnEmpty && filteredSelectOptions.length === 0"
        >
          {{ messageOnEmpty }}
        </p>
      </div>
      <img
        src="https://widget.platformwizards.com/images/25-white.svg"
        alt=""
        class="pw---fixed-icon-input"
        :style="{ top: iconStyleTop }"
      />
      <div
        class="pw---dropdown pw---small"
        style="padding-right: 10px;padding-left: 10px;"
      >
        <div
          v-if="Object.keys(value).length !== 0 && !isMultipleSelect"
          class="pw---select-value"
          style="margin-left: 0px; margin-top: 0px;"
          :style="{
            backgroundColor: hideRemove
              ? '#1d131b'
              : 'rgba(255, 255, 255, 0.25)',
            paddingLeft: hideRemove ? 0 + 'px' : 5 + 'px'
          }"
        >
          <p v-if="value.title">{{ value.title }}</p>
          <p v-if="value.subtitle" class="pw---25 pw---5-left">
            {{ value.subtitle }}
          </p>
          <img
            src="https://widget.platformwizards.com/images/white_4.svg"
            alt=""
            class="pw---15px-icon pw---left-5 pw---remove"
            @click="resetValue"
            v-if="!hideRemove"
          />
        </div>
        <div
          v-if="Object.keys(value).length !== 0 && isMultipleSelect"
          style="margin-left: -5px; margin-top: -5px; width: 100%; display: flex; justify-content: left; align-items: center; flex-wrap: wrap; "
        >
          <div
            class="pw---select-value"
            style=""
            v-for="select in value"
            :key="select.id"
          >
            <p v-if="select.title">{{ select.title }}</p>
            <p v-if="select.subtitle" class="pw---25 pw---5-left">
              {{ select.subtitle }}
            </p>
            <img
              src="https://widget.platformwizards.com/images/white_4.svg"
              alt=""
              class="pw---15px-icon pw---left-5 pw---remove"
              @click="resetValue(select.id)"
              v-if="!hideRemove"
            />
          </div>
        </div>
        <p
          v-if="Object.keys(value).length === 0"
          class="pw---select-value pw---50"
          style="background-color: transparent; margin-left: 0px; margin-top: 0px; padding: 0px"
        >
          {{ placeholder }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/*
Props:
- title
- subtitle
- isMultipleSelect
- enableSearch
- placeholder
- isRequired
- hasBoxAroundOptions

- selectOptions: Array
-- id
-- title
-- subtitle
*/

export default {
  name: "InputSelect",
  props: {
    title: String,
    subtitle: String,
    isMultipleSelect: { type: Boolean, default: false },
    enableSearch: { type: Boolean, default: true },
    initialValue: [Array, Object],
    placeholder: String,
    isRequired: { type: Boolean, default: false },
    hasBoxAroundOptions: { type: Boolean, default: false },
    selectOptions: {
      type: Array,
      required: true,
      validator: function(value) {
        value.forEach(option => {
          if (!option.title || !option.id) {
            return false
          }
        })
        return true
      }
    },
    hideRemove: Boolean,
    messageOnEmpty: String
  },
  data() {
    return {
      isOpen: false,
      search: "",
      returnValue: this.initialValue ? this.initialValue : {}
    }
  },
  methods: {
    selectValue(id) {
      let value = this.selectOptions.find(element => element.id === id)
      if (!this.isMultipleSelect) {
        this.returnValue = value
      } else {
        if (!this.returnValue.includes(value)) {
          this.returnValue.push(value)
        }
      }
    },
    resetValue(id) {
      if (!this.isMultipleSelect) {
        this.returnValue = {}
      } else {
        let index = this.value.findIndex(element => element.id === id)
        this.returnValue.splice(index, 1)
      }
    }
  },
  computed: {
    filteredSelectOptions() {
      let selectOptions = []
      this.selectOptions.forEach(option => {
        if (Object.keys(option).length > 0 && option.title) {
          selectOptions.push(option)
        }
      })
      return selectOptions.filter(
        selectOption =>
          selectOption.title
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          (selectOption.subtitle &&
            selectOption.subtitle
              .toLowerCase()
              .includes(this.search.toLowerCase()))
      )
    },
    iconStyleTop() {
      return !this.hasBoxAroundOptions ||
        (this.hasBoxAroundOptions && Object.keys(this.value).length === 0)
        ? "39px"
        : "42px"
    },
    value() {
      return this.initialValue ? this.initialValue : {}
    }
  },
  watch: {
    returnValue: function(value) {
      this.$emit("change", value)
    }
  }
}
</script>
