<template>
  <div>
    <div class="pw---embed" v-show="!visualSelectorOpen">
      <div class="pw---scroll-wrapper">
        <div class="pw---main">
          <TopBar />
          <div class="pw---main-content">
            <div class="pw---flex-column pw---left">
              <div class="pw---seperator pw---small"></div>
              <BackButton />
              <div class="pw---seperator pw---small"></div>
            </div>
            <div class="pw---flex-row pw---left">
              <div class="pw---label pw---blue">
                <p class="pw---blue-text">{{ numberOfLoadRequests }}</p>
              </div>
              <p class="pw---left-10">Load Data</p>
            </div>
            <div class="pw---operations">
              <div
                class="pw---operation"
                :class="{ 'pw---active': authConfigOpen }"
                @click="openAuthConfig"
                v-if="
                  $store.state.authentication &&
                    $store.state.authentication.login &&
                    $store.state.authentication.enabled &&
                    currentPage &&
                    currentPage.id !== $store.state.authentication.login.id
                "
                style="margin-bottom: 10px;"
              >
                <label class="switch" style="margin: 0px">
                  <input type="checkbox" disabled v-model="authIsActive" />
                  <span class="slider round"></span>
                </label>
                <p class="pw---left-10">Authenticate &amp; Load User</p>
              </div>
              <div
                class="pw---operation"
                :class="{ 'pw---active': loginConfigOpen }"
                @click="openLoginConfig"
                v-if="
                  $store.state.authentication &&
                    $store.state.authentication.enabled &&
                    $store.state.authentication.login &&
                    currentPage.id === $store.state.authentication.login.id
                "
                style="margin-bottom: 10px;"
              >
                <label class="switch" style="margin: 0px">
                  <input type="checkbox" disabled v-model="loginIsConfigured" />
                  <span class="slider round"></span>
                </label>
                <p class="pw---left-10">User Login</p>
              </div>
              <div
                v-if="
                  $store.state.config && $store.state.config[currentPage.id]
                "
              >
                <div
                  v-for="request in requests"
                  :key="request.id"
                  class="pw---operation"
                  :class="{ 'pw---active': openRequestConfigId === request.id }"
                  @click="openRequestConfig(request.id)"
                  style="margin-bottom: 10px;"
                >
                  <div class="pw---flexrow pw---100">
                    <p>{{ request.name }}</p>
                  </div>
                </div>
              </div>
              <div class="pw---flexrow pw---centered pw---top-15">
                <div
                  class="pw---button pw---small pw---round pw---light"
                  @click="
                    () => {
                      requestConfigOpen = true
                      loginConfigOpen = false
                      authConfigOpen = false
                    }
                  "
                >
                  <img
                    src="https://widget.platformwizards.com/images/white.svg"
                    alt=""
                    class="pw---20px-icon pw---right-10"
                  />
                  <p>Add Request</p>
                </div>
              </div>
              <div
                :style="{ opacity: hasAuthenticationOrRequests ? 1 : 0.2 }"
                class="pw---button pw---big pw---top-20"
                @click="
                  () => {
                    if (this.hasAuthenticationOrRequests)
                      $router.push({ name: 'LoadDataConnect' })
                  }
                "
              >
                <p class="pw---50 pw---right-5">Next:</p>
                <p>Link loaded data with elements</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pw---scroll-wrapper">
        <LoadAuthenticationConfig
          v-if="authConfigOpen"
          :currentPage="currentPage"
          @saved="closeAuthConfig"
          @cancel="closeAuthConfig"
          @open-selector="elementSelectorOpen = true"
          :elementSelectorValue="elementSelectorValue"
        />
        <LoginConfig
          v-if="loginConfigOpen"
          :currentPage="currentPage"
          @saved="closeLoginConfig"
          @cancel="closeLoginConfig"
          @open-selector="elementSelectorOpen = true"
          :elementSelectorValue="elementSelectorValue"
        />
        <LoadRequestConfig
          v-if="requestConfigOpen"
          @open-selector="elementSelectorOpen = true"
          @cancel="closeRequestConfig"
          @saved="closeRequestConfig"
          :elementSelectorValue="elementSelectorValue"
          :requestConfig="currentlyOpenRequest"
          :currentPage="currentPage"
        />
      </div>
      <div class="pw---scroll-wrapper">
        <SelectElement
          v-if="elementSelectorOpen"
          @selected="elementSelectorValue = $event"
          @open-visual-selector="visualSelectorOpen = true"
          @close-element-selector="elementSelectorOpen = false"
          v-on="$listeners"
        />
      </div>
    </div>
    <VisualSelector
      v-if="visualSelectorOpen"
      @selected="selectedValue($event)"
      @canceled="visualSelectorOpen = false"
    />
  </div>
</template>

<script>
import LoadAuthenticationConfig from "./../components/configViews/LoadAuthenticationConfig"
import LoginConfig from "./../components/configViews/LoginConfig"
import LoadRequestConfig from "./../components/configViews/LoadRequestConfig"
import TopBar from "./../components/nav/TopBar"
import BackButton from "./../components/nav/BackButton"
import SelectElement from "./../components/configViews/SelectElement"
import VisualSelector from "./VisualSelector"

export default {
  name: "LoadDataRequests",
  components: {
    LoadAuthenticationConfig,
    LoadRequestConfig,
    TopBar,
    BackButton,
    SelectElement,
    VisualSelector,
    LoginConfig
  },
  props: {
    visualSelectorValue: Object
  },
  data() {
    return {
      authConfigOpen: false,
      requestConfigOpen: false,
      loginConfigOpen: false,
      openRequestConfigId: String,
      elementSelectorOpen: false,
      elementSelectorValue: {},
      visualSelectorOpen: false
    }
  },
  methods: {
    openRequestConfig(id) {
      if (this.authConfigOpen === true || this.loginConfigOpen === true) {
        this.authConfigOpen = false
        this.loginConfigOpen = false
      }
      if (this.openRequestConfigId === String) {
        if (this.authConfigOpen === true) this.authConfigOpen = false
        this.requestConfigOpen = true
        this.openRequestConfigId = id
      }
    },
    closeRequestConfig() {
      this.requestConfigOpen = false
      this.openRequestConfigId = String
    },
    closeAuthConfig() {
      this.authConfigOpen = false
    },
    closeLoginConfig() {
      this.loginConfigOpen = false
    },
    openAuthConfig() {
      if (this.requestConfigOpen === true) {
        this.openRequestConfigId = String
        this.requestConfigOpen = false
      }
      this.authConfigOpen = true
    },
    openLoginConfig() {
      if (this.requestConfigOpen === true) {
        this.requestConfigOpen = false
        this.openRequestConfigId = String
      }
      this.loginConfigOpen = true
    },
    selectedValue(event) {
      this.elementSelectorValue = event
      this.visualSelectorOpen = false
      this.elementSelectorOpen = false
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    currentlyOpenRequest() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests &&
        this.openRequestConfigId
      ) {
        let request = this.$store.state.config[
          this.currentPage.id
        ].load.requests.find(request => request.id === this.openRequestConfigId)
        return request
      } else {
        return {}
      }
    },
    authIsActive() {
      if (
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.authentication &&
        this.$store.state.config[this.currentPage.id].load.authentication
          .isActive
      ) {
        return this.$store.state.config[this.currentPage.id].load.authentication
          .isActive
      } else {
        return false
      }
    },
    loginIsConfigured() {
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.login &&
        Object.keys(
          this.$store.state.config[this.currentPage.id].load.login.emailField
        ).length > 0 &&
        Object.keys(
          this.$store.state.config[this.currentPage.id].load.login
            .loginLinkButton
        ).length > 0
      ) {
        return true
      } else {
        return false
      }
    },
    requests() {
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        return this.$store.state.config[this.currentPage.id].load.requests
      } else {
        return []
      }
    },
    hasAuthenticationOrRequests() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load
      ) {
        if (
          (this.$store.state.config[this.currentPage.id].load.requests &&
            this.$store.state.config[this.currentPage.id].load.requests
              .length !== 0) ||
          (this.$store.state.config[this.currentPage.id].load.authentication &&
            this.$store.state.config[this.currentPage.id].load.authentication
              .isActive)
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    numberOfLoadRequests() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        result = this.$store.state.config[this.currentPage.id].load.requests
          .length
      }
      if (this.authIsActive) {
        result += 1
      }
      if (this.loginIsConfigured) {
        result += 1
      }
      return result
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        this.visualSelectorOpen = false
      }
    }
  }
}
</script>

<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #ffffff;
}
input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #3371f2;
}
</style>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #ffffff;
}
input:focus + .slider {
  box-shadow: 0 0 1px #ffffff;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #3371f2;
}
</style>
