var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---select-attribute"},[_c('p',{staticClass:"pw---bottom-5"},[_vm._v(_vm._s(_vm.request.title))]),(!_vm.childHasListConnection(_vm.request) || !_vm.childHasListConnectionCheck)?_c('div',{staticClass:"pw---attribute-wrapper"},[(_vm.request.type === 'list')?_c('div',{staticClass:"pw---element-row"},[_c('img',{staticClass:"pw---15px-icon",attrs:{"src":"https://widget.platformwizards.com/images/25-white.svg","alt":""}}),_c('div',{style:({
          opacity: _vm.parentHasListConnection(_vm.request) ? 0.2 : 1
        })},[_c('Attribute',{attrs:{"title":"List","subtitle":"Item","color":"#33adf2"},on:{"click":function () {
              if (!_vm.parentHasListConnection(_vm.request))
                { _vm.selectedValue(_vm.request, {
                  id: 'list-' + _vm.request.id,
                  name: 'List',
                  type: 'list'
                }) }
            }}})],1)]):_vm._e(),_vm._l((_vm.request.values),function(value){return _c('div',{key:value.id,style:({
        opacity:
          _vm.request.type === 'list' && !_vm.parentHasListConnection(_vm.request)
            ? 0.2
            : 1
      })},[(value.type !== 'multipleAttachments')?_c('div',{staticClass:"pw---element-row"},[(_vm.request.type === 'list')?_c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})]):_vm._e(),_c('img',{staticClass:"pw---15px-icon pw---0",attrs:{"src":"https://widget.platformwizards.com/images/25-white.svg","alt":""}}),_c('Attribute',{attrs:{"title":value.name,"subtitle":value.type,"color":_vm.request.title !== 'Navigation parameters' ? '#3371f2' : '#e18e41'},on:{"click":function () {
              if (
                (_vm.request.type === 'list' &&
                  _vm.parentHasListConnection(_vm.request)) ||
                _vm.request.type !== 'list'
              )
                { _vm.selectedValue(_vm.request, value) }
            }}})],1):_vm._e(),(value.type === 'multipleAttachments')?_c('div',[_c('div',{staticClass:"pw---element-row"},[(_vm.request.type === 'list')?_c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})]):_vm._e(),_c('img',{staticClass:"pw---15px-icon",attrs:{"src":"https://widget.platformwizards.com/images/25-white.svg","alt":""}}),_c('Attribute',{attrs:{"title":value.name,"subtitle":value.type,"color":'rgba(255, 255, 255, 0.1)'}})],1),_c('div',{staticClass:"pw---element-row"},[_vm._m(0,true),(_vm.request.type === 'list')?_c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})]):_vm._e(),_c('img',{staticClass:"pw---15px-icon pw---0",attrs:{"src":"https://widget.platformwizards.com/images/25-white.svg","alt":""}}),_c('Attribute',{attrs:{"title":'url',"subtitle":value.name,"color":_vm.request.title !== 'Navigation parameters'
                ? '#3371f2'
                : '#e18e41'},on:{"click":function () {
                if (
                  (_vm.request.type === 'list' &&
                    _vm.parentHasListConnection(_vm.request)) ||
                  _vm.request.type !== 'list'
                )
                  { _vm.selectedValue(_vm.request, {
                    name: value.name + '[0].url',
                    type: value.type,
                    id: value.id,
                    option: value.options
                  }) }
              }}})],1),_c('div',{staticClass:"pw---element-row"},[_vm._m(1,true),(_vm.request.type === 'list')?_c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})]):_vm._e(),_c('img',{staticClass:"pw---15px-icon pw---0",attrs:{"src":"https://widget.platformwizards.com/images/25-white.svg","alt":""}}),_c('Attribute',{attrs:{"title":'filename',"subtitle":value.name,"color":_vm.request.title !== 'Navigation parameters'
                ? '#3371f2'
                : '#e18e41'},on:{"click":function () {
                if (
                  (_vm.request.type === 'list' &&
                    _vm.parentHasListConnection(_vm.request)) ||
                  _vm.request.type !== 'list'
                )
                  { _vm.selectedValue(_vm.request, {
                    name: value.name + '[0].filename',
                    type: value.type,
                    id: value.id,
                    option: value.options
                  }) }
              }}})],1)]):_vm._e()])})],2):_vm._e(),(_vm.childHasListConnection(_vm.request) && _vm.childHasListConnectionCheck)?_c('div',{staticClass:"pw---attribute-wrapper"},[_c('p',{staticStyle:{"opacity":"0.3","margin-left":"10px"}},[_vm._v(" Seems like one of this elements children already has a 'List connection' with this request. ")])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pw---depth",staticStyle:{"height":"35px"}},[_c('div',{staticClass:"pw---hor-depth"})])}]

export { render, staticRenderFns }