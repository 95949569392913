<template>
  <div>
    <div class="pw---embed">
      <div class="pw---scroll-wrapper">
        <div
          class="pw---main"
          style="padding-left: 20px; padding-right: 20px; padding-top: 17px"
        >
          <div class="pw---flex-row pw---main-heading">
            <div class="pw---flex-row">
              <img
                src="https://widget.platformwizards.com/images/logo.svg"
                loading="lazy"
                alt=""
                class="pw---logo"
              />
              <h5 class="pw---left-10">Authentication</h5>
            </div>
          </div>
          <div class="pw---main-content">
            <LoadingAnimation v-if="loading" />
            <div class="pw---settings-wrap" v-if="error">
              <div class="pw---flexrow pw---left pw---bottom-10">
                <div class="pw---highlight pw---red"></div>
                <p class="pw---normal ">Authentication failed</p>
              </div>
              <div>
                <p class="pw---50 pw---bottom-10">
                  Seems like your login token has expired. <br />Please go back
                  to the project dashbaord and try again.
                </p>
                <div
                  class="pw---button pw---medium pw---ultra-light"
                  @click="goToProjectDashbaord()"
                  style="background-color: #ffffff20;"
                >
                  <p>Project Dashboard</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO SAVE PAGE SETTINGS CORRECTLY - CURRENTLY NOT SAVING AUTH SETTINGS
import LoadingAnimation from "./../components/misc/LoadingAnimation"
const axios = require("axios")
let check = require("./../auth/check.js")
import * as Sentry from "@sentry/browser"

export default {
  name: "Pages",
  components: { LoadingAnimation },
  data() {
    return {
      loading: true,
      error: false
    }
  },
  methods: {
    async auth(token) {
      return new Promise(resolve => {
        axios({
          method: "get",
          url:
            "https://us-central1-platform-wizards-main.cloudfunctions.net/checkRightsToEditProjectConfig",
          headers: {
            access_token: token,
            projectid: document
              .querySelector("platform-wizards")
              .getAttribute("projectid")
          }
        })
          .then(function(response) {
            if (response.data) {
              resolve(response.data)
            }
            resolve(false)
          })
          .catch(function(e) {
            resolve(false)
            throw new Error(e)
          })
      })
    },
    async login(token) {
      // eslint-disable-next-line no-unused-vars
      let [config, auth] = await Promise.all([
        this.$store.dispatch("getAdminConfig", token),
        this.auth(token)
      ])
      if (auth && auth.isAuthenticatedForProject) {
        // Is logged in
        if (process.env.NODE_ENV !== "development") {
          // eslint-disable-next-line no-undef
          Sentry.setUser({ uid: auth.user.uid, email: auth.user.email })
        }
        this.$store.commit("updateAdminUser", auth.user)
        this.initIntercom(
          auth.user.uid,
          auth.user.firstName,
          auth.user.lastName,
          auth.user.email
        )
        this.hideWebflowBadge()
        check.deleteParam("token")
        this.loading = false
        if (check.isPreviewEnabled()) {
          // Push to preview
          this.$router.push({ name: "PreviewSettings" })
          check.deleteParam("preview")
        } else {
          this.$router.push({ name: "Menu" })
        }
      } else {
        this.loading = false
        this.error = true
      }
    },
    hideWebflowBadge() {
      let elements = document.getElementsByClassName("w-webflow-badge")
      elements.forEach(element => {
        element.setAttribute("style", "display: none! important")
      })
    },
    initIntercom(userId, userFirstName, userLastName, userEmail) {
      //console.log(userId, userFirstName, userLastName, userEmail)
      let intercom = {
        user_id: userId,
        email: userEmail
      }
      if (userFirstName) {
        intercom.name = userFirstName
      } else if (userFirstName && userLastName) {
        intercom.name = userFirstName + " " + userLastName
      }
      this.$intercom.boot(intercom)
    },
    goToProjectDashbaord() {
      window.location.href =
        "https://app.platformwizards.com/project/" +
        document.querySelector("platform-wizards").getAttribute("projectid")
    }
  },
  mounted() {
    document.querySelector("body").style.display = "block"
    let adminToken = check.token("token")
    this.login(adminToken)
  }
}
</script>
