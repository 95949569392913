<template>
  <div class="pw---embed">
    <div class="pw---scroll-wrapper">
      <div class="pw---main">
        <TopBar />
        <LoadingAnimation
          v-if="
            Object.keys($store.state.adminUser).length === 0 &&
              $store.state.adminUser.constructor === Object
          "
        />
        <div
          class="pw---main-content"
          v-if="Object.keys($store.state.adminUser).length !== 0"
        >
          <div class="pw---flex-column pw---left">
            <div class="pw---seperator pw---small"></div>
            <div
              class="pw---flex-row"
              style="align-items: flex-start; flex-direction: column;"
            >
              <div
                class="pw---button pw---medium pw---ultra-light"
                @click="goToProjectDashbaord()"
                style="background-color: #ffffff08;"
              >
                <img
                  src="https://widget.platformwizards.com/images/white_2.svg"
                  alt=""
                  class="pw---20px-icon pw---right-10"
                />
                <p>Project Dashboard</p>
              </div>
            </div>
            <div class="pw---seperator pw---small"></div>
          </div>
          <div class="pw---pages">
            <div class="pw---menu-item" @click="navigateTo('LoadDataRequests')">
              <div class="pw---flexrow">
                <div class="pw---label pw---blue">
                  <p class="pw---blue-text">{{ numberOfLoadRequests }}</p>
                </div>
                <p class="pw---left-10">Load Data</p>
              </div>
              <p class="pw---50 pw---top-5">Load data fom your database.</p>
            </div>
            <div
              class="pw---menu-item"
              @click="navigateTo('WriteDataRequests')"
            >
              <div class="pw---flexrow">
                <div class="pw---label pw---violet">
                  <p class="pw---violet-text">{{ numberOfWriteRequests }}</p>
                </div>
                <div class="pw---label pw---red pw---left-10" v-if="false">
                  <img
                    src="https://widget.platformwizards.com/images/white_1.svg"
                    width="20"
                    alt=""
                    class="pw---20px-icon"
                  />
                  <p class="pw---left-5">1</p>
                </div>
                <p class="pw---left-10">Write Data</p>
              </div>
              <p class="pw---50 pw---top-5">Write data to your database.</p>
            </div>
            <div class="pw---menu-item" @click="navigateTo('Actions')">
              <div class="pw---flexrow">
                <div class="pw---label pw---turquoise">
                  <p class="pw---turqoise-text">{{ numberOfActions }}</p>
                </div>
                <p class="pw---left-10">Actions</p>
              </div>
              <p class="pw---50 pw---top-5">
                Trigger load / write operations based on user interaction.
              </p>
            </div>
            <div
              class="pw---menu-item"
              @click="navigateTo('NavigationParameters')"
            >
              <div class="pw---flexrow">
                <div class="pw---label pw---light-orange pw---orange-outline">
                  <p class="pw---orange-text">{{ numberOfNavParameters }}</p>
                </div>
                <p class="pw---left-10">Navigation Parameters</p>
              </div>
              <p class="pw---50 pw---top-5">
                Specify parameters that must be passed in a link to this page.
              </p>
            </div>
          </div>
          <div class="pw---seperator pw---small"></div>
          <div
            class="pw---flex-row"
            style="align-items: flex-start; flex-direction: column;"
          >
            <div
              @click="signOut()"
              class="pw---button pw---medium pw---ultra-light"
              style="background-color: #ffffff08;"
            >
              <p>Sign Out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingAnimation from "./../components/misc/LoadingAnimation"
import TopBar from "./../components/nav/TopBar"
let check = require("./../auth/check.js")
import * as Sentry from "@sentry/browser"

export default {
  name: "Menu",
  components: { LoadingAnimation, TopBar },
  methods: {
    navigateTo(name) {
      this.$router.push({ name: name })
    },
    goToProjectDashbaord() {
      window.location.href =
        "https://app.platformwizards.com/project/" +
        document.querySelector("platform-wizards").getAttribute("projectid")
    },
    signOut() {
      check.setCookie("token", "", 0)
      if (process.env.NODE_ENV !== "development") {
        // eslint-disable-next-line no-undef
        Sentry.configureScope(scope => scope.setUser(null))
      }
      location.reload()
    },
    handlePageDoesNotExist() {
      setTimeout(() => {
        window.alert(
          "This page does not exist in your Platform Wizards project configuration. To edit this page, please add it first."
        )
        window.location.href =
          "https://" +
          window.location.hostname +
          "/" +
          this.$store.state.pages[0].slug
      }, 1000)
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      if (page && typeof page.slug === "string") {
        return page
      } else {
        this.handlePageDoesNotExist()
        return {}
      }
    },
    numberOfLoadRequests() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        result = this.$store.state.config[this.currentPage.id].load.requests
          .length
      }
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.authentication &&
        this.$store.state.config[this.currentPage.id].load.authentication
          .isActive
      ) {
        result += 1
      }
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.login &&
        Object.keys(
          this.$store.state.config[this.currentPage.id].load.login.emailField
        ).length > 0 &&
        Object.keys(
          this.$store.state.config[this.currentPage.id].load.login
            .loginLinkButton
        ).length > 0
      ) {
        result += 1
      }
      return result
    },
    numberOfWriteRequests() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        result = this.$store.state.config[this.currentPage.id].write.requests
          .length
      }
      return result
    },
    numberOfActions() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].actions
      ) {
        result = this.$store.state.config[this.currentPage.id].actions.length
      }
      return result
    },
    numberOfNavParameters() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].parameters
      ) {
        result = this.$store.state.config[this.currentPage.id].parameters.length
      }
      return result
    }
  }
}
</script>
