import jwt_decode from "jwt-decode"

let getURLParameter = name => {
  let url_string = window.location.href
  let url = new URL(url_string)
  if (url.searchParams.get(name)) {
    return url.searchParams.get(name)
  } else {
    return false
  }
}

let setCookie = (cname, cvalue, exdays) => {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

let getCookie = cname => {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

let token = tokenName => {
  saveToken(tokenName)
  if (tokenExistsAndIsNotExpired(tokenName)) {
    let token = tokenExistsAndIsNotExpired(tokenName)
    // Get User Info
    return token
  } else {
    return false
  }
}

let saveToken = tokenName => {
  let token = getURLParameter(tokenName)
  if (token) {
    setCookie(tokenName, token, 1)
  }
}

let deleteParam = paramName => {
  let url = new URL(window.location.href)
  url.searchParams.delete(paramName)
  window.history.replaceState(null, null, url)
}

let isPreviewEnabled = () => {
  let url = new URL(window.location.href)
  let preview = url.searchParams.get("preview")
  if (preview == 1) {
    return true
  } else return false
}

let tokenExistsAndIsNotExpired = tokenName => {
  let token = getCookie(tokenName)
  if (token) {
    try {
      var decoded = jwt_decode(token)
      if (Date.now() / 1000 < decoded.exp) {
        return token
      } else {
        return false
      }
    } catch (err) {
      return false
    }
  } else {
    return false
  }
}

export {
  token,
  deleteParam,
  isPreviewEnabled,
  setCookie,
  getURLParameter,
  getCookie
}
