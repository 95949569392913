<template>
  <div class="pw---input-group pw---top-10">
    <div
      v-if="title || subtitle"
      class="pw---flexrow pw---left pw---bottom-5"
      style="flex-wrap: wrap"
    >
      <p style="margin-right: 5px">{{ title }}</p>
      <p class="pw---25">{{ subtitle }}</p>
    </div>
    <div style="padding: 15px; background-color:#1d131b; border-radius:5px">
      <div
        class="pw---button pw---small pw---light-white"
        style="height:35px"
        @click="createNewCondition()"
      >
        <img
          src="https://widget.platformwizards.com/images/white.svg"
          alt=""
          class="pw---20px-icon pw---right-10"
        />
        <p>Add Filter</p>
      </div>
      <div
        v-for="(filter, index) of formulaArray"
        :key="index"
        class="pw---settings-wrap"
      >
        <div class="pw---flex-row pw---heading">
          <p style="margin-right: 5px">Filter {{ index + 1 }}</p>
          <div
            class="pw---button pw---small pw---light-white pw---icon"
            @click="deleteCondition(index)"
          >
            <img
              src="https://widget.platformwizards.com/images/white_trash.svg"
              width="20"
              alt=""
              class="_20px-icon"
            />
          </div>
        </div>
        <div class="pw---seperator pw---small"></div>
        <InputSelect
          title="Field"
          placeholder="Select field"
          :selectOptions="fieldsEnriched"
          :initialValue="filter.field"
          @change="selectField(index, $event)"
        />
        <InputSelect
          title="Condition"
          placeholder="Select condition"
          :selectOptions="conditions"
          :initialValue="filter.condition"
          :hasBoxAroundOptions="false"
          @change="selectCondition(index, $event)"
        />
        <InputValue
          title="Value"
          subtitle=""
          :id="id + index"
          :selectOptions="valueParameters"
          :initialValue="filter.value"
          @change="selectValue(index, $event)"
        />
      </div>
      <div class="pw---info-block" style="margin-top:10px">
        <img
          src="https://widget.platformwizards.com/images/envelopes-1.svg"
          loading="lazy"
          alt=""
          class="pw---18px-icon"
        />
        <p class="pw---left-5 pw---50">
          The Filter field is very powerfull. For example you can set a filter
          so that only data related to the logged in user is loaded.
          <!--p>IF(FIND(//user.userId//,userId,1) >= 1, TRUE(), FALSE())<p-->
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "./InputSelect"
import InputValue from "./InputValue"

export default {
  name: "InputFormula",
  components: { InputValue, InputSelect },
  props: {
    id: String,
    title: String,
    subtitle: String,
    initialValue: Object,
    fields: Object,
    navigationParameters: Object,
    authFields: Object
  },
  data() {
    return {
      formula: "",
      formulaArray: []
    }
  },
  methods: {
    deleteCondition(index) {
      this.formulaArray.splice(index, 1)
    },
    selectCondition(index, condition) {
      this.formulaArray[index].condition = condition
    },
    selectField(index, field) {
      this.formulaArray[index].field = field
    },
    selectValue(index, value) {
      this.formulaArray[index].value = value
    },
    createNewCondition() {
      this.formulaArray.push({
        field: {},
        condition: {},
        value: ""
      })
    }
  },
  computed: {
    conditions() {
      return [
        { id: "equal", title: "Equal to" },
        { id: "not-equal", title: "Not equal to" },
        { id: "contains", title: "Contains" },
        { id: "not-contain", title: "Does not contain" }
      ]
    },
    fieldsEnriched() {
      let result = []
      this.fields.properties.forEach(field => {
        result.push({ id: field, title: field })
      })
      return result
    },
    valueParameters() {
      let result = []
      if (this.navigationParameters.properties.length > 0)
        result.push(this.navigationParameters)
      if (this.authFields.properties.length > 0) result.push(this.authFields)
      return result
    }
  },
  watch: {
    formulaArray: {
      deep: true,
      handler() {
        let formula = "AND("
        this.formulaArray.forEach(filter => {
          if (
            Object.keys(filter.condition).length > 0 &&
            Object.keys(filter.field).length > 0 &&
            filter.value
          ) {
            switch (filter.condition.id) {
              case "equal":
                formula +=
                  "IF({" +
                  filter.field.title +
                  "} = '" +
                  filter.value +
                  "', TRUE(), FALSE()),"
                break
              case "not-equal":
                formula +=
                  "IF({" +
                  filter.field.title +
                  "} != '" +
                  filter.value +
                  "', TRUE(), FALSE()),"
                break
              case "contains":
                formula +=
                  "IF(FIND('" +
                  filter.value +
                  "',{" +
                  filter.field.title +
                  "})>=1, TRUE(), FALSE()),"
                break
              case "not-contain":
                formula +=
                  "IF(FIND('" +
                  filter.value +
                  "',{" +
                  filter.field.title +
                  "})=0, TRUE(), FALSE()),"
                break
            }
          }
        })
        this.formula = formula.substring(0, formula.length - 1) + ")"
        this.$emit("change", {
          formula: this.formula,
          formulaArray: this.formulaArray
        })
      }
    },
    initialValue: {
      deep: true,
      handler() {
        if (
          Object.keys(this.initialValue).length > 0 &&
          Array.isArray(this.initialValue.formulaArray) &&
          this.initialValue.formulaArray.length > 0
        ) {
          this.formulaArray = this.initialValue.formulaArray
        }
      }
    }
  }
}
</script>
