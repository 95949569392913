<template>
  <div>
    <div class="pw---embed" v-show="!visualSelectorOpen">
      <div class="pw---scroll-wrapper">
        <div class="pw---main">
          <TopBar />
          <div class="pw---main-content">
            <div class="pw---flex-column pw---left">
              <div class="pw---seperator pw---small"></div>
              <BackButton />
              <div class="pw---seperator pw---small"></div>
            </div>
            <div class="pw---flexrow pw---left">
              <div class="pw---label pw---purple-light">
                <p class="pw---violet-text">{{ numberOfWriteRequests }}</p>
              </div>
              <p class="pw---left-10">Write Data</p>
            </div>
            <div class="pw---operations">
              <div
                class="pw---operation"
                v-for="request in requests"
                :key="request.id"
                @click="openRequestConfig(request.id)"
                style="margin-bottom: 10px;"
              >
                <div class="pw---flexrow pw---100">
                  <p>{{ request.name }}</p>
                </div>
              </div>
              <div class="pw---flexrow pw---centered pw---top-15">
                <div
                  class="pw---button pw---small pw---round pw---light"
                  @click="requestConfigOpen = true"
                >
                  <img
                    src="https://widget.platformwizards.com/images/white.svg"
                    alt=""
                    class="pw---20px-icon pw---right-10"
                  />
                  <p>Add Request</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WriteRequestConfig
        v-if="requestConfigOpen"
        @open-selector="elementSelectorOpen = true"
        @cancel="closeRequestConfig"
        @saved="closeRequestConfig"
        :elementSelectorValue="elementSelectorValue"
        :requestConfig="currentlyOpenRequest"
        :currentPage="currentPage"
      />
      <div class="pw---scroll-wrapper" v-if="elementSelectorOpen">
        <SelectElement
          @selected="selectedValue($event)"
          @open-visual-selector="visualSelectorOpen = true"
          @close-element-selector="elementSelectorOpen = false"
          v-on="$listeners"
        />
      </div>
    </div>
    <VisualSelector
      v-if="visualSelectorOpen"
      @selected="selectedValue($event)"
      @canceled="visualSelectorOpen = false"
    />
  </div>
</template>

<script>
import TopBar from "./../components/nav/TopBar.vue"
import BackButton from "./../components/nav/BackButton.vue"
import WriteRequestConfig from "./../components/configViews/WriteRequestConfig.vue"
import SelectElement from "./../components/configViews/SelectElement"
import VisualSelector from "./VisualSelector"

export default {
  name: "WriteDataRequests",
  components: {
    TopBar,
    BackButton,
    WriteRequestConfig,
    SelectElement,
    VisualSelector
  },
  data() {
    return {
      authConfigOpen: false,
      requestConfigOpen: false,
      openRequestConfigId: String,
      elementSelectorOpen: false,
      elementSelectorValue: {},
      visualSelectorOpen: false
    }
  },
  methods: {
    openRequestConfig(id) {
      if (this.openRequestConfigId === String) {
        if (this.authConfigOpen === true) this.authConfigOpen = false
        this.requestConfigOpen = true
        this.openRequestConfigId = id
      }
    },
    closeRequestConfig() {
      this.requestConfigOpen = false
      this.openRequestConfigId = String
    },
    selectedValue(event) {
      this.elementSelectorValue = event
      this.visualSelectorOpen = false
      this.elementSelectorOpen = false
    }
  },
  computed: {
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    requests() {
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        return this.$store.state.config[this.currentPage.id].write.requests
      } else return []
    },
    numberOfWriteRequests() {
      let result = 0
      if (
        this.$store.state.config &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].write &&
        this.$store.state.config[this.currentPage.id].write.requests
      ) {
        result = this.$store.state.config[this.currentPage.id].write.requests
          .length
      }
      return result
    },
    currentlyOpenRequest() {
      if (this.requests.length !== 0) {
        let request = this.requests.find(
          request => request.id === this.openRequestConfigId
        )
        return request
      } else {
        return {}
      }
    }
  },
  watch: {
    elementSelectorValue: {
      deep: true,
      handler() {
        this.visualSelectorOpen = false
      }
    }
  }
}
</script>
