<template>
  <div>
    <div v-if="showConnectionErrors && unfoundConnections.length > 0">
      <p class="pw---bottom-5">Unfound connections</p>
      <div
        class=""
        style="background-color: #ffffff10; padding: 1px 10px; border-radius: 5px; margin-bottom: 20px"
      >
        <div
          class="pw---element-row"
          style="border-radius: 5px;background-color: #ffffff05;border: 1px solid #FF715D75;padding: 8px 7px;margin: 10px 0px;"
          v-for="connection in unfoundConnections"
          :key="connection.id"
        >
          <p class="pw---25">{{ connection.element.tagName }}</p>
          <p class="pw---5-left">
            {{
              connection.element.id
                ? "#" + connection.element.id
                : connection.element.classListString
                ? "." +
                  connection.element.classListString.substring(0, 25) +
                  (connection.element.classListString.length > 25 ? "..." : "")
                : ""
            }}
          </p>
          <div style="margin-left: 5px; flex">
            <Attribute
              :title="connection.value.name"
              @click="$emit('open-connection', connection)"
              :color="attributeColor(connection.value)"
            />
          </div>
          <div
            class="pw---button pw---small pw---light-white pw---icon"
            @click="$emit('delete-connection', connection.id)"
            style="flex-shrink: 0; margin-left: 10px"
          >
            <img
              src="https://widget.platformwizards.com/images/white_trash.svg"
              width="20"
              alt=""
              class="_20px-icon"
            />
          </div>
        </div>
      </div>
    </div>
    <p class="pw---bottom-5">Page Tree</p>
    <div
      class="pw---page-tree-wrapper"
      :class="{ 'pw---max-height': fullHeight }"
    >
      <div
        class="pw---element-row"
        v-for="element in getDOMElements"
        :key="element.queryString"
      >
        <div
          class="pw---depth"
          v-for="item in element.parents"
          :key="item.queryString"
        >
          <div class="pw---hor-depth"></div>
        </div>
        <img
          src="https://widget.platformwizards.com/images/25-white.svg"
          alt=""
          class="pw---15px-icon"
          :style="{
            opacity: element.children.length > 0 ? 1 : 0,
            transform: collapsed.includes(element.queryString)
              ? 'rotate(-90deg)'
              : ''
          }"
          @click="toggleCollapse(element)"
        />
        <div
          class="pw---connection-content"
          @mouseover="mouseOver(element.queryString)"
          @mouseleave="mouseOut(element.queryString)"
        >
          <div
            class="pw---element-wrapper"
            :style="{
              opacity: !query
                ? 1
                : query && element.matchesQuery
                ? 1
                : '0.2!important',
              'margin-right': element.connections.length > 0 ? '0px' : '10px'
            }"
            @click="
              () => {
                if (!query) selectElement(element)
                if (query && element.matchesQuery) selectElement(element)
              }
            "
          >
            <p class="pw---25">{{ element.tagName }}</p>
            <p class="pw-classname">
              {{
                element.id
                  ? "#" + element.id
                  : element.classListString
                  ? "." +
                    element.classListString.substring(0, 25) +
                    (element.classListString.length > 25 ? "..." : "")
                  : ""
              }}
            </p>
          </div>
          <div
            class="pw---connections-block"
            v-if="element.connections.length > 0"
          >
            <p class="pw---bottom-5 pw---25">Connections</p>
            <div
              class="pw---connection-wrapper"
              v-for="connection of element.connections"
              :key="connection.id"
              @click="$emit('open-connection', connection)"
            >
              <p class="pw---bottom-5">
                <span
                  class="pw---parameter"
                  :style="{
                    'background-color': attributeColor(connection.value)
                  }"
                  >{{ connection.value.name }}</span
                >
                <span class="pw---50"> of</span>
                {{ getRequestName(connection) }}
              </p>
              <p>
                <span class="pw---50">Method</span>
                {{ connection.method.title }}
              </p>
              <div
                style="background-color: #ff715d; margin-top: 5px; padding: 5px; border-radius: 5px; max-width: 300px"
                v-if="connectionHasNoValidRequest(connection)"
              >
                <p>
                  The selected connection value is invalid. Its request might
                  have been deleted. Please edit connection and re-select value.
                  Or delete connection and try again.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--div
          class="pw---element-wrapper page-tree"
          :style="{
            opacity: !query
              ? 1
              : query && element.matchesQuery
              ? 1
              : '0.2!important'
          }"
          @mouseover="mouseOver(element.queryString)"
          @mouseleave="mouseOut(element.queryString)"
          @click="
            () => {
              if (!query) selectElement(element)
              if (query && element.matchesQuery) selectElement(element)
            }
          "
        >
          <p class="pw---25">{{ element.tagName }}</p>
          <p class="pw---5-left">
            {{
              element.id
                ? "#" + element.id
                : element.classListString
                ? "." +
                  element.classListString.substring(0, 25) +
                  (element.classListString.length > 25 ? "..." : "")
                : ""
            }}
          </p>
          <div
            style="margin-left: 5px; flex"
            v-for="connection of element.connections"
            :key="connection.id"
          >
            <Attribute
              :title="connection.value.name"
              @click="$emit('open-connection', connection)"
              :color="attributeColor(connection.value)"
            />
          </div>
        </div-->
      </div>
    </div>
    <div
      class="pw---element-selector-wrapper"
      id="pw---element-selector-wrapper"
      style="display: none; z-index: -1"
    >
      <div
        class="pw---selector-label"
        style="width: max-content;"
        v-if="Object.keys(hovered).length !== 0"
      >
        <p
          v-if="hovered.classListString.length > 0"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{
            hovered.classListString.length > 0
              ? "." +
                hovered.classListString.substring(0, 25) +
                (hovered.classListString.length > 25 ? "..." : "")
              : ""
          }}
        </p>
        <p
          v-if="hovered.id"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{ "#" + hovered.id }}
        </p>
        <p
          class="pw---5-left pw---50"
          style="-webkit-box-flex: 0; -webkit-flex: 0 0 auto; -ms-flex: 0 0 auto; flex: 0 0 auto;"
        >
          {{
            !hovered.id && !hovered.classListString
              ? hovered.queryString
              : hovered.tagName
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Attribute from "./../ui/Attribute"

export default {
  name: "PageTree",
  props: {
    query: String,
    showConnectionErrors: { type: Boolean, default: false },
    fullHeight: Boolean
  },
  data() {
    return {
      collapsed: [],
      hovered: {},
      foundConnections: [],
      connectionsWithInvalidRequests: []
    }
  },
  components: {
    Attribute
  },
  methods: {
    formatElement(element) {
      return {
        tagName: element.tagName.toLowerCase(),
        id: element.id,
        classList: element.className.toString().split(" "),
        classListString: element.className.toString(),
        parents: this.getParentsOfElement(element),
        queryString: this.getQueryString(element),
        children: this.getChildNodes(element),
        element: {},
        connections: this.getConnetionsOfElement(this.getQueryString(element)),
        matchesQuery: this.matchesQuery(element)
      }
    },
    getParentsOfElement(element) {
      let result = []
      while (
        element &&
        element.nodeName !== "#document" &&
        element.nodeName !== "HTML"
      ) {
        result.unshift(this.getQueryString(element))
        element = element.parentNode
      }
      result.pop()
      return result
    },
    getQueryString(element) {
      let result = ""

      function loop(element) {
        if (element.nodeType === Node.ELEMENT_NODE) {
          // stop here = element has ID
          if (element.getAttribute("id")) {
            result = result.replace(/^/, " #" + element.getAttribute("id"))
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // stop here = element is body
          if (document.body === element) {
            result = result.replace(/^/, " body")
            result = result.replace(/\s/, "")
            result = result.replace(/\s/g, " > ")
            return result
          }

          // concat all classes in "queryselector" style
          if (element.getAttribute("class")) {
            let elemClasses = "."
            elemClasses += element.getAttribute("class")
            elemClasses = elemClasses.replace(/\s/g, ".")
            elemClasses = elemClasses.replace(/^/g, " ")
            let classNth = ""

            // check if element class is the unique child
            let childrens = element.parentNode.children

            let similarElements = []
            for (let i = 0; i < childrens.length; i++) {
              if (
                element.getAttribute("class") ==
                  childrens[i].getAttribute("class") ||
                element.tagName == childrens[i].tagName
              ) {
                similarElements.push(childrens[i])
              }
            }
            if (similarElements.length > 1) {
              for (let j = 0; j < similarElements.length; j++) {
                if (element === similarElements[j]) {
                  j++
                  classNth = ":nth-of-type(" + j + ")"
                  break
                }
              }
            }

            result = result.replace(/^/, elemClasses + classNth)
          } else {
            // get nodeType
            let name = element.nodeName
            name = name.toLowerCase()
            let nodeNth = ""

            let childrens = element.parentNode.children

            if (childrens.length > 1) {
              let similarNodes = []

              for (let i = 0; i < childrens.length; i++) {
                if (element.nodeName == childrens[i].nodeName) {
                  similarNodes.push(childrens[i])
                }
              }

              if (similarNodes.length > 1) {
                for (let j = 0; j < similarNodes.length; j++) {
                  if (element === similarNodes[j]) {
                    j++
                    nodeNth = ":nth-of-type(" + j + ")"
                    break
                  }
                }
              }
            }

            result = result.replace(/^/, " " + name + nodeNth)
          }

          if (element.parentNode) {
            loop(element.parentNode)
          } else {
            result = result.replace(/\s/g, " > ")
            result = result.replace(/\s/, "")
            return result
          }
        } else {
          result = ""
        }
      }

      loop(element)

      return result
    },
    getChildNodes(element) {
      let result = []
      let self = this
      for (let i = 0; i < element.childNodes.length; i++) {
        let childNode = element.childNodes[i]
        let queryString = self.getQueryString(childNode)
        result.push(queryString)
      }
      return result
    },
    toggleCollapse(element) {
      if (element.children.length > 0) {
        if (this.collapsed.includes(element.queryString)) {
          let index = this.collapsed.indexOf(element.queryString)
          this.collapsed.splice(index)
        } else {
          let collapsed = this.collapsed
          collapsed.push(element.queryString)
          this.collapsed = collapsed
        }
      }
    },
    isCurrentlyCollapsed(element) {
      if (element.parents.length > 0) {
        let parentQueryStrings = []
        let result = false
        element.parents.forEach(parent => {
          parent = document.querySelector(parent)
          parentQueryStrings.push(this.getQueryString(parent))
        })
        parentQueryStrings.forEach(queryString => {
          this.collapsed.forEach(queryS => {
            if (queryS === queryString) result = true
          })
        })
        return result
      } else {
        return false
      }
    },
    selectElement(element) {
      this.$emit("selected", element)
    },
    getConnetionsOfElement(elementQueryString) {
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let connections = this.$store.state.config[
          this.currentPage.id
        ].load.connections.filter(
          connection => connection.element.queryString === elementQueryString
        )
        connections.forEach(connection => {
          this.foundConnections.push(connection)
        })
        return connections
      } else {
        //console.log("no connections found for " + elementQueryString)
        return []
      }
    },
    attributeColor(value) {
      if (value.type === "list") {
        return "rgb(51, 173, 242)"
      } else return ""
    },
    matchesQuery(element) {
      let result
      if (this.query) {
        let elementQueryString = this.getQueryString(element)
        let elementsMachingQuery = document.querySelectorAll(this.query)
        let queryStringsForElementsMatchingQuery = []
        elementsMachingQuery.forEach(element => {
          let querySelector = this.getQueryString(element)
          queryStringsForElementsMatchingQuery.push(querySelector)
        })
        queryStringsForElementsMatchingQuery.forEach(queryString => {
          if (queryString === elementQueryString) {
            result = true
          }
        })
      }
      return result
    },
    mouseOver(queryString) {
      let e = document.querySelector(queryString)
      let rect = e.getBoundingClientRect()
      // let height = rect.bottom - rect.top
      // let width = rect.right - rect.left
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper"
      )
      let formattedElement = this.formatElement(e)
      this.hovered = {
        tagName: formattedElement.tagName,
        id: formattedElement.id,
        classListString: formattedElement.classListString
      }
      e.style["border-width"] = "2px"
      e.style["border-color"] = "#3371f2"
      e.style["border-style"] = "solid"
      /*
        selectorWrapper.style.width = width + "px"
        selectorWrapper.style.height = height + "px"
        */
      selectorWrapper.style.top = rect.top + "px"
      selectorWrapper.style.left = rect.left + "px"
      selectorWrapper.style.display = "block"
    },
    mouseOut(queryString) {
      let e = document.querySelector(queryString)
      let selectorWrapper = document.getElementById(
        "pw---element-selector-wrapper"
      )
      this.hovered = {}
      selectorWrapper.style.display = "block"
      e.style["border-width"] = null
      e.style["border-color"] = null
      e.style["border-style"] = null
      e.onclick = null
    },
    getRequestName(connection) {
      let request = {}
      if (
        connection.requestId !== "auth" &&
        connection.requestId !== "params" &&
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.requests
      ) {
        request = this.$store.state.config[
          this.currentPage.id
        ].load.requests.find(request => request.id === connection.requestId)
        if ((!request || !request.name) && connection && connection.requestId) {
          //this.connectionsWithInvalidRequests.push(connection.id)
          return "❌ Request does not exist any more. Please select another value!"
        }
      } else if (connection.requestId !== "auth") {
        request = {}
        request.name = "Authentication data"
      } else if (connection.requestId !== "params") {
        request = {}
        request.name = "Navigation Parameters"
      }
      return request.name
    },
    connectionHasNoValidRequest(connection) {
      let foundConnectionId = this.connectionsWithInvalidRequests.find(
        connectionId => connectionId === connection.id
      )
      if (foundConnectionId) {
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    getDOMElements() {
      let self = this
      self.foundConnections = []
      self.connectionsWithInvalidRequests = []
      function traverse(node, elems) {
        if (
          node &&
          node.tagName &&
          node.tagName !== "PLATFORM-WIZARDS" &&
          node.id !== "intercom-frame" &&
          node.classList[0] !== "intercom-lightweight-app" &&
          node.classList[0] !== "w-webflow-badge"
        ) {
          let element = self.formatElement(node)
          if (!self.isCurrentlyCollapsed(element)) {
            elems.push(element)
          }
          let childs = node.childNodes
          for (let i = 0; i < childs.length; i++) {
            traverse(childs[i], elems)
          }
        }
      }
      let domElements = []
      traverse(document.getElementsByTagName("body")[0], domElements)
      return domElements
    },
    currentPage() {
      let pathName = window.location.pathname.substring(1)
      let page = this.$store.state.pages.find(page => page.slug === pathName)
      return page
    },
    unfoundConnections() {
      if (
        this.$store.state.config[this.currentPage.id] &&
        this.$store.state.config[this.currentPage.id].load &&
        this.$store.state.config[this.currentPage.id].load.connections
      ) {
        let connections = [
          ...this.$store.state.config[this.currentPage.id].load.connections
        ]
        this.foundConnections.forEach(connection => {
          let index = connections.indexOf(connection)
          if (index > -1) {
            connections.splice(index, 1)
          }
        })
        return connections
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>
.page-tree:hover {
  opacity: 0.9 !important;
}
</style>
